import { Authorization, Permission } from '../components/auth'
import {
  HomePage,
  Login,
  DecentralizationUserPage,
  RoleCategoryPage,
  UpdateDecentralizationUserPage,
  SubjectsCategoryPage,
  DepartmentCategoryPage,
  UrgencyCategoryPage,
  SecurityLevelCategoryPage,
  RegencyCategoryPage,
  RolePermissionsPage,
  SaveNoteCategoryPage,
  AddSubjectsCategoryPage,
  AddRegencyCategoryPage,
  AddUrgencyCategoryPage,
  AddSecurityLevelCategoryPage,
  AddSaveNoteCategoryPage,
  AddDepartmentCategoryPage,
  AddRoleCategoryPage,
  UnitCategoryPage,
  AddUnitCategoryPage,
  ErrorPages,
  ListPermissionRolePage,
  ListAdminNotificationPage,
  AddAdminNotificationPage,
  DetailNotificationPage,
  MoveUsersUnitPage,
  AddService,
  ListService,
  ThongKeService,
  StatisticService,
  ThoiGianLuuDanhSach,
  ThoiGianLuuChinhSua,
  Priority,
  PhienBan,
  Examples,
  NotFoundPage,
} from '../containers/pages'

import * as Pages from '../containers/pages'
import * as Banners from '../containers/pages/HeThong'

import App from '../containers/App'
import _ from 'lodash'
const getAuthComponent = permission_list =>
  Authorization.Component(permission_list, [], true)
const getAuthAndMenuPermissionComponent = (
  permission_list,
  permissionMenu,
  isPage = true,
  isOrPermission = false
) =>
  Authorization.Component(
    permission_list,
    permissionMenu,
    isPage,
    false,
    isOrPermission
  )
// Nếu không có quyền thì chuyển về tab công việc cũ
const getAuthComponentRedirect = permission_list =>
  Authorization.Component(permission_list, [], true, true)

const SystemSubject = getAuthComponent([
  Permission.HETHONG,
  Permission.DOITUONGCONGVAN_QUANLY,
])
const SystemSaveNote = getAuthComponent([
  Permission.HETHONG,
  Permission.SOLUUTRU_QUANLY,
])
const SuperAdmin = getAuthComponent([Permission.SUPERADMIN])


const [System] = _.fill(
  Array(7),
  getAuthComponent([Permission.ADMIN])
)
const KySoTapTrungSuper = getAuthComponent([
  Permission.SUPERADMIN,
  Permission.KY_SO_TAP_TRUNG,
])
const KySoTapTrungAdmin = getAuthComponent([
  Permission.ADMIN,
  Permission.KY_SO_TAP_TRUNG,
])
const quanLyCongViec = getAuthComponentRedirect([Permission.SUDUNG_QLCV])

const thongBaoKetLuanCuocHop = getAuthComponentRedirect([
  Permission.THONGBAOKETLUANHDTV,
])

const PhanQuyenChucNang = getAuthAndMenuPermissionComponent(
  [Permission.SUPERADMIN],
  [Permission.MENU_SIDEBAR.SMENU_HETHONG_PQUYENCNANG]
)

const routes = [
  {
    component: App,
    requireLogin: '/dang-nhap',
    routes: [
      {
        path: '/',
        exact: true,
        component: HomePage,
        privateRouter: true,
      },
      {
        component: Login,
        path: '/dang-nhap',
        privateRouter: true,
      },
      // He Thong
      {
        component: System(DecentralizationUserPage),
        path: '/he-thong/nguoi-dung/phan-quyen',
        privateRouter: true,
      },
      {
        component: System(ListPermissionRolePage),
        path: '/he-thong/nguoi-dung/phong-ban/:id',
        privateRouter: true,
      },
      {
        component: System(MoveUsersUnitPage),
        path: '/he-thong/nguoi-dung/di-chuyen',
        privateRouter: true,
      },
      {
        component: SuperAdmin(RoleCategoryPage),
        path: '/he-thong/vai-tro/danh-muc',
        privateRouter: true,
      },
      {
        component: SystemSubject(SubjectsCategoryPage),
        path: '/he-thong/doi-tuong/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UrgencyCategoryPage),
        path: '/he-thong/do-khan/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(SecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/danh-muc',
        privateRouter: true,
      },
      {
        component: System(DepartmentCategoryPage),
        path: '/he-thong/phong-ban/danh-muc/:id',
        privateRouter: true,
      },
      {
        component: System(DepartmentCategoryPage),
        path: '/he-thong/phong-ban/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UnitCategoryPage),
        path: '/he-thong/don-vi/danh-muc/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UnitCategoryPage),
        path: '/he-thong/don-vi/danh-muc',
        privateRouter: true,
      },
      {
        component: System(RegencyCategoryPage),
        path: '/he-thong/chuc-vu/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(RolePermissionsPage),
        path: '/he-thong/phan-quyen-vai-tro',
        privateRouter: true,
      },
      {
        component: PhanQuyenChucNang(PhienBan),
        path: '/he-thong/phien-ban/phan-quyen-chuc-nang',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(SaveNoteCategoryPage),
        path: '/he-thong/so-luu/danh-muc',
        privateRouter: true,
      },
      {
        component: System(UpdateDecentralizationUserPage),
        path: '/he-thong/nguoi-dung/them-moi',
        privateRouter: true,
      },
      {
        component: System(UpdateDecentralizationUserPage),
        path: '/he-thong/nguoi-dung/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SystemSubject(AddSubjectsCategoryPage),
        path: '/he-thong/doi-tuong/them-moi',
        privateRouter: true,
      },
      {
        component: SystemSubject(AddSubjectsCategoryPage),
        path: '/he-thong/doi-tuong/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: System(AddRegencyCategoryPage),
        path: '/he-thong/chuc-vu/them-moi',
        privateRouter: true,
      },
      {
        component: System(AddRegencyCategoryPage),
        path: '/he-thong/chuc-vu/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUrgencyCategoryPage),
        path: '/he-thong/do-khan/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUrgencyCategoryPage),
        path: '/he-thong/do-khan/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddSecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddSecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(AddSaveNoteCategoryPage),
        path: '/he-thong/so-luu/them-moi',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(AddSaveNoteCategoryPage),
        path: '/he-thong/so-luu/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: System(AddDepartmentCategoryPage),
        path: '/he-thong/phong-ban/them-moi',
        privateRouter: true,
      },
      {
        component: System(AddDepartmentCategoryPage),
        path: '/he-thong/phong-ban/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUnitCategoryPage),
        path: '/he-thong/don-vi/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUnitCategoryPage),
        path: '/he-thong/don-vi/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddRoleCategoryPage),
        path: '/he-thong/vai-tro/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddRoleCategoryPage),
        path: '/he-thong/vai-tro/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(Banners.ListBanner),
        path: '/he-thong/quan-ly-cong-viec/banner/danh-sach',
        privateRouter: true,
      },
      {
        component: SuperAdmin(Banners.UpdateBanner),
        path: '/he-thong/quan-ly-cong-viec/banner/:type/:id?',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddService),
        path: '/he-thong/service/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddService),
        path: '/he-thong/service/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ListService),
        path: '/he-thong/service/danh-sach',
        privateRouter: true,
      },
      {
        component: SuperAdmin(StatisticService),
        path: '/he-thong/service/thong-ke/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ThongKeService),
        path: '/he-thong/service/thong-ke',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ThoiGianLuuDanhSach),
        path: '/he-thong/thoi-gian-luu/danh-sach',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ThoiGianLuuChinhSua),
        path: '/he-thong/thoi-gian-luu/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ThoiGianLuuChinhSua),
        path: '/he-thong/thoi-gian-luu/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(Priority),
        path: '/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach',
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AddProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.EditProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AppliedUnitPage),
        path: '/he-thong/quy-trinh/:id/don-vi-ap-dung',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedurePage),
        path: '/he-thong/quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AddProcedurePage),
        path: '/he-thong/quy-trinh/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.EditProcedurePage),
        path: '/he-thong/quy-trinh/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedureDetailsPage),
        path: '/he-thong/quy-trinh/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupPage),
        path: '/he-thong/don-vi/quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureDetailsPage),
        path: '/he-thong/don-vi/quy-trinh/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupDetailPage),
        path: '/he-thong/don-vi/quy-trinh/:id/nhom-thanh-vien',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupDTXDPage),
        path: '/he-thong/don-vi/quy-trinh-dtxd/:id/nhom-thanh-vien',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.KsttStatisticPage),
        path: '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.KsttStatisticPage),
        path: '/he-thong/thong-ke-ky-so-tap-trung/danh-sach/:id',
        exact: true,
        privateRouter: true,
      },
      // ThongBao
      {
        component: System(ListAdminNotificationPage),
        path: '/thong-bao/danh-sach',
        privateRouter: true,
      },
      {
        component: System(AddAdminNotificationPage),
        path: '/thong-bao/them-moi',
        privateRouter: true,
      },
      {
        component: System(AddAdminNotificationPage),
        path: '/thong-bao/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: DetailNotificationPage,
        path: '/thong-bao/chi-tiet/:id',
        privateRouter: true,
      },
      // Quản lý công việc
      {
        component: thongBaoKetLuanCuocHop(Pages.MeetingDetail),
        path: '/thong-bao-ket-luan-cuoc-hop/chi-tiet/:id?/:action?/:actionId?',
        privateRouter: true,
      },
      {
        component: thongBaoKetLuanCuocHop(Pages.Meeting),
        path: '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/:action?/:id?',
        privateRouter: true,
      },
      {
        component: thongBaoKetLuanCuocHop(Pages.ThongKeKetLuanCuocHop),
        path: '/thong-bao-ket-luan-cuoc-hop/thong-ke',
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.qlcvNotificationPage),
        path: '/quan-ly-cong-viec/thong-bao/',
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.FavoriteGroupPage),
        path: '/quan-ly-cong-viec/nhom-thuong-giao-viec/:action?/:id?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.TagsPage),
        path: '/quan-ly-cong-viec/tags/:action?/:id?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.qlcvStatisticPage),
        path: '/quan-ly-cong-viec/thong-ke',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.AllTaskPage),
        path: '/quan-ly-cong-viec/:type/:action?/:id?/:actionPage?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.FavoriteGroupPage),
        path: '/quan-ly-cong-viec/nhom-thuong-giao',
        privateRouter: true,
      },
      {
        component: Examples,
        path: '/examples',
        exact: true,
        privateRouter: true,
      },
      {
        path: '/error-page',
        exact: true,
        component: ErrorPages,
        privateRouter: true,
      },
      {
        path: '*',
        exact: true,
        component: NotFoundPage,
      },
    ],
  },
]

export default routes
