import React, { memo, useState, useMemo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Intent, Tooltip } from '@blueprintjs/core'
import classnames from 'classnames'
import FileDownload from 'file-saver'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'
import isString from 'lodash/isString'
import { withRouter } from 'react-router'
import moment from 'moment'

import { Permission } from 'components/auth'
import { MeetingStatisticFilter } from './Filter'
import { MasterLayout } from 'components/layout'
import { Table } from 'components/newCommon2'
import BackgroundSetting from './Settings/Background'
import { Icon } from 'components/newCommon'
import {
  getMeetingStatistics,
  getMeetings,
  exportMeetingStatistics,
  showToast,
  getPhongBanDonVi,
  getMeetingWorkStatistics,
} from 'actions/task'
import requestAction from 'helpers/request'
import { Action, Toast } from 'constants/MessageForm'
import ThongKeKetLuanCuocHopChiTiet from './ThongKeKetLuanCuocHopChiTiet'

export const ThongKeKetLuanCuocHopContext = React.createContext({})

const ThongKeKetLuanCuocHop = ({
  history,
  location,
  allCookies,
  hasShowAllDeparment,
  mainUnitId,
}) => {
  const idTotal = '00000000-0000-0000-0000-000000000000'
  const requestDateFormat = 'YYYY-MM-DD'
  const PAGE_SIZE = 1000
  const PAGE_SIZE_DETAIL = 10
  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
      isHide: true,
      limit: PAGE_SIZE,
    }),
    []
  )

  const DEFAULT_FILTER_DETAIL = useMemo(
    () => ({
      page: 1,
      limit: PAGE_SIZE_DETAIL,
      status: 'NEW',
    }),
    []
  )

  const [filterOld, setFilterOld] = useState(null)
  const [totalData, setTotalData] = useState(1)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [dataSource, setDataSource] = useState([])
  const [dataSourceTotal, setDataSourceTotal] = useState([])
  const [dataInfo, setDataInfo] = useState([])
  const [treeData, setTreeData] = useState([])
  const [firstDisplay, setFirstDisplay] = useState(true)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [filterDetail, setFilterDetail] = useState(DEFAULT_FILTER_DETAIL)
  const [dataTableDetail, setDataTableDetail] = useState([])

  const convertParam = useCallback(values => {
    return {
      ...values,
      status:
        values?.status && values?.status !== 'ALL' ? values?.status : undefined,
      phongBanId:
        values?.phongBanDonVi &&
        values?.phongBanDonVi?.type === 'DEPARTMENT' &&
        values?.phongBanDonVi?.value !== 'ALL'
          ? values?.phongBanDonVi?.value
          : undefined,
      donViId:
        values?.phongBanDonVi &&
        values?.phongBanDonVi?.type === 'ORGANIZATION' &&
        values?.phongBanDonVi?.value !== 'ALL'
          ? values?.phongBanDonVi?.value
          : undefined,
    }
  }, [])

  const fetchDataDetail = data => {
    const requestFilter = {
      ...data,
      fromDate:
        data?.fromDate && moment(data?.fromDate).isValid()
          ? moment(data?.fromDate).format(requestDateFormat)
          : undefined,
      toDate:
        data?.toDate && moment(data?.toDate).isValid()
          ? moment(data?.toDate).format(requestDateFormat)
          : undefined,
      ids: location?.state?.ids,
      phongBanDonViId: data?.phongBanDonViId,
    }
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      action: () => getMeetingWorkStatistics(requestFilter),
      afterResponse: response => {
        setDataTableDetail(response?.items)
      },
    })
  }

  const onChangeFilterDetail =
    ({ name, reFetch = false, multipleKey = false }) =>
    value => {
      let newFilter = { ...filterDetail, page: 1 }
      if (name === 'RESET') {
        newFilter = DEFAULT_FILTER
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else if (name) {
        newFilter = {
          ...newFilter,
          [name]: isString(value) ? value?.trim() : value,
        }
      }
      setFilterDetail(newFilter)
      if (reFetch) {
        fetchDataDetail(newFilter)
      }
    }

  const changeFilter =
    ({ name, multipleKey = false }) =>
    value => {
      let newFilter = { ...filter, page: 1 }
      if (name === 'RESET') {
        newFilter = DEFAULT_FILTER
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else if (name) {
        newFilter = {
          ...newFilter,
          [name]: isString(value) ? value?.trim() : value,
        }
      }
      setFilter(newFilter)
      if (name === 'RESET') {
        setDataSource([])
        setDataSourceTotal([])
        setPage(1)
        if (size(location?.state?.ids) !== 0) {
          fetchInfoStatistic(filterOld?.filterMeeting)
          fetchList(filterOld?.filterStatistic)
        } else {
          setFilter(prev => ({
            ...prev,
            fromDate: moment().subtract(1, 'months'),
            toDate: moment(),
          }))
        }
        return
      }

      if (name === 'SUBMIT') {
        setDataSource([])
        setDataSourceTotal([])
        setPage(1)
        fetchList({
          ...convertParam(newFilter),
          ids: location?.state?.ids || undefined,
        })
      }
    }

  const fetchList = useCallback(requestFilter => {
    setFirstDisplay(false)
    const params = {
      ...requestFilter,
      fromDate:
        requestFilter?.fromDate && moment(requestFilter?.fromDate).isValid()
          ? moment(requestFilter?.fromDate).format(requestDateFormat)
          : undefined,
      toDate:
        requestFilter?.toDate && moment(requestFilter?.toDate).isValid()
          ? moment(requestFilter?.toDate).format(requestDateFormat)
          : undefined,
    }
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      action: () => getMeetingStatistics(params),
      afterResponse: response => {
        const newResponse =
          response?.items?.filter(res => res?.donViPhongBanId !== idTotal) || []
        setDataSource(prev =>
          uniqBy([...prev, ...newResponse], 'donViPhongBanId')
        )
        setDataSourceTotal(
          response?.items
            ?.filter(f => f?.donViPhongBanId === idTotal)
            ?.map(res => ({
              ...res,
              tenDonViPhongBan: 'Tổng cộng',
              isSum: true,
            })) || []
        )
        setPage(requestFilter?.page || 1)
        setTotalData(response?.totalCount)
      },
    })
  }, [])

  const fetchInfoStatistic = useCallback(requestFilter => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      action: () => getMeetings(requestFilter),
      afterResponse: response => {
        setDataInfo(response?.items)
      },
    })
  }, [])

  const onClickDetail = (data, name) => {
    const params = {
      ...filterDetail,
      phongBanDonViId: data?.donViPhongBanId,
      isAccept: name === 'chuaHoanThanhDaXacNhan' ? true : undefined,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
    }
    setFilterDetail(prev => ({
      ...prev,
      phongBanDonViId: data?.donViPhongBanId,
      isAccept: name === 'chuaHoanThanhDaXacNhan' ? true : undefined,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
    }))
    setIsDetail(true)
    fetchDataDetail(params)
  }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center justify-center element-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (get(filter, 'page', 1) - 1) * PAGE_SIZE + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'donViPhongBan',
      width: 20,
      headClass: 'uppercase',
      className: 'align-center min-width-200',
      title: 'Đơn vị/ phòng ban',
      render: ({ tenDonViPhongBan }) => <span>{tenDonViPhongBan}</span>,
    },
    {
      key: 'tongSo',
      width: 10,
      headClass: 'uppercase',
      className: 'align-center min-width-100 justify-center element-center ',
      title: 'Tổng số',
      render: ({ tongSo }) => <span>{tongSo}</span>,
    },
    {
      key: 'chuaHoanThanh',
      width: 10,
      headClass: 'uppercase',
      className: 'min-width-160 align-center justify-center',
      title: 'Chưa hoàn thành',
      render: data => {
        let isClick = false
        if (data.chuaHoanThanh !== 0 && !data?.isSum) {
          isClick = true
        }

        return (
          <span
            className={classnames('', {
              'color-blue': isClick,
              'cursor-pointer': isClick,
            })}
            onClick={() =>
              isClick ? onClickDetail(data, 'chuaHoanThanh') : null
            }
          >
            {data.chuaHoanThanh}
          </span>
        )
      },
    },
    {
      key: 'tiLeChuaHoanThanh',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'min-width-200 align-center justify-center',
      title: 'Tỷ lệ chưa hoàn thành (%)',
      render: ({ tyLeChuaHoanThanh }) => <span>{tyLeChuaHoanThanh}</span>,
    },
    {
      key: 'chuaHoanThanhCoXacNhan',
      headClass: 'uppercase text-center',
      width: 10,
      className: 'min-width-160 align-center justify-center',
      title: 'Chưa hoàn thành có xác nhận',
      render: data => {
        let isClick = false
        if (data.chuaHoanThanhDaXacNhan !== 0 && !data?.isSum) {
          isClick = true
        }

        return (
          <span
            className={classnames('', {
              'color-blue-important': isClick,
              'cursor-pointer': isClick,
            })}
            onClick={() =>
              isClick ? onClickDetail(data, 'chuaHoanThanhDaXacNhan') : null
            }
          >
            {data.chuaHoanThanhDaXacNhan}
          </span>
        )
      },
    },
    {
      key: 'daHoanThanh',
      headClass: 'uppercase',
      width: 10,
      className: 'min-width-100 align-center justify-center',
      title: 'Đã hoàn thành',
      render: ({ hoanThanh }) => <span>{hoanThanh}</span>,
    },
    {
      key: 'tiLeDaHoanThanh',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'align-center min-width-100 justify-center',
      title: 'Tỳ lệ đã hoàn thành (%)',
      render: ({ tyLeHoanThanh }) => <span>{tyLeHoanThanh}</span>,
    },
  ]

  const handleExport = () => {
    const params = {
      ...convertParam(filter),
      ids: location?.state?.ids || undefined,
      page: 1,
      limit: 9999,
    }
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      beforeAction: () => {
        setIsDisabledButton(true)
      },
      action: () => exportMeetingStatistics(params),
      afterResponse: response => {
        FileDownload.saveAs(response, `thong-ke-klch.xls`)
      },
      afterAction: () => {
        setIsDisabledButton(false)
      },
      afterError: () => {
        showToast({
          message: Toast.FAIL(Action.EXPORT_EXCEL),
          intent: Intent.DANGER,
        })
      },
    })
  }

  const onScrollTable = () => {
    const newFilter = { ...filter }
    if (size(dataSource) >= totalData) {
      return
    }

    fetchList({
      ...newFilter,
      ids: location?.state?.ids || undefined,
      page: page + 1,
    })
  }

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const fetchDepartmentsUnit = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              value: elm?.donViPhongBanId,
              label: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              value: elm?.donViPhongBanId,
              label: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  value: -1,
                  label: 'Phòng ban',
                  parentId: null,
                  noCheck: true,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  value: -2,
                  label: 'PC/ Đơn vị',
                  parentId: null,
                  noCheck: true,
                  children: organizationData,
                },
              ]
            : []),
        ]
        setTreeData(newData)
      },
    })
  }, [mainUnitId])

  const onGoBack = () => {
    if (isDetail) {
      setIsDetail(false)
      return
    }
    return history.push('/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop')
  }

  useEffect(() => {
    if (size(location?.state?.ids) !== 0) {
      fetchList({
        ...DEFAULT_FILTER,
        ids: location?.state?.ids,
      })
    } else {
      setFilter(prev => ({
        ...prev,
        fromDate: moment().subtract(1, 'months'),
        toDate: moment(),
      }))
    }

    setFilterOld({
      filterMeeting: {
        page: 1,
        ids: location?.state?.ids,
        limit: 9999,
      },
      filterStatistic: {
        ...DEFAULT_FILTER,
        ids: location?.state?.ids,
      },
    })
  }, [location, DEFAULT_FILTER, fetchList])

  useEffect(() => {
    if (size(location?.state?.ids) !== 0) {
      fetchInfoStatistic({
        ids: location?.state?.ids,
        limit: 9999,
      })
    }
  }, [location, fetchInfoStatistic])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  const contextValue = {
    treeData,
    filter,
    filterDetail,
    changeFilter,
    onChangeFilterDetail,
    dataTableDetail,
  }

  return (
    <MasterLayout typeSidebar="taskManager" newLayout>
      <ThongKeKetLuanCuocHopContext.Provider value={contextValue}>
        <div style={{ flex: 1, padding: 40, position: 'relative' }}>
          <header className="d-flex mb20">
            <div style={{ marginRight: 'auto' }}>
              <div
                className={classnames('font-size-20 font-weight-600', {
                  'color-light': get(
                    allCookies,
                    'background_image.is_light',
                    false
                  ),
                })}
              >
                <div className="d-flex align-center">
                  <Button
                    className={classnames(
                      'cpc-button',
                      'no-padding',
                      'rounded',
                      'mr10'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={onGoBack}
                  >
                    <Icon classIcon="icon2-arrow-left" />
                  </Button>
                  <div>{isDetail ? 'Danh sách thống kê' : 'Thống kê'}</div>
                </div>
              </div>
            </div>
            <span style={{ marginLeft: 'auto' }}>
              {!isDetail && (
                <Tooltip
                  content={<span className="font-size-12">Xuất excel</span>}
                >
                  <Button
                    className={classnames(
                      'cpc-button',
                      'cpc-button-green',
                      'no-padding',
                      'rounded'
                    )}
                    loading={isDisabledButton}
                    disabled={isDisabledButton}
                    onClick={() => handleExport()}
                  >
                    <Icon classIcon="icon2-export" />
                  </Button>
                </Tooltip>
              )}
              <BackgroundSetting />
            </span>
          </header>
          {!isDetail && (
            <div className="mb20">
              <MeetingStatisticFilter />
            </div>
          )}
          <div className="block-thongke">
            {!isEmpty(dataInfo) && (
              <div className="info-thongke">
                {dataInfo?.map?.((item, index) => (
                  <p key={index} className="info-thongke-item">
                    {item?.title}
                  </p>
                ))}
              </div>
            )}
            <div className="table-thongke">
              {!isDetail && (
                <Table
                  className="cpc-table-radius"
                  autoHide={false}
                  columns={columns}
                  data={dataSource}
                  dataTotal={dataSourceTotal}
                  loading={false}
                  tableMinWidth={1200}
                  autoHideScrollBody={false}
                  statisticMessage={
                    (size(location?.state?.ids) === 0 && firstDisplay) || false
                  }
                  onScrollFrameBottom={onScrollTable}
                />
              )}
              {isDetail && (
                <ThongKeKetLuanCuocHopChiTiet type="THONG_KE_KET_LUAN_CUOC_HOP" />
              )}
            </div>
          </div>
        </div>
      </ThongKeKetLuanCuocHopContext.Provider>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  const hasShowAllDeparment = get(state, 'auth.permission', []).includes(
    Permission.THONGBAOKETLUANHDTV
  )

  return {
    hasShowAllDeparment,
    mainUnitId: get(state, 'auth.mainUnitId'),
  }
}

export default withRouter(connect(mapStateToProps)(memo(ThongKeKetLuanCuocHop)))
