import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  Fragment,
} from 'react'
import {
  Button,
  Intent,
  Popover,
  Menu,
  MenuDivider,
  Position,
  MenuItem,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import moment from 'moment'
import { get, size } from 'lodash'
import { withCookies } from 'react-cookie'

import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import {
  Table,
  Pagination,
  SelectTree,
  DatePicker,
} from 'components/newCommon2'
import {
  Icon,
  Avatar,
  FileGroup,
  TagGroup,
  Dialog,
  Input,
  Select,
} from 'components/newCommon'
import { TruncateTextTooltip, ViewMoreDots } from 'components/common'
import { Permission } from 'components/auth'
import {
  saveTree2,
  getDsTag,
  getMeeting,
  getPhongBanDonVi,
  getThongTinDonVi,
  getLanhDaoChuTri,
  getWorksMeeting,
} from 'actions/task'
import { WORK_MEETING_STATUS, WORK_STATUS } from 'constants/Enum'
import MeetingFormUpdate from './Form/MeetingFormUpdate'
import WorkMeetingUpdate from './Form/WorkMeetingUpdate'
import Reminder from './Form/Reminder'
import Accept from './Form/Accept'
import workMeetingTags from './Form/WorkMeetingTags'
import DeleteWorkMeeting from './Confirm/DeleteWorkMeeting'
import ApproveSecretary from './Confirm/ApproveSecretary'
import FlowWorksDetail from './Details/FlowWorks'
import WorkMeetingDetail from './Details/WorkMeetingDetail'
import BackgroundSetting from './Settings/Background'
import AcceptHistory from './Details/AcceptHistory'

export const MeetingDetailContext = React.createContext({})

const MeetingDetail = ({
  history,
  location,
  match,
  allCookies = {},
  roleId,
  mainUnitId,
  mainDepartmentId,
  hasShowAllDeparment,
  dataThongTinDonVi,
  dataTreeOrganizationDepartmentStore,
}) => {
  const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
  const PAGE_SIZE = 10
  const DATA_FORMAT = 'DD/MM/YYYY'
  const pageActions = {
    flowWorks: 'flow-works',
    chinhSua: 'chinh-sua',
    taoMoiCongViec: 'them-moi-cong-viec',
    chinhSuaCongViec: 'chinh-sua-cong-viec',
    huyDuyet: 'huy-duyet',
    xoa: 'xoa',
    chiTiet: 'chi-tiet',
    chiTietCongViec: 'chi-tiet-cong-viec',
    nhacViec: 'nhac-viec',
    tag: 'tag',
    xacNhan: 'xac-nhan',
    acceptHistory: 'lich-su-xac-nhan',
  }
  const dsLoaiCuocHop = [
    {
      label: 'Giao ban tuần',
      value: 'WEEKLY_DELIVERY',
    },
    {
      label: 'Sơ kết/Tổng kết',
      value: 'SUMMARY',
    },
    {
      label: 'Họp tại đơn vị',
      value: 'MEETING_AT_ORGANIZATION',
    },
    {
      label: 'Khác',
      value: 'OTHER',
    },
  ]
  const dsStatus = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Chưa thực hiện',
      value: WORK_STATUS.CHUA_THUC_HIEN,
    },
    {
      label: 'Đang thực hiện',
      value: WORK_STATUS.DANG_THUC_HIEN,
    },
    {
      label: 'Hoàn thành',
      value: WORK_STATUS.HOAN_THANH,
    },
    {
      label: 'Hoàn thành chậm',
      value: WORK_STATUS.HOAN_THANH_CHAM,
    },
  ]
  const dsStatusApprobal = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Chưa duyệt',
      value: WORK_MEETING_STATUS.NOT_APPROVED_YET,
    },
    {
      label: 'Đã duyệt',
      value: WORK_MEETING_STATUS.APPROVED,
    },
    {
      label: 'Chờ duyệt',
      value: WORK_MEETING_STATUS.WAITING_APPROVAL,
    },
  ]
  const filterKeys = {
    title: 'Title',
    chuTri: 'MainResponsibilityId',
    phoiHop: 'CoordinationId',
    ngayBatDau: 'ExecutionDate',
    hanThucHien: 'Deadline',
    trangThai: 'Status',
    trangThaiDuyet: 'Approval',
  }

  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { id: meetingId, action: currentAction } = match.params
  const mainPathname = useMemo(
    () => '/thong-bao-ket-luan-cuoc-hop/chi-tiet',
    []
  )
  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
    }),
    []
  )

  const [tags, setTags] = useState([])
  const [fetchingDetail, setFetchingDetail] = useState(true)
  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [dataWorksMetting, setDataWorksMetting] = useState([])
  const [dataDetailMetting, setDataDetailMetting] = useState({})
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [totalData, setTotalData] = useState(1)
  const [dataDialog, setDataDialog] = useState({
    isOpen: false,
    data: null,
  })

  const goToAction = useCallback(
    (id, action) => () => {
      const url = `${mainPathname}/${meetingId}/${action}/${id}`
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== meetingId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, meetingId]
  )

  const renderDetail = useCallback(() => {
    const newData = [
      {
        label: 'Tên cuộc họp:',
        value: dataDetailMetting?.title || '',
      },
      {
        label: 'Số hiệu văn bản:',
        value: dataDetailMetting?.textNumber || '',
      },
      {
        label: 'Lãnh đạo chủ trì:',
        value: dataDetailMetting?.mainResponsibility || null,
        type: 'AVATAR',
        className: 'align-items-center',
      },
      {
        label: 'Ngày ban hành VB:',
        value: dataDetailMetting?.executionDate,
        type: 'DATE',
      },
      {
        label: 'Loại cuộc họp:',
        value:
          dsLoaiCuocHop.find(
            elm => elm?.value === dataDetailMetting?.meetingType
          )?.label || '',
      },
      {
        label: 'Đơn vị:',
        value: dataDetailMetting?.organizationName,
      },
      {
        label: 'File văn bản:',
        value: dataDetailMetting?.files,
        type: 'FILES',
      },
    ]

    return (
      <ul>
        {newData?.map((item, index) => {
          let content = <span>{item?.value}</span>
          if (item?.type === 'DATE') {
            content = (
              <div>
                {moment(item?.value).isValid()
                  ? moment(item?.value).format(DATA_FORMAT)
                  : ''}
              </div>
            )
          } else if (item?.type === 'FILES') {
            content = <FileGroup list={get(item, 'value')} />
          } else if (item?.type === 'AVATAR') {
            content = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={get(item, 'value.avatar')}
                  className="mr10 tiny"
                  needBaseUrl
                />
                <div className="user-info">
                  <p className="font-weight-600 no-wrap">
                    {get(item, 'value.name')}
                  </p>
                  <span className="font-size-10">
                    {get(item, 'value.information.organization.shortName')}
                  </span>
                </div>
              </div>
            )
          }

          return (
            <li key={index} className={classNames('mb15', item?.className)}>
              <label className="label font-size-13 font-weight-600">
                {item?.label}
              </label>
              {fetchingDetail ? (
                <div
                  className="cpc-loading-wrapper"
                  style={{ minWidth: 150, padding: 0 }}
                >
                  <div className="cpc-loading-block">
                    <span className={classNames('cpc-loading-bar', `item-5`)} />
                  </div>
                </div>
              ) : (
                <div className="font-size-13 white-space-break-spaces">
                  {content}
                </div>
              )}
            </li>
          )
        })}
      </ul>
    )
  }, [fetchingDetail, dataDetailMetting, dsLoaiCuocHop])

  const fetchDetail = useCallback(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetchingDetail, true),
      action: () => getMeeting(meetingId),
      afterResponse: response => {
        mountedSet(setDataDetailMetting, response || {})
      },
      afterAction: () => mountedSet(setFetchingDetail, false),
    })
  }, [meetingId])

  const _fetchList = useCallback(
    requestFilter => {
      const params = {
        page: requestFilter?.page,
        [filterKeys.title]: requestFilter?.[filterKeys.title]
          ? requestFilter?.[filterKeys.title]?.trim()
          : undefined,
        [filterKeys.chuTri]:
          requestFilter?.[filterKeys.chuTri] &&
          requestFilter?.[filterKeys.chuTri]?.code !== 'ALL'
            ? requestFilter?.[filterKeys.chuTri]?.id
            : undefined,
        [filterKeys.phoiHop]:
          requestFilter?.[filterKeys.phoiHop] &&
          requestFilter?.[filterKeys.phoiHop]?.code !== 'ALL'
            ? requestFilter?.[filterKeys.phoiHop]?.id
            : undefined,
        [filterKeys.ngayBatDau]:
          requestFilter?.[filterKeys.ngayBatDau] &&
          moment(requestFilter?.[filterKeys.ngayBatDau]).isValid()
            ? moment(requestFilter?.[filterKeys.ngayBatDau]).format(
                DATETIME_FORMAT
              )
            : undefined,
        [filterKeys.hanThucHien]:
          requestFilter?.[filterKeys.hanThucHien] &&
          moment(requestFilter?.[filterKeys.hanThucHien]).isValid()
            ? moment(requestFilter?.[filterKeys.hanThucHien]).format(
                DATETIME_FORMAT
              )
            : undefined,
        [filterKeys.trangThai]:
          requestFilter?.[filterKeys.trangThai] &&
          requestFilter?.[filterKeys.trangThai] !== 'ALL'
            ? requestFilter?.[filterKeys.trangThai]
            : undefined,
        [filterKeys.trangThaiDuyet]:
          requestFilter?.[filterKeys.trangThaiDuyet] &&
          requestFilter?.[filterKeys.trangThaiDuyet] !== 'ALL'
            ? requestFilter?.[filterKeys.trangThaiDuyet]
            : undefined,
      }
      requestAction({
        codeCheck: false,
        showToast: false,
        getResult: true,
        beforeAction: () => mountedSet(setFetching, true),
        action: () => getWorksMeeting(meetingId, params),
        afterResponse: response => {
          mountedSet(setDataWorksMetting, response?.items || [])
          mountedSet(setTotalData, response?.totalCount || 0)
        },
        afterAction: () => mountedSet(setFetching, false),
        afterError: () => mountedSet(setFetchingFail, true),
      })
    },
    // eslint-disable-next-line
    [meetingId]
  )

  const changeFilter =
    ({ name, reFetch = true, multipleKey = false }) =>
    value => {
      let newFilter = { ...filter, page: 1 }
      if (name === 'RESET') {
        newFilter = DEFAULT_FILTER
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else {
        newFilter = { ...newFilter, [name]: value }
      }

      setFilter(newFilter)
      if (reFetch) {
        _fetchList(newFilter)
      }
    }

  const getMenuAction = useCallback(
    ({ id, creatorProfileId, approval, status } = {}) => {
      const allowAction = creatorProfileId === roleId
      const { huyDuyet, chinhSuaCongViec, xoa } = pageActions
      let isSecretaryApprove = false
      if (approval === WORK_MEETING_STATUS.APPROVED) {
        isSecretaryApprove = true
      }

      let isAbleDeleted = false
      if (
        allowAction &&
        !isSecretaryApprove &&
        status === WORK_STATUS.CHUA_THUC_HIEN
      ) {
        isAbleDeleted = true
      }

      return [
        ...(allowAction && isSecretaryApprove
          ? [
              {
                icon: 'icon-Huy size-icon-12 color-red-important',
                text: 'Huỷ duyệt',
                className: 'color-red',
                onClick: goToAction(id, huyDuyet),
              },
            ]
          : []),
        ...(allowAction && !isSecretaryApprove
          ? [
              {
                icon: 'icon-but size-icon-15',
                text: 'Sửa/Hủy',
                onClick:
                  status !== WORK_STATUS.HOAN_THANH
                    ? goToAction(id, chinhSuaCongViec)
                    : () =>
                        setDataDialog({
                          isOpen: true,
                          data: {
                            id,
                            key: chinhSuaCongViec,
                          },
                        }),
              },
            ]
          : []),
        ...(isAbleDeleted
          ? [
              {
                icon: 'icon-bin size-icon-15',
                text: 'Xóa',
                onClick: goToAction(id, xoa),
              },
            ]
          : []),
      ]
    },

    [pageActions, roleId, goToAction]
  )

  const handleStatus = status => {
    let text = 'Chưa thực hiện'
    let color = 'yellow'
    switch (status) {
      case WORK_STATUS.DANG_THUC_HIEN:
        text = 'Đang thực hiện'
        color = 'blue'
        break
      case WORK_STATUS.HOAN_THANH:
        text = 'Hoàn thành'
        color = 'green'
        break
      case WORK_MEETING_STATUS.APPROVED:
        text = 'Đã duyệt'
        color = 'green'
        break
      case WORK_MEETING_STATUS.NOT_APPROVED_YET:
        text = '-'
        color = ''
        break
      case WORK_STATUS.HOAN_THANH_CHAM:
        text = 'Hoàn thành chậm'
        color = 'red'
        break
      case WORK_MEETING_STATUS.WAITING_APPROVAL:
        text = 'Chờ duyệt'
        color = 'orange'
        break
      default:
        break
    }

    return (
      <span
        className={classNames(
          'status-metting text-uppercase font-size-10 font-weight-600 no-wrap',
          color
        )}
      >
        {text}
      </span>
    )
  }

  const columns = () => {
    return [
      {
        key: 'stt',
        width: 5,
        headClass: 'uppercase',
        className: 'align-center min-width-60 text-center',
        title: 'Stt',
        render: (v, r, index) => {
          const num = (get(filter, 'page', 1) - 1) * 10 + index + 1
          return `${num}`.padStart(num < 10 ? 2 : 1, '0')
        },
      },
      {
        key: 'title',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Nội dung kết luận',
        filter: (
          <Input
            clearable
            onChange={changeFilter({ name: filterKeys.title, reFetch: false })}
            onClear={changeFilter({ name: filterKeys.title })}
            onSend={changeFilter({ name: filterKeys.title })}
            placeholder="Nhập"
            value={filter?.[filterKeys.title]}
          />
        ),
        render: ({
          title,
          status,
          workUserTags = [],
          referenceId,
          reference,
        }) => (
          <div>
            <span
              className={classNames('white-space-break-spaces', {
                'color-red': status === 'DONE_SLOW',
              })}
            >
              {title?.length > 20 ? (
                <TruncateTextTooltip content={title} />
              ) : (
                title
              )}
            </span>
            <TagGroup
              popoverClassName="ml15 mb5"
              popoverPosition={Position.TOP_LEFT}
              className={classNames({ mt5: !!size(workUserTags) })}
              tags={workUserTags.map(
                ({ workTagId, workTagName, backgroundColor }) => ({
                  id: workTagId,
                  name: workTagName,
                  color: backgroundColor,
                })
              )}
              preChildren={
                <>
                  {!!referenceId && (
                    <>
                      {reference?.daLuuTuPhongBan && (
                        <Tooltip
                          content={
                            <span className="font-size-13">
                              Công việc đã lưu tủ tài liệu
                            </span>
                          }
                          position={Position.BOTTOM}
                        >
                          <Icon
                            classIcon="icon-Save-DocumentCabinet"
                            className={classNames(
                              'icon-color-primary',
                              'size-icon-18',
                              'mr10 mb5',
                              'd-block'
                            )}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        content={
                          <span className="font-size-13">
                            Công việc Ký số, Công văn
                          </span>
                        }
                        position={Position.BOTTOM}
                      >
                        <Icon
                          classIcon="icon2-e-office-label"
                          className={classNames(
                            'icon-color-primary',
                            'size-icon-18',
                            'mr10 mb5',
                            'd-block'
                          )}
                        />
                      </Tooltip>
                    </>
                  )}
                </>
              }
            />
          </div>
        ),
      },
      {
        key: 'presiding_name',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị chủ trì',
        filter: (
          <SelectTree
            inputWrapperClassName={
              'input-not-ph filter-column-border-hide right-bottom'
            }
            dataSource={[
              { name: 'Tất cả', code: 'ALL' },
              ...dataTreeOrganizationDepartmentStore?.map(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }

                return e
              }),
            ]}
            keySource={['name', 'code']}
            placeholder="Phòng ban/ Đơn vị"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            radioName={filterKeys.chuTri}
            value={filter?.[filterKeys.chuTri]}
            onChange={changeFilter({
              name: filterKeys.chuTri,
            })}
          />
        ),
        render: ({ presidingName, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {presidingName || '-'}
          </span>
        ),
      },
      {
        key: 'coordination_name',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị phối Hợp',
        filter: (
          <SelectTree
            inputWrapperClassName={
              'input-not-ph filter-column-border-hide right-bottom'
            }
            dataSource={[
              { name: 'Tất cả', code: 'ALL' },
              ...dataTreeOrganizationDepartmentStore?.map(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }

                return e
              }),
            ]}
            keySource={['name', 'code']}
            placeholder="Phòng ban/ Đơn vị"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            radioName={filterKeys.phoiHop}
            value={filter?.[filterKeys.phoiHop]}
            onChange={changeFilter({
              name: filterKeys.phoiHop,
            })}
          />
        ),
        render: ({ coordinationName, status }) => {
          const arrayData = coordinationName?.split(', ') || []
          return (
            <span
              className={classNames({ 'color-red': status === 'DONE_SLOW' })}
            >
              {size(arrayData) !== 0 ? (
                <ViewMoreDots data={arrayData} isTotal={true} />
              ) : (
                '-'
              )}
            </span>
          )
        },
      },
      {
        key: 'executionDate',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Ngày Bắt đầu',
        filter: (
          <DatePicker
            leftIcon=""
            clearable={true}
            valueDisplay={null}
            onChange={changeFilter({
              name: filterKeys.ngayBatDau,
            })}
          />
        ),
        render: ({ executionDate, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {moment(executionDate).isValid()
              ? moment(executionDate).format(DATA_FORMAT)
              : '-'}
          </span>
        ),
      },
      {
        key: 'deadline',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Hạn thực hiện',
        filter: (
          <DatePicker
            leftIcon=""
            clearable={true}
            valueDisplay={null}
            onChange={changeFilter({
              name: filterKeys.hanThucHien,
            })}
          />
        ),
        render: ({ deadline, status }) => {
          const today = moment().startOf('day')
          const diff = moment(deadline).startOf('day').diff(today, 'days')
          const daTre = diff < 0
          const ganTre = diff >= 0 && diff - 3 <= 0
          const isChuaHoanThanh =
            status === WORK_STATUS.CHUA_THUC_HIEN ||
            status === WORK_STATUS.DANG_THUC_HIEN

          return (
            <div>
              <p
                className={classNames({
                  'color-red': status === 'DONE_SLOW',
                  'font-weight-600': isChuaHoanThanh && (daTre || ganTre),
                  'text-danger': isChuaHoanThanh && daTre,
                  'color-orange-lv1': isChuaHoanThanh && ganTre,
                })}
              >
                {moment(deadline).isValid()
                  ? moment(deadline).format(DATA_FORMAT)
                  : '-'}
              </p>
              {isChuaHoanThanh &&
              (daTre || ganTre) &&
              moment(deadline).isValid() ? (
                <span className={classNames('font-size-11 disabled')}>
                  {daTre
                    ? `Đã trễ hạn ${Math.abs(diff)} ngày`
                    : ganTre && diff !== 0
                    ? `(Còn ${diff} ngày đến hạn)`
                    : `(Đến hạn)`}
                </span>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        key: 'status',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center min-width-100',
        title: 'Trạng thái',
        filter: (
          <Select
            dataSource={dsStatus || []}
            onChange={changeFilter({ name: filterKeys.trangThai })}
            placeholder="Chọn"
            value={filter?.[filterKeys.trangThai]}
          />
        ),
        render: ({ status }) => {
          return handleStatus(status)
        },
      },
      {
        key: 'luongCongViec',
        width: 5,
        headClass: 'uppercase',
        className: 'align-center justify-center min-width-100',
        title: 'Luồng cv',
        render: ({ id }) => (
          <div onClick={e => e.stopPropagation()}>
            <div
              className="color-blue pointer"
              onClick={goToAction(id, pageActions.flowWorks)}
            >
              <Icon
                classIcon={'icon-organization'}
                className={classNames('mr10', 'size-icon-13')}
              />
              <span>Chi tiết</span>
            </div>
          </div>
        ),
      },
      {
        key: 'approval',
        width: 15,
        headClass: 'uppercase',
        className: 'align-center justify-center min-width-140',
        title: 'Trạng thái duyệt',
        filter: (
          <Select
            dataSource={dsStatusApprobal || []}
            onChange={changeFilter({ name: filterKeys.trangThaiDuyet })}
            placeholder="Chọn"
            value={filter?.[filterKeys.trangThaiDuyet]}
          />
        ),
        render: ({ approval }) => {
          return handleStatus(approval)
        },
      },
      {
        key: 'thaoTac',
        className: 'element-center max-width-60 min-width-60 align-center',
        width: 5,
        render: record => {
          const menu = getMenuAction(record) || []
          if (!size(menu)) return null
          return (
            <Popover
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <Menu className="cpc-context-menu border-none">
                  {menu.map(
                    ({ icon, text, className, onClick = () => {} }, index) => (
                      <Fragment key={index}>
                        {!!index && <MenuDivider />}
                        <MenuItem
                          text={
                            <span
                              className={classNames(
                                'cpc-context-menu-item',
                                className
                              )}
                            >
                              <i className={classNames(icon)} /> {text}
                            </span>
                          }
                          onClick={onClick}
                        />
                      </Fragment>
                    )
                  )}
                </Menu>
              }
            >
              <span
                className="icon-More size-icon-24"
                onClick={e => e.stopPropagation()}
              />
            </Popover>
          )
        },
      },
    ]
  }

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const fetchDepartmentsUnit = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  name: 'Phòng ban',
                  code: 'Phòng ban',
                  parentId: null,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  name: 'PC/ Đơn vị',
                  code: 'PC/ Đơn vị',
                  parentId: null,
                  children: organizationData,
                },
              ]
            : []),
        ]
        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree2(newData),
        })
      },
    })
  }, [mainUnitId])

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => mountedSet(setTags, items),
    })
  }, [])

  const handleRedirect = useCallback(() => {
    const hasGoBack = get(location, 'state.showGoBack', false)
    hasGoBack
      ? history.goBack()
      : history.push(`/thong-bao-ket-luan-cuoc-hop/chi-tiet/${meetingId}`)
  }, [location, history, meetingId])

  const openCreateWork = useCallback(() => {
    const url = `/thong-bao-ket-luan-cuoc-hop/chi-tiet/${meetingId}/${pageActions.taoMoiCongViec}`
    history.push(url)
  }, [history, meetingId, pageActions.taoMoiCongViec])

  const actionBlock = useMemo(() => {
    const {
      flowWorks,
      chinhSua,
      taoMoiCongViec,
      chinhSuaCongViec,
      huyDuyet,
      xoa,
      chiTietCongViec,
      nhacViec,
      tag,
      xacNhan,
      acceptHistory,
    } = pageActions
    const actionOverlays = {
      [flowWorks]: FlowWorksDetail,
      [chinhSua]: MeetingFormUpdate,
      [taoMoiCongViec]: WorkMeetingUpdate,
      [chinhSuaCongViec]: WorkMeetingUpdate,
      [huyDuyet]: ApproveSecretary,
      [xoa]: DeleteWorkMeeting,
      [chiTietCongViec]: WorkMeetingDetail,
      [nhacViec]: Reminder,
      [tag]: workMeetingTags,
      [xacNhan]: Accept,
      [acceptHistory]: AcceptHistory,
    }
    const ActionOverlay = actionOverlays[currentAction]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        onClose={handleRedirect}
        reloadDetail={() => fetchDetail()}
        reloadList={() => _fetchList(filter)}
      />
    )
  }, [
    pageActions,
    currentAction,
    fetchDetail,
    _fetchList,
    handleRedirect,
    filter,
  ])

  const onTagsUpdate = useCallback(tags => {
    mountedSet(setTags, tags)
  }, [])

  const onTagsSucess = useCallback(
    ({ id: taskId, newTags }) => {
      mountedSet(
        setDataWorksMetting,
        dataWorksMetting.map(task =>
          task.id === taskId ? { ...task, workUserTags: newTags } : task
        )
      )
    },
    [dataWorksMetting]
  )

  const onGoBack = () => {
    return history.push('/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop')
  }

  const checkLayout = keyName => {
    const data = [
      pageActions.flowWorks,
      pageActions.chinhSua,
      pageActions.taoMoiCongViec,
      pageActions.chinhSuaCongViec,
      pageActions.huyDuyet,
      pageActions.xoa,
      pageActions.chiTietCongViec,
      pageActions.nhacViec,
      pageActions.tag,
      pageActions.xacNhan,
      pageActions.acceptHistory,
    ]
    if (data.includes(keyName)) {
      return true
    }

    return false
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  useEffect(() => {
    _fetchList(DEFAULT_FILTER)
  }, [_fetchList, DEFAULT_FILTER])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  useEffect(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getLanhDaoChuTri(mainUnitId),
    })
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getThongTinDonVi({ donViId: mainUnitId }),
    })
  }, [mainUnitId])

  const contextValue = {
    tags,
    dataTreeOrganizationDepartmentStore,
    mainDepartmentId,
    hasShowAllDeparment,
    dataThongTinDonVi,
    dsLoaiCuocHop,
    filter,
    totalData,
    dataDetailMetting,
    onTagsUpdate,
    onTagsSucess,
  }

  const allowAction = dataDetailMetting?.creatorProfileId === roleId

  return (
    <MasterLayout typeSidebar="taskManager" newLayout>
      <MeetingDetailContext.Provider value={contextValue}>
        <div style={{ flex: 1, padding: 40, position: 'relative' }}>
          <header className="d-flex mb20">
            <div style={{ marginRight: 'auto' }}>
              <div
                className={classNames('font-size-20 font-weight-600', {
                  'color-light': get(
                    allCookies,
                    'background_image.is_light',
                    false
                  ),
                })}
              >
                <div className="d-flex align-center">
                  <Button
                    className={classNames(
                      'cpc-button',
                      'no-padding',
                      'rounded',
                      'mr10'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={onGoBack}
                  >
                    <Icon classIcon="icon2-arrow-left" />
                  </Button>
                  <div>
                    Số hiệu văn bản:{' '}
                    <span className="color-blue white-space-break-spaces">
                      {dataDetailMetting?.textNumber}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span style={{ marginLeft: 'auto' }}>
              {allowAction && (
                <Tooltip
                  position={Position.TOP_RIGHT}
                  content={
                    <span className="font-size-12">
                      Sửa thông báo kết luận cuộc họp
                    </span>
                  }
                >
                  <Button
                    className={classNames(
                      'cpc-button',
                      'no-padding',
                      'rounded'
                    )}
                    intent={Intent.SUCCESS}
                    onClick={goToAction(
                      dataDetailMetting?.id,
                      pageActions.chinhSua
                    )}
                  >
                    <Icon classIcon="icon-but" />
                  </Button>
                </Tooltip>
              )}
              <BackgroundSetting />
            </span>
          </header>
          <div className="panel-meeting-detail mb20">{renderDetail()}</div>
          <header className="d-flex mb20">
            <div style={{ marginRight: 'auto' }}>
              <span
                className={classNames('font-size-20 font-weight-600', {
                  'color-light': get(
                    allCookies,
                    'background_image.is_light',
                    false
                  ),
                })}
              >
                Danh sách kết luận
              </span>
            </div>
            <span style={{ marginLeft: 'auto' }}>
              {allowAction && (
                <Tooltip
                  position={Position.TOP_RIGHT}
                  content={<span className="font-size-12">Tạo kết luận</span>}
                >
                  <Button
                    className={classNames(
                      'cpc-button',
                      'no-padding',
                      'rounded'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={openCreateWork}
                  >
                    <Icon classIcon="icon-Plus" />
                  </Button>
                </Tooltip>
              )}
            </span>
          </header>
          <Dialog
            buttonUppercase
            isOpen={dataDialog?.isOpen}
            cancelClassName="pl20 pr20"
            cancelText="Hủy"
            confirmClassName="pl20 pr20"
            confirmText="Sửa/Hủy"
            loading={false}
            onCancel={() =>
              setDataDialog({
                isOpen: false,
                data: null,
              })
            }
            onConfirm={() => {
              setDataDialog({
                isOpen: false,
                data: null,
              })
              goToAction(dataDialog?.data?.id, dataDialog?.data?.key)()
            }}
            onClose={() =>
              setDataDialog({
                isOpen: false,
                data: null,
              })
            }
            title={'Thông báo'}
            textCenter
          >
            {
              'Công việc đang chọn đã hoàn thành. Bạn có chắc chắn muốn sửa/hủy hay không?'
            }
          </Dialog>
          <Table
            className="cpc-table-radius"
            autoHide={false}
            columns={columns()}
            data={dataWorksMetting}
            loading={fetching}
            error={fetchingFail}
            tableMinWidth={1200}
            onRowClick={({ id }) =>
              goToAction(id, pageActions.chiTietCongViec)()
            }
          />
          <div className="element-flex-end w-100 mt15">
            <Pagination
              total={totalData}
              current={get(filter, 'page', 1)}
              pageSize={PAGE_SIZE}
              onChange={changeFilter({ name: 'page' })}
            />
          </div>
        </div>
        {checkLayout(currentAction) && <div className="mask-layout"></div>}
        {actionBlock}
      </MeetingDetailContext.Provider>
    </MasterLayout>
  )
}

MeetingDetail.propTypes = {
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasShowAllDeparment: PropTypes.bool,
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTreeOrganizationDepartmentStore: PropTypes.array,
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const hasShowAllDeparment = get(state, 'auth.permission', []).includes(
    Permission.THONGBAOKETLUANHDTV
  )

  return {
    hasShowAllDeparment,
    roleId,
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    dataThongTinDonVi: get(state, 'common.dataThongTinDonVi'),
    dataTreeOrganizationDepartmentStore: get(
      state,
      'common.dataTreeOrganizationDepartmentStore'
    ),
  }
}

export default withCookies(connect(mapStateToProps)(memo(MeetingDetail)))
