import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react'
import {
  Popover,
  Position,
  PopoverInteractionKind,
  Button,
  Intent,
  Menu,
  MenuItem,
  MenuDivider,
  Tooltip,
} from '@blueprintjs/core'
import classNames from 'classnames'
import { get, size, find } from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  StatusDate,
  PrioritizeFlag,
  TagGroup,
  Input,
  Select,
  UserTree,
  Icon,
  Avatar,
  Badge,
  CheckboxButton,
} from 'components/newCommon'
import { Table, Pagination, Progress } from 'components/newCommon2'
import { ViewMoreDots, TruncateTextTooltip } from 'components/common'
import {
  WORK_PRIORITY,
  WORK_STATUS,
  DEADLINE_STATUS,
  ASSIGN_TYPE,
  READ_STATUS,
  WORK_TYPE,
} from 'constants/Enum'
import { TaskContext } from '../AllTask'
import { Filter } from '../Filter'
import RightContextMenu from '../ContextMenu/Menu'
import requestAction from 'helpers/request'
import {
  patchTienDo,
  getThongKeLoaiGiaoViec,
  getInformationWorkItemAction,
} from 'actions/task'
import { Action, Toast, TEXT_HOANTHANH_CONGVIEC } from 'constants/MessageForm'

const dateFormat = 'DD/MM/YYYY'

const actionEnum = {
  chinhSua: 'chinh-sua',
  xoa: 'xoa',
  chiTiet: 'chi-tiet',
  luongCongViec: 'luong-cong-viec',
}

const tabsType = {
  DONE: 'DONE',
}

const PAGE_SIZE = 10

const dropdownSource = {
  deadline: [
    { label: 'Tất cả', value: null },
    { label: 'Gần đến hạn', value: DEADLINE_STATUS.CON_HAN },
    { label: 'Quá hạn', value: DEADLINE_STATUS.QUA_HAN },
  ],
  status: [
    { label: 'Tất cả', value: null },
    { label: 'Chưa thực hiện', value: WORK_STATUS.CHUA_THUC_HIEN },
    { label: 'Đang thực hiện', value: WORK_STATUS.DANG_THUC_HIEN },
    { label: 'Đã thực hiện', value: WORK_STATUS.HOAN_THANH },
  ],
  prioritizes: [
    { label: 'Tất cả', value: null },
    { label: 'Cao', value: WORK_PRIORITY.CAO },
    { label: 'Bình thường', value: WORK_PRIORITY.BINH_THUONG },
    { label: 'Thấp', value: WORK_PRIORITY.THAP },
  ],
  readStatus: [
    { label: 'Tất cả', value: null },
    { label: 'Đã đọc', value: READ_STATUS.DA_DOC },
    { label: 'Chưa đọc', value: READ_STATUS.CHUA_DOC },
  ],
}

const dataVaiTro = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Chủ trì',
    value: 'MAIN_RESPONSIBILITY',
  },
  {
    label: 'Phối hợp',
    value: 'COORDINATION',
  },
]

const dataDuyet = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Chưa duyệt',
    value: 'NOT_APPROVED_YET',
  },
  {
    label: 'Đã duyệt',
    value: 'APPROVED',
  },
  {
    label: 'Chờ duyệt',
    value: 'WAITING_APPROVAL',
  },
]

const DEFAULT_CONTEXT_PROPS = {
  isOpen: false,
  data: {},
  position: { top: 0, left: 0 },
}

const filterKeys = {
  title: 'Title',
  deadline: 'WorkDeadLine',
  assigner: 'AssignerId',
  assignerStorage: 'assignerStorage',
  executor: 'MainResponsibilityId',
  executorStorage: 'executorStorage',
  status: 'Status',
  priority: 'WorkPriority',
  from: 'FromDate',
  to: 'ToDate',
  tag: 'TagIds',
  read: 'IsRead',
  responsibilityType: 'ResponsibilityType',
  approval: 'Approval',
}

const List = ({ match, history, roleId, statusCounter = [] }) => {
  const { type: taskType, id: currentId, action: currentAction } = match.params
  const mainPathname = useMemo(
    () => `/quan-ly-cong-viec/${taskType}`,
    [taskType]
  )

  const congViecDOffice = taskType === 'cong-viec-d-office'
  const toiGiao = taskType === 'toi-giao'
  const duocGiao = taskType === 'duoc-giao'
  const theoDoi = taskType === 'theo-doi'

  const {
    fetchStatus = {},
    data = [],
    filter: {
      page: currentPage = 1,
      [filterKeys.title]: filterTitle = '',
      [filterKeys.deadline]: filterDeadline,
      [filterKeys.status]: filterStatus,
      [filterKeys.priority]: filterPriority,
      [filterKeys.executorStorage]: filterExecutorStorage,
      [filterKeys.assignerStorage]: filterAssignerStorage,
      [filterKeys.tag]: filterTag = [],
      [filterKeys.read]: filterRead,
      [filterKeys.responsibilityType]: filterResponsibilityType,
      [filterKeys.approval]: filterApproval,
    } = {},
    totalPage = 1,
    changeFilter = () => {},
    departments = [],
    fetchUsers = () => {},
    reloadData = () => {},
    setDetail,
    markAsRead,
    counterTabsList,
    buttonTabs,
  } = useContext(TaskContext)
  const root = document.querySelector('#root')

  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const [menuProps, setMenuProps] = useState(DEFAULT_CONTEXT_PROPS)
  const [disableCheckBox, setDisableCheckBox] = useState(false)

  const hideContext = () => {
    mountedSet(setMenuProps, DEFAULT_CONTEXT_PROPS)
    if (root) {
      root.removeEventListener('click', hideContext)
    }
  }

  const getDeadline = detail => {
    return get(detail, 'deadline')
  }

  const renderVaiTro = (isMainResponsibility, responsibilityType) => {
    if (!responsibilityType) {
      return ''
    }

    let name = 'Phối hợp'
    let className = ''
    if (responsibilityType === 'MAIN_RESPONSIBILITY') {
      name = 'Chủ trì'
      if (isMainResponsibility) {
        className = 'text-blue'
      }
    }

    return <span className={className}>{name}</span>
  }

  const getDataCheck = async record => {
    let result = null
    await requestAction({
      showToast: false,
      action: () => getInformationWorkItemAction(record?.id),
      afterResponse: res => {
        result = res?.assigned
      },
    })
    return result
  }

  const showContext = (record, e) => {
    e.preventDefault()
    const status = record?.status
    getDataCheck(record).then(assigned => {
      const hasGiveTaskAction =
        status === WORK_STATUS.CHUA_THUC_HIEN &&
        ((assigned !== null && duocGiao) || toiGiao)
      const isEditGiveTask = assigned || toiGiao
      setMenuProps(prev => ({
        ...prev,
        data: record,
        isEditGiveTask,
        hasGiveTaskAction,
      }))
    })
    const hasSaveDocument = !!get(record, 'referenceId')
    setMenuProps(prev => ({
      ...prev,
      hasSaveDocument,
      isOpen: true,
      data: record,
      position: { left: e.pageX, top: e.pageY },
    }))
    if (root) {
      root.addEventListener('click', hideContext)
    }
  }

  const handleStatus = useCallback(status => {
    let text = ''
    let color = ''
    switch (status) {
      case 'APPROVED':
        text = 'Đã duyệt'
        color = 'green'
        break
      case 'WAITING_APPROVAL':
        text = 'Chờ duyệt'
        color = 'orange'
        break
      default:
        text = ''
        color = ''
        break
    }

    return (
      <span
        className={classNames(
          'status-metting text-uppercase font-size-10 font-weight-600 no-wrap',
          color
        )}
      >
        {text}
      </span>
    )
  }, [])

  // Thao tác công việc
  const goToAction = useCallback(
    (id, action) => () => {
      const url = `${mainPathname}/${action}/${id}`
      if (action === actionEnum.chiTiet) markAsRead(id)
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, currentId, markAsRead]
  )

  const getMenuAction = useCallback(
    ({
      id,
      status = WORK_STATUS.CHUA_THUC_HIEN,
      creatorProfileId = null,
      workType,
    } = {}) => {
      const allowAction =
        status === WORK_STATUS.CHUA_THUC_HIEN &&
        creatorProfileId === roleId &&
        !theoDoi &&
        taskType !== 'cong-viec-d-office' &&
        workType !== WORK_TYPE.MEETING
      return [
        ...(allowAction
          ? [
              {
                icon: 'icon-but',
                text: 'Sửa công việc',
                onClick: goToAction(id, actionEnum.chinhSua),
              },
            ]
          : []),
        ...(allowAction
          ? [
              {
                icon: 'icon-bin',
                text: 'Xóa công việc',
                onClick: goToAction(id, actionEnum.xoa),
              },
            ]
          : []),
      ]
    },

    [roleId, theoDoi, taskType, goToAction]
  )

  // hoàn thành nhanh
  const fastFinish = (taskId, referenceId) => () => {
    const requestData = {
      progress: 100,
      status: WORK_STATUS.HOAN_THANH,
    }
    requestAction({
      getResult: false,
      beforeAction: () => {
        mountedSet(setDisableCheckBox, true)
      },
      action: () => patchTienDo(taskId, requestData),
      afterResponse: () => {
        reloadData()
        setDetail(prevDetail => ({ ...prevDetail, ...requestData }))
        getThongKeLoaiGiaoViec()
        mountedSet(setDisableCheckBox, false)
      },
      successMessage: TEXT_HOANTHANH_CONGVIEC,
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  // Cấu trúc bảng
  const columns = [
    ...(filterStatus !== tabsType.DONE
      ? [
          {
            key: 'hoanThanh',
            width: 5,
            className: 'align-center element-center min-width-40',
            dataIndex: '',
            render: ({ mainResponsibility, id, referenceId, status }) => (
              <span onClick={e => e.stopPropagation()}>
                <Tooltip
                  isDisabled={
                    get(mainResponsibility, 'id') !== roleId ||
                    status === WORK_STATUS.HOAN_THANH ||
                    disableCheckBox
                  }
                  content={
                    <span className="font-size-13">Hoàn thành nhanh</span>
                  }
                  position={Position.TOP}
                >
                  <CheckboxButton
                    disabled={
                      get(mainResponsibility, 'id') !== roleId ||
                      status === WORK_STATUS.HOAN_THANH ||
                      disableCheckBox
                    }
                    styleIcon={{
                      fontSize: '18px',
                    }}
                    onClick={fastFinish(id, referenceId)}
                  />
                </Tooltip>
              </span>
            ),
          },
        ]
      : []),
    {
      key: 'trichYeu',
      width: 30,
      headClass: 'uppercase',
      className: 'align-center min-width-200',
      title: 'Trích yếu / Tiêu đề',
      filter: (
        <Input
          clearable
          onChange={changeFilter({ name: filterKeys.title, reFetch: false })}
          onClear={changeFilter({ name: filterKeys.title })}
          onSend={changeFilter({ name: filterKeys.title })}
          placeholder="Nhập"
          value={filterTitle}
        />
      ),
      render: ({
        title,
        workItemTag = [],
        isDaLuuTuPhongBan,
        isRead,
        workType,
      }) => (
        <div>
          <span
            className={classNames({
              'text-color medium word-break-word white-space-break-spaces':
                theoDoi && !isRead,
            })}
          >
            {title?.length > 20 ? (
              <TruncateTextTooltip content={title} />
            ) : (
              title
            )}
          </span>
          <TagGroup
            popoverClassName="ml15 mb5"
            popoverPosition={Position.TOP_LEFT}
            className={classNames({ mt5: !!size(workItemTag) })}
            tags={workItemTag.map(({ id, name, backgroundColor }) => ({
              id: id,
              name: name,
              color: backgroundColor,
            }))}
            preChildren={
              <>
                {workType === WORK_TYPE.EOFFICE && (
                  <>
                    {isDaLuuTuPhongBan && (
                      <Tooltip
                        content={
                          <span className="font-size-12">
                            Công việc đã lưu tủ tài liệu
                          </span>
                        }
                        position={Position.BOTTOM}
                      >
                        <Icon
                          classIcon="icon-Save-DocumentCabinet"
                          className={classNames(
                            'icon-color-primary',
                            'size-icon-18',
                            'mr10 mb5',
                            'd-block'
                          )}
                        />
                      </Tooltip>
                    )}
                    <Tooltip
                      content={
                        <span className="font-size-12">
                          Công việc Ký số, Công văn
                        </span>
                      }
                      position={Position.BOTTOM}
                    >
                      <Icon
                        classIcon="icon2-e-office-label"
                        className={classNames(
                          'icon-color-primary',
                          'size-icon-18',
                          'mr10 mb5',
                          'd-block'
                        )}
                      />
                    </Tooltip>
                  </>
                )}
                {workType === WORK_TYPE.MEETING && (
                  <Tooltip
                    content={
                      <span className="font-size-12">
                        Công việc Thông báo KLCH
                      </span>
                    }
                    position={Position.BOTTOM}
                  >
                    <Icon
                      classIcon="icon2-clipboard"
                      className={classNames(
                        'icon-color-yellow',
                        'size-icon-18',
                        'mr10 mb5',
                        'd-block'
                      )}
                    />
                  </Tooltip>
                )}
                {workType === WORK_TYPE.DOFFICE && (
                  <Tooltip
                    content={
                      <span className="font-size-12">Công việc D-Office</span>
                    }
                    position={Position.BOTTOM}
                  >
                    <Icon
                      classIcon="icon2-d-office"
                      className={classNames(
                        'icon-color-danger',
                        'size-icon-18',
                        'mr10 mb5',
                        'd-block'
                      )}
                    />
                  </Tooltip>
                )}
              </>
            }
            activeTags={filterTag}
            onClick={({ id }) => {
              const newSelectedTags = filterTag.includes(id)
                ? filterTag.filter(selectedId => selectedId !== id)
                : [...filterTag, id]
              changeFilter({
                name: filterKeys.tag,
              })(newSelectedTags)
            }}
          />
        </div>
      ),
    },
    ...(duocGiao || congViecDOffice
      ? [
          {
            key: 'nguoiGiao',
            width: 20,
            headClass: 'uppercase',
            className: 'min-width-100 align-center',
            title: 'Người giao',
            dataIndex: 'assigner',
            filter: (
              <UserTree
                dataSource={departments}
                onChange={value =>
                  changeFilter({
                    name: filterKeys.assignerStorage,
                    filterKey: { [filterKeys.assigner]: value?.id },
                  })(value)
                }
                onShowCollapse={fetchUsers}
                mainOnly
                placeholder="Chọn"
                value={filterAssignerStorage}
              />
            ),
            render: value => {
              if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={get(value, 'avatar')}
                      className="mr10 tiny"
                      needBaseUrl
                    />
                    {get(value, 'name')}
                  </div>
                )
              }

              return get(value, 'shortName')
            },
          },
        ]
      : [
          {
            key: 'nguoiThucHien',
            width: 20,
            headClass: 'uppercase',
            className: 'min-width-240 align-center',
            title: 'Chủ trì',
            dataIndex: 'mainResponsibility',
            filter: (
              <UserTree
                dataSource={departments}
                onChange={value =>
                  changeFilter({
                    name: filterKeys.executorStorage,
                    filterKey: { [filterKeys.executor]: value?.id },
                  })(value)
                }
                onShowCollapse={fetchUsers}
                mainOnly
                placeholder="Chọn"
                value={filterExecutorStorage}
              />
            ),
            render: (value, { isRead }) => {
              if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={get(value, 'avatar')}
                      className="mr10 tiny"
                      needBaseUrl
                    />
                    <span
                      className={classNames({
                        'text-color medium': theoDoi && !isRead,
                      })}
                    >
                      {get(value, 'name')}
                    </span>
                  </div>
                )
              }

              return get(value, 'shortName')
            },
          },
        ]),
    ...(toiGiao
      ? [
          {
            key: 'phoiHop',
            width: 20,
            headClass: 'uppercase',
            className: 'min-width-160 align-center',
            title: 'Phối hợp',
            dataIndex: 'coordination',
            render: record => {
              const coordinations = record?.filter(e => e?.id)
              if (size(coordinations) === 0) {
                return '-'
              }

              const arrayData = coordinations?.map(elm => {
                const type = elm?.type
                if (
                  type === ASSIGN_TYPE.DON_VI ||
                  type === ASSIGN_TYPE.PHONG_BAN
                ) {
                  return elm?.shortName
                }

                return elm?.name
              })
              return <ViewMoreDots data={arrayData} isTotal={true} />
            },
          },
        ]
      : []),
    {
      key: 'hanThucHien',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Hạn thực hiện',
      filter: (
        <Select
          placeholder="Chọn"
          dataSource={dropdownSource.deadline}
          onChange={changeFilter({ name: filterKeys.deadline })}
          value={filterDeadline}
        />
      ),
      render: ({ status }, record) => (
        <StatusDate
          date={getDeadline(record)}
          isCompleted={status === tabsType.DONE}
          descriptionSize={11}
        />
      ),
    },
    {
      key: 'tienDo',
      className: 'min-width-80 element-center align-center',
      headClass: 'uppercase',
      width: 5,
      title: 'Tiến độ',
      dataIndex: 'progress',
      filter: toiGiao ? (
        <Select
          placeholder="Chọn"
          dataSource={dropdownSource.status}
          onChange={changeFilter({ name: filterKeys.status })}
          value={filterStatus}
        />
      ) : null,
      render: (value = 0, record) => (
        <div>
          {value === 100 ? (
            <Icon className="icon-Bao_Cao font-size-13 color-icon-green font-weight-600" />
          ) : (
            <div style={{ width: 32 }}>
              <Progress
                progress={value}
                status={{ read: theoDoi && record.isRead }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'ngayHoanThanh',
      headClass: 'uppercase',
      className: 'min-width-140 align-center element-center',
      title: 'Ngày hoàn thành',
      render: ({ finishedDate }) => (
        <span className="font-weight-600 font-size-13">
          {finishedDate ? moment(finishedDate).format(dateFormat) : '-'}
        </span>
      ),
    },
    {
      key: 'uuTien',
      width: 5,
      align: 'center',
      headClass: 'uppercase',
      className: 'min-width-80 align-center',
      title: 'Ưu tiên',
      filter: (
        <Select
          placeholder="Chọn"
          dataSource={dropdownSource.prioritizes}
          onChange={changeFilter({ name: filterKeys.priority })}
          value={filterPriority}
        />
      ),
      dataIndex: 'priority',
      render: value => (
        <div>
          <PrioritizeFlag type={value} />
        </div>
      ),
    },
    ...(theoDoi
      ? [
          {
            key: 'read',
            width: 15,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-80 align-center',
            title: 'Trạng thái',
            filter: (
              <Select
                placeholder="Chọn"
                dataSource={dropdownSource.readStatus}
                onChange={changeFilter({ name: filterKeys.read })}
                value={filterRead}
              />
            ),
            dataIndex: 'isRead',
            render: value =>
              value ? (
                <span>Đã đọc</span>
              ) : (
                <span className="text-color medium">Chưa đọc</span>
              ),
          },
        ]
      : []),
    ...(!toiGiao && !theoDoi
      ? [
          {
            key: 'vaiTro',
            width: 6,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-80 align-center',
            title: 'Vai trò',
            filter: (
              <Select
                placeholder="Chọn"
                dataSource={dataVaiTro}
                onChange={changeFilter({ name: filterKeys.responsibilityType })}
                value={filterResponsibilityType}
              />
            ),
            render: ({ isMainResponsibility, responsibilityType }) =>
              renderVaiTro(isMainResponsibility, responsibilityType),
          },
        ]
      : []),
    ...(toiGiao || (theoDoi && filterStatus === tabsType.DONE)
      ? [
          {
            key: 'read',
            width: 10,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-80 align-center',
            title: 'Duyệt',
            dataIndex: 'approval',
            filter: (
              <Select
                placeholder="Chọn"
                dataSource={dataDuyet}
                onChange={changeFilter({ name: filterKeys.approval })}
                value={filterApproval}
              />
            ),
            render: value => {
              return handleStatus(value)
            },
          },
        ]
      : []),
    {
      key: 'luongCongViec',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center justify-center min-width-100',
      title: 'Luồng cv',
      render: ({ id }) => (
        <div onClick={e => e.stopPropagation()}>
          <div
            className="color-blue pointer"
            onClick={goToAction(id, actionEnum.luongCongViec)}
          >
            <Icon
              classIcon={'icon-organization'}
              className={classNames('mr10', 'size-icon-13')}
            />
            <span>Chi tiết</span>
          </div>
        </div>
      ),
    },
    {
      key: 'thaoTac',
      className: 'element-center max-width-60 min-width-60 align-center',
      width: 5,
      render: record => {
        const menu = getMenuAction(record) || []
        if (!size(menu)) return null
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <Menu className="cpc-context-menu border-none">
                {menu.map(({ icon, text, onClick = () => {} }, index) => (
                  <Fragment key={index}>
                    {!!index && <MenuDivider />}
                    <MenuItem
                      text={
                        <span className="cpc-context-menu-item">
                          <i className={classNames('size-icon-15', icon)} />{' '}
                          {text}
                        </span>
                      }
                      onClick={onClick}
                    />
                  </Fragment>
                ))}
              </Menu>
            }
          >
            <span
              className="icon-More size-icon-24"
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        )
      },
    },
  ]

  const renderBadgeTabs = type => {
    let result = 0
    if (
      size(Object.keys(counterTabsList)) &&
      Object.keys(counterTabsList)[0] === type
    ) {
      result = get(counterTabsList, type, 0)
    } else {
      result = get(find(statusCounter, { status: type }), 'totalCount', 0)
    }

    return (
      <Badge
        className={classNames('cpc-badge ml10 cpc-badge-status-tab')}
        count={result}
      />
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <>
      {!toiGiao && !congViecDOffice && (
        <div>
          {buttonTabs.map(({ value, text, themeClass }) => (
            <Button
              key={value}
              className={classNames(
                'cpc-button',
                'uppercase',
                'font-size-13',
                'relative',
                {
                  gray: filterStatus !== value,
                  [themeClass]: filterStatus === value,
                }
              )}
              intent={filterStatus === value ? Intent.PRIMARY : Intent.NONE}
              onClick={() =>
                changeFilter({ name: filterKeys.status, isReloadList: false })(
                  value
                )
              }
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: 2,
              }}
            >
              {text}
              {duocGiao && value !== tabsType.DONE && renderBadgeTabs(value)}
            </Button>
          ))}
        </div>
      )}
      <div className="mb15">
        <Filter />
      </div>

      <Table
        loading={get(fetchStatus, 'load', true)}
        error={get(fetchStatus, 'fail', false)}
        className="cpc-table-radius"
        autoHide={false}
        columns={columns}
        data={data}
        onRowClick={({ id }) => goToAction(id, actionEnum.chiTiet)()}
        onContextMenu={congViecDOffice ? null : showContext}
        tableMinWidth={1400}
        rowClassName={({ id }) => (id === currentId ? 'active' : '')}
      />

      <div className="text-right pt15">
        <Pagination
          total={totalPage}
          current={currentPage}
          pageSize={PAGE_SIZE}
          onChange={changeFilter({ name: 'page' })}
        />
      </div>

      <RightContextMenu {...menuProps} hideMenu={hideContext} />
    </>
  )
}

const mapStateToProps = state => ({
  roleId: get(state, 'auth.roleId'),
  statusCounter: get(
    state,
    'TaskManager.statisticsQuanLyCongViecTrangThaiCongViec.countStatus'
  ),
})

export default withRouter(connect(mapStateToProps)(memo(List)))
