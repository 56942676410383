import {
  GET_REQUEST_DASHBOARD,
  GET_PHIEU_LAY_Y_KIEN,
  GET_LAY_Y_KIEN_DON_VI,
} from 'constants/ActionTypes'

const initialState = {
  listDashboard: [],
  listPhieuLayYKien: null,
  listLayYKienDonVi: null,
}

export default function dashboard(state = initialState, { type, payload }) {
  switch (type) {
    case GET_REQUEST_DASHBOARD:
      return {
        ...state,
        listDashboard: payload?.data?.result?.items ?? [],
      }
    case GET_PHIEU_LAY_Y_KIEN:
      return {
        ...state,
        listPhieuLayYKien: payload?.data?.result || null,
      }
    case GET_LAY_Y_KIEN_DON_VI:
      return {
        ...state,
        listLayYKienDonVi: payload?.data?.result || null,
      }
    default:
      return state
  }
}
