import React, { memo, useState, useEffect, useCallback } from 'react'
import { Checkbox, Intent } from '@blueprintjs/core'
import { size } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'

import { Action, Toast } from 'constants/MessageForm'
import { MasterLayout } from 'components/layout'
import { HeadingPage, GridView } from 'components/common'
import requestAction from 'helpers/request'
import { showToast } from 'actions/task'
import { putQuyenChucNang } from 'actions/index'
import Headers from './Headers'

const index = memo(({ actions: { putQuyenChucNang } }) => {
  const [dataQuyenPhienBan, setDataQuyenPhienBan] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onChangeField = useCallback(
    (value, isChecked) => {
      requestAction({
        showToast: false,
        codeCheck: false,
        action: () =>
          putQuyenChucNang({
            id: value?.chucNangId,
            is_active: isChecked,
          }),
        afterResponse: res => {
          if (!res) {
            return showToast({
              message: Toast.FAIL(Action.UPDATE),
              intent: Intent.DANGER,
            })
          }

          setDataQuyenPhienBan(prev =>
            prev?.map(elm => {
              if (elm?.chucNangId === value?.chucNangId) {
                return {
                  ...elm,
                  isActive: isChecked,
                }
              }

              return elm
            })
          )
          return showToast({
            message: Toast.SUCCESS(Action.UPDATE),
            intent: Intent.SUCCESS,
          })
        },
      })
    },
    [putQuyenChucNang]
  )

  const fetchDsMaChucNang = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => setIsLoading(true),
      action: () => {},
      afterResponse: res => {
        setDataQuyenPhienBan(res?.items)
      },
      afterAction: () => setIsLoading(false),
    })
  }, [])

  const onHandleCheckAll = useCallback(
    event => {
      const checked = event?.target?.checked
      requestAction({
        showToast: false,
        codeCheck: false,
        action: () =>
          putQuyenChucNang({
            id: null,
            is_active: checked,
          }),
        afterResponse: res => {
          if (!res) {
            return showToast({
              message: Toast.FAIL(Action.UPDATE),
              intent: Intent.DANGER,
            })
          }

          if (checked) {
            setDataQuyenPhienBan(prev =>
              prev?.map(elm => ({ ...elm, isActive: true }))
            )
          } else {
            fetchDsMaChucNang()
          }

          return showToast({
            message: Toast.SUCCESS(Action.UPDATE),
            intent: Intent.SUCCESS,
          })
        },
      })
    },
    [fetchDsMaChucNang, putQuyenChucNang]
  )

  const Row = ({ number, item: data, onClickDetail }) =>
    !(number && data) ? null : (
      <tr
        className={classNames({
          disabled: data?.isDisible || false,
          'cursor-not-allowed': data?.isDisible || false,
        })}
      >
        <td>{number < 10 ? `0${number}` : number}</td>
        <td>{data?.maChucNang || ''}</td>
        <td>{data.moTa}</td>
        <td className="center">
          <Checkbox
            className="checkbox-inline"
            checked={data?.isActive || false}
            disabled={data?.isDisible}
            onChange={event => onClickDetail(data, event?.target?.checked)}
          />
        </td>
      </tr>
    )

  useEffect(() => {
    fetchDsMaChucNang()
  }, [fetchDsMaChucNang])

  return (
    <MasterLayout typeSidebar="sidebarSystem">
      <HeadingPage
        namePage="Phân quyền chức năng"
        iconPage="icon-decentralization"
      />
      <div className="table-container table-container-full pb0">
        <GridView
          classNames="pt-table table-middle"
          isLoading={isLoading}
          isEmpty={!size(dataQuyenPhienBan)}
          headers={Headers}
          data={dataQuyenPhienBan}
          page={1}
          isCheckAll={
            size(dataQuyenPhienBan) ===
            size(dataQuyenPhienBan?.filter(e => e?.isActive))
          }
          itemShape={Row}
          onClickDetail={onChangeField}
          handleCheckAll={onHandleCheckAll}
        />
      </div>
    </MasterLayout>
  )
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      putQuyenChucNang,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(index)
