import { Button } from '@blueprintjs/core'
import React from 'react'
import { withRouter } from 'react-router-dom'

const GoBackButton = props => (
  <Button
    className="pt-button btn-cancel"
    onClick={() =>
      props.to ? props.history.push(props.to) : props.history.goBack()
    }
    disabled={props.disabled}
  >
    <span className="pt-icon icon-back" />
    <span className="text-content">Quay lại</span>
  </Button>
)

export default withRouter(GoBackButton)
