import { includes } from 'lodash'
import { Cookies } from 'react-cookie'
import moment from 'moment'
const cookies = new Cookies()

export const getTypeResponsipility = type => {
  switch (type) {
    case 'MAIN_RESPONSIBILITY':
      return 'Chủ trì'
    case 'COORDINATION':
      return 'Phối hợp'
    default:
      return ''
  }
}

// Kiem tra theo ma don vi
export const checkDonVi = (dsChucDanh, tenVietTat) => {
  if (
    dsChucDanh.length !== 0 &&
    dsChucDanh.findIndex(
      i =>
        i.donVi.tenVietTat === tenVietTat &&
        i.id === cookies.get('authState').roleId
    ) !== -1
  ) {
    return true
  }
  return false
}

// Get file type
export const getExtension = fileName => {
  var parts = fileName.split('.')
  return parts[parts.length - 1]
}

// Check file type EX: checkExtension(["jpg", "jpeg", "png"], file.name)
export const checkExtension = (fileType = [], fileName) => {
  if ((fileType && fileType.length === 0) || !fileName) {
    return false
  }

  let type = getExtension(fileName)
  if (fileType.findIndex(i => i.toLowerCase() === type.toLowerCase()) !== -1) {
    return true
  }

  return false
}

// Kiểm tra dữ liệu theo điều kiện
// originValue: Danh sách dữ liệu ban đầu
// targetValue: Danh sách dữ liệu muốn kiểm tra với dữ liệu ban đầu
// operator: điều kiện kiểm tra
export const isValidCondition = (
  originValue = [],
  targetValue = [],
  operator = 'OR'
) => {
  let result = false
  if (originValue.length === 0 || targetValue.length === 0) {
    return result
  }

  let data = []
  targetValue.forEach(ele =>
    originValue.forEach(item => {
      if (item === ele) {
        data.push(ele)
      }
    })
  )
  switch (operator) {
    case 'OR':
      if (data.length !== 0) {
        result = true
      }
      break
    case 'AND':
      if (data.length !== 0 && data.length === targetValue.length) {
        result = true
      }
      break
    default:
      result = false
      break
  }

  return result
}

// TODO: xóa
export const removeVietnameseTones = str => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  /* eslint-disable no-useless-escape */
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\\=|\<|>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  return str.toUpperCase()
}

export const checkPermissionMenu = (
  originPermisson = [],
  targetPermission = [],
  operator = 'OR'
) => {
  let result = false
  if (originPermisson?.length === 0 || targetPermission?.length === 0) {
    return result
  }

  switch (operator) {
    case 'OR':
      if (originPermisson?.some(oP => includes(targetPermission, oP))) {
        result = true
      }
      break
    case 'AND':
      const newTarget = targetPermission?.filter(f =>
        includes(originPermisson, f)
      )
      if (newTarget?.length === targetPermission?.length) {
        result = true
      }
      break
    default:
      result = false
      break
  }

  return result
}

export const getTimeToText = time => {
  const now = moment()
  const isSameday = now.isSame(moment(time), 'day')
  if (isSameday) {
    const seconds = now.diff(moment(time), 'seconds')
    if (seconds < 60) {
      return `${seconds} giây trước`
    }

    if (seconds < 60 ** 2) {
      return `${~~(seconds / 60)} phút trước`
    }

    return `${~~(seconds / 60 ** 2)} giờ trước`
  }

  return moment(time).format('HH:mm')
}

export const downloadFile = (url, filename, token) => {
  fetch(url, {
    headers: {
      authorization: 'Bearer ' + token,
    },
  })
    .then(response => response.blob())
    .then(blob => {
      if (blob.size === 0) {
        return false
      }
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
    })
}
