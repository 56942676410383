import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { Button, Classes, Intent, Position } from '@blueprintjs/core'
import { get, size } from 'lodash'
import csx from 'classnames'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { Card, TaskCheckbox, Loading, Col, Row } from 'components/newCommon'
import {
  postCongViecCon,
  getDsCongViecCon,
  putCongViecCon,
  deleteCongViecCon,
} from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { WORK_STATUS } from 'constants/Enum'
import { Input, FormDatePicker } from 'components/newCommon2'
import DeleteTodo from '../Confirm/DeleteTodo'

const NEW_ID = 'NEW'

const checkedEnum = Object.freeze({
  0: WORK_STATUS.DANG_THUC_HIEN,
  1: WORK_STATUS.HOAN_THANH,
})

const dateRequest = 'YYYY-MM-DD'

const TodoForm = memo(({ onSubmit, defaultFormValue, loading, onCancel }) => {
  const [formData, setFormData] = useState(defaultFormValue || {})

  const _onSubmit = () => {
    const requestData = {
      ...formData,
      deadline: formData?.deadline
        ? formData.deadline.startOf('day').format(dateRequest)
        : undefined,
    }
    onSubmit(requestData)
  }

  const onChange = name => value => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <div className="ph10">
      <Input
        type="textarea"
        maxLength={255}
        wrapperClassName="mb10"
        placeholder="Nhập tiêu đề"
        value={formData?.content || ''}
        onChange={onChange('content')}
      />

      <Row gutterVertical>
        <Col grid="half">
          <FormDatePicker
            inputClearable
            inputIconClassName="icon2-date-frames"
            onChange={onChange('deadline')}
            placeholder="Hạn thực hiện"
            popoverStretch={false}
            popoverPosition={Position.TOP_LEFT}
            selected={formData?.deadline || null}
          />
        </Col>
        <Col grid="half" className="element-flex-end">
          <span>
            <Button
              className={csx(
                'cpc-button',
                'default',
                'no-border',
                'text-uppercase',
                'font-size-13',
                'ph10',
                'mr10'
              )}
              disabled={loading}
              onClick={onCancel}
            >
              Hủy
            </Button>
            <Button
              className={csx(
                'cpc-button',
                'text-uppercase',
                'font-size-13',
                'ph10',
                'mr10'
              )}
              disabled={!formData?.content?.trim()}
              intent={Intent.PRIMARY}
              loading={loading}
              onClick={_onSubmit}
            >
              Lưu
            </Button>
          </span>
        </Col>
      </Row>
    </div>
  )
})

const ToDoList = ({ match, allowAction }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const taskId = useMemo(() => get(match, 'params.id'), [match])

  const [fetching, setFetching] = useState(true)
  const [todoList, setTodoList] = useState([])
  const [editId, setEditId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [waitingChecked, setWaitingChecked] = useState([])
  const [deleteId, setDeleteId] = useState()

  const toggleEdit = useCallback(
    (id = null) =>
      () => {
        if (!loading) _mountedSet(setEditId, id)
      },
    [loading]
  )

  const editProps = useMemo(
    () => ({
      onCancel: toggleEdit(),
      loading,
    }),
    [toggleEdit, loading]
  )

  const _fetchTodoList = useCallback(() => {
    if (!taskId) {
      return
    }
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getDsCongViecCon(taskId),
      afterResponse: ({ items = [] }) => _mountedSet(setTodoList, items),
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [taskId])

  const createToDo = formValues => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setLoading, true)
      },
      action: () =>
        postCongViecCon({
          work_item_id: taskId,
          ...formValues,
        }),
      afterResponse: result => {
        _mountedSet(setTodoList, [...todoList, result])
        _mountedSet(setEditId, null)
      },
      afterAction: () => _mountedSet(setLoading, false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const updateToDo =
    (currentTodo, type = 'form') =>
    value => {
      const isForm = type === 'form'
      requestAction({
        beforeAction: () => {
          if (isForm) {
            _mountedSet(setLoading, true)
          } else {
            _mountedSet(setWaitingChecked, [...waitingChecked, currentTodo.id])
          }
        },
        action: () =>
          putCongViecCon(
            toDecamelizeKeys({
              ...currentTodo,
              ...(isForm
                ? value
                : {
                    [type]: value,
                  }),
            })
          ),
        afterResponse: () => {
          _mountedSet(
            setTodoList,
            todoList.map(todo =>
              todo.id === currentTodo.id
                ? {
                    ...todo,
                    ...(isForm
                      ? value
                      : {
                          [type]: value,
                        }),
                  }
                : todo
            )
          )
          if (isForm) {
            _mountedSet(setEditId, null)
          } else {
            _mountedSet(
              setWaitingChecked,
              waitingChecked.filter(id => id !== currentTodo.id)
            )
          }
        },
        afterAction: () => {
          _mountedSet(setLoading, false)
        },
        successMessage: Toast.SUCCESS(Action.UPDATE),
        errorMessage: Toast.FAIL(Action.UPDATE),
        warningMessage: Toast.INCOMPLETE,
      })
    }

  const deleteToDo = () => {
    requestAction({
      action: () => deleteCongViecCon(deleteId),
      afterResponse: () =>
        _mountedSet(
          setTodoList,
          todoList.filter(todo => todo.id !== deleteId)
        ),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchTodoList()
  }, [_fetchTodoList])

  if (!allowAction && !size(todoList)) {
    return null
  }

  return (
    <div className="cpc-detail-block">
      <label className={csx('font-size-13', 'font-weight-600', 'uppercase')}>
        Bước thực hiện
      </label>

      <div className="cpc-list">
        {fetching ? (
          <Loading />
        ) : (
          todoList.map(todo =>
            editId === todo.id ? (
              <TodoForm
                {...editProps}
                key="todo-edit"
                onSubmit={updateToDo(todo, 'form')}
                defaultFormValue={{
                  content: todo.content,
                  deadline: todo.deadline ? moment(todo.deadline) : null,
                }}
              />
            ) : (
              <Card
                className={csx('cpc-list-item', 'ph10', 'border-bottom')}
                key={todo.id}
              >
                <TaskCheckbox
                  className="mb0"
                  checked={get(todo, 'status') === WORK_STATUS.HOAN_THANH}
                  onChange={checked =>
                    updateToDo(todo, 'status')(checkedEnum[+checked])
                  }
                  disabled={waitingChecked.includes(todo.id) || !allowAction}
                  style={{ alignItem: 'flex-start' }}
                >
                  <span className="d-inline-block">
                    <p className="d-block font-size-13 m0">
                      {todo?.content && (
                        <span
                          className="word-break-word"
                          dangerouslySetInnerHTML={{
                            __html: todo?.content?.replace(/\n/g, '<br>'),
                          }}
                        />
                      )}
                    </p>
                    {todo.deadline && (
                      <i className="font-size-11" style={{ color: '#999' }}>
                        {moment(todo.deadline).format('DD/MM/YYYY')}
                      </i>
                    )}
                  </span>
                  {allowAction && (
                    <span
                      className="action"
                      style={{ minWidth: '35px' }}
                      onClick={e => e.preventDefault()}
                    >
                      <i
                        className="icon-but size-icon-12 mr10 icon-color-primary"
                        onClick={toggleEdit(todo.id)}
                      />
                      <i
                        className="icon-bin size-icon-12 icon-color-danger"
                        onClick={() => _mountedSet(setDeleteId, todo.id)}
                      />
                    </span>
                  )}
                </TaskCheckbox>
              </Card>
            )
          )
        )}

        {editId === NEW_ID && <TodoForm {...editProps} onSubmit={createToDo} />}
      </div>

      {allowAction && (
        <Button
          className={csx(Classes.MINIMAL, 'cpc-button', 'no-hover', 'pv0')}
          intent={Intent.PRIMARY}
          iconName="plus"
          text="Thêm"
          onClick={toggleEdit(NEW_ID)}
        />
      )}

      <DeleteTodo
        isOpen={!!deleteId}
        onClose={() => _mountedSet(setDeleteId)}
        onConfirm={() => deleteToDo()}
      />
    </div>
  )
}

export default memo(withRouter(ToDoList))
