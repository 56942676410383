import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  API_REQUEST_ERROR,
  SET_DO_PHONGBAN_NOIBO,
  GET_DOCS_LIST_DO,
  GET_PHONGBAN_LIST_DO,
  GET_DONVI_LIST_DO,
  GET_SOVB_DO,
  GET_PHONGBANXULY_DO,
  GET_LANHDAOCHIDAO_DO,
  GET_CHUYENVIENXULY_DO,
  GET_DANHSACHCONGVAN_DO,
  GET_CHITIETVANBANSOHOA_DO,
  GET_CHITIETVANBANSOHOA_DO_RESET,
  GET_LISTVANBAN_DO,
  GET_LIST_DONVI_VANBAN_DO,
  RESET_DO,
  GET_LIST_QUAN_HE_DONVI_VANBAN_DO,
  GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET,
} from '../constants/ActionTypes'
import { API_URL, QLCV_API_URL, DO_API } from '../constants/Api'
import { store } from '../index'

const taskRequest = ({
  type: reduxSuccessType = API_REQUEST_SUCCESS,
  tokenRequest,
  isNotToken = false,
  baseURL,
  client,
  endpoint,
  method = 'GET',
  params = {},
  data,
  apiVersion,
  ...otherOptions
}) => {
  const { dispatch, getState } = store
  const token = getState()?.auth?.token || null
  const headers = {
    ...(tokenRequest
      ? {
          Authorization: `${tokenRequest.tokenType} ${tokenRequest.accessToken}`,
        }
      : !isNotToken && token
      ? {
          Authorization: `${token.tokenType} ${token.accessToken}`,
        }
      : {}),
    ...(apiVersion ? { 'api-version': apiVersion } : {}),
  }

  return dispatch({
    types: [API_REQUEST_SEND, reduxSuccessType, API_REQUEST_ERROR],
    payload: {
      client,
      request: {
        ...otherOptions,
        baseURL,
        url: endpoint,
        method,
        headers,
        params,
        data,
      },
    },
  })
}

export const getDOPhongBanNoiBo = params => {
  return taskRequest({
    type: SET_DO_PHONGBAN_NOIBO,
    baseURL: API_URL,
    endpoint: '/api/doffice/danhmuc/phongbannoibo',
    params,
  })
}

export const getDOCongVan = id => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: '/api/doffice/vanban/vbde/detail',
    params: {
      idVB: id,
    },
  })
}

export const getDOPreviewFileCongVan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    tokenRequest: {
      tokenType: token?.tokenType,
      accessToken: token?.accessToken,
    },
    client: 'defaultV2',
    endpoint: '/v1/files/FileVb/GetPreviewFileVB',
    params: {
      ID_FILE: params?.id,
      ID_DV: params?.idDv,
      ID_NV: params?.idNv,
      ID_LOAI_VB: params?.idLoaiVb,
    },
  })
}

export const getDODowloadFileCongVan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    tokenRequest: {
      tokenType: token?.tokenType,
      accessToken: token?.accessToken,
    },
    client: 'download',
    endpoint: '/v2/files/FileVb/DownloadFileVB',
    params: {
      ID_FILE: params?.id,
      ID_DV: params?.idDv,
      ID_NV: params?.idNv,
      ID_LOAI_VB: params?.idLoaiVb,
    },
  })
}

export const getDOCongViec = id => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: `/api/works-doffice/${id}`,
  })
}

export const getDODonViIdByUsername = username => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    isNotToken: true,
    endpoint: '/api/eoffice/donviid-by-username',
    params: {
      username,
    },
  })
}

export const getPhongBanThucHienNotToken = donViId => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works-doffice/department',
    params: {
      donViId,
    },
  })
}

export const getDonViTrucThuocNotToken = donViId => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works-doffice/organization',
    params: {
      donViId,
    },
  })
}

export const putDOGiaoViec = (workItemId, username, data) => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    method: 'PUT',
    endpoint: `/api/works-doffice-assign/${workItemId}`,
    params: {
      username,
    },
    data,
  })
}

export const postDOFiles = (files, idDonVi, token) => {
  let formData = new FormData()
  formData.append('ID_DV', idDonVi)
  if (files?.fileVanBan) {
    files.fileVanBan.forEach(element => {
      formData.append('FileVanBan', element)
    })
  }

  if (files?.filePhuLuc) {
    files.filePhuLuc.forEach(element => {
      formData.append('FilePhuLuc', element)
    })
  }

  return taskRequest({
    baseURL: DO_API,
    client: 'defaultFormData',
    method: 'POST',
    tokenRequest: token,
    endpoint: '/v2/files/FileVb/UploadFile',
    data: formData,
  })
}

export const getDOWorkKey = params => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    isNotToken: true,
    endpoint: '/api/works-doffice-key',
    params,
  })
}

export const postDOWork = data => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    method: 'POST',
    isNotToken: true,
    endpoint: '/api/works-doffice',
    data,
  })
}

export const getLoaiVanBan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_DOCS_LIST_DO,
    endpoint: '/v1/congviec/DanhMuc/GetLoaiVB_DonVi',
    tokenRequest: token,
    params,
  })
}

export const getPhongBanHanh = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_PHONGBAN_LIST_DO,
    endpoint: '/v1/congviec/Searching/GetPBNhanVBDI',
    tokenRequest: token,
    params,
  })
}

export const getDonViNhan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_DONVI_LIST_DO,
    endpoint: '/v1/congviec/Searching/GetDVNhanVBDE',
    tokenRequest: token,
    params,
  })
}

export const getSoVanBan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_SOVB_DO,
    endpoint: '/v1/congviec/DanhMuc/GetSoVB_DonVi',
    tokenRequest: token,
    params,
  })
}

export const getPhongBanXuLy = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_PHONGBANXULY_DO,
    endpoint: '/v1/congviec/Searching/GetPBNhanVBDI',
    tokenRequest: token,
    params,
  })
}

export const getLanhDaoChiDao = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_LANHDAOCHIDAO_DO,
    endpoint: '/v1/quantri/DanhMuc/GetDM_PHONGBAN_LANHDAO',
    tokenRequest: token,
    params,
  })
}

export const getChuyenVienXuLy = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_CHUYENVIENXULY_DO,
    endpoint: '/v1/congviec/DanhMuc/GetListNvByID_DV',
    tokenRequest: token,
    params,
  })
}

// POST danh sách tìm kiếm văn bản DO
export const getDanhSachSoHoa = ({ data, params }, token) => {
  return taskRequest({
    baseURL: DO_API,
    method: 'POST',
    endpoint: '/v1/congviec/Searching/advancedsearch',
    type: GET_DANHSACHCONGVAN_DO,
    tokenRequest: token,
    params,
    data,
  })
}

// GET chi tiết văn bản số hóa
export const getChiTietVanBanSoHoa = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_CHITIETVANBANSOHOA_DO,
    endpoint: '/v1/congviec/Searching/getdetailVbsearch',
    tokenRequest: token,
    params,
  })
}

export const getChiTietVanBanSoHoaReset = () => {
  const { dispatch } = store
  return dispatch({
    type: GET_CHITIETVANBANSOHOA_DO_RESET,
  })
}

// GET danh sách văn bản
export const getListVanBan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_LISTVANBAN_DO,
    endpoint: '/v1/vanban/VBDE/ListFileVB',
    tokenRequest: token,
    params,
  })
}

export const getTenSoLoaiVanBan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    endpoint: '/v1/vanban/DanhMuc/GetTenSoLoaiVanBan',
    tokenRequest: token,
    params,
  })
}

export const getLoaiVanBanDO = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    endpoint: '/v1/vanban/DanhMuc/GetLOAIVBByIDVB',
    tokenRequest: token,
    params,
  })
}

export const getDuThaoId = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    endpoint: '/v1/duthao/VBDT/GetDuThaoByVanBan',
    tokenRequest: token,
    params,
  })
}

export const getQuaTrinhXuLy = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    endpoint: '/v1/duthao/VBDT/GetQuaTrinhXuLy',
    tokenRequest: token,
    params,
  })
}

export const getCongViecXuLy = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    endpoint: '/v1/congviec/CongViec/Get_QTCVXuly',
    tokenRequest: token,
    params,
  })
}

export const getDonViVanBan = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    type: GET_LIST_DONVI_VANBAN_DO,
    tokenRequest: token,
    endpoint: '/v1/vanban/VBDI/getVBDIById',
    params,
  })
}

export const getLichSuPhanPhoi = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    tokenRequest: token,
    endpoint: '/v1/congviec/CongViec/Get_LichsuPhanphoi',
    params,
  })
}

export const getLichSuChiDao = (params, token) => {
  return taskRequest({
    baseURL: DO_API,
    tokenRequest: token,
    endpoint: '/v1/congviec/CongViec/Get_QTChiDao',
    params,
  })
}

export const getPhongBanDetail = ({ id }) => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: `/v1/congviec/PhongBanDetail/${id}`,
  })
}

export const getQuanHeDonViVanBan = ({ id }) => {
  return taskRequest({
    baseURL: API_URL,
    type: GET_LIST_QUAN_HE_DONVI_VANBAN_DO,
    endpoint: `/api/doffice/vanban/quanhevanbandonvi?idVanban=${id}`,
  })
}

export const getQuanHeDonViVanBanReset = () => {
  const { dispatch } = store
  return dispatch({
    type: GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET,
  })
}

export const resetDO = () => {
  const { dispatch } = store
  return dispatch({
    type: RESET_DO,
  })
}

export const getDonViTrongNganh = (token, params) => {
  return taskRequest({
    baseURL: DO_API,
    tokenRequest: token,
    endpoint: '/v1/duthao/VBDT/ListDonViTrongNganh',
    params,
  })
}

export const postUploadZipFromDO = data => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works/doffice/upload-file-zip',
    method: 'POST',
    data,
  })
}

export const postUploadFromDO = params => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works/doffice/upload-files',
    method: 'POST',
    params,
  })
}
