// const getTypeFromName = (name) => {
//   return name.split('.').pop();
// };

export const convertFileToUpload = file => ({
  uid: file?.uid,
  name: file?.name,
  mime: file?.type,
  size: file?.size,
})

export default {
  convertFileToUpload,
}
