import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import ReactDatePicker from 'react-daterange-picker'
import { get } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { Classes, Radio } from '@blueprintjs/core'

import DropdownWrapper from './DropdownWrapper'

const DateAdvanced = ({
  dataSource = [],
  onChange: selectChange = () => {},
  selected: selectValue,
  minimumDate = null,
  maximumDate = null,
  ...props
}) => {
  const typeKey = {
    DATE: 'DATE',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR',
  }
  const dateFormat = 'DD/MM/YYYY'
  const dataRadio = [
    {
      label: 'Ngày',
      keyName: 'DATE',
    },
    {
      label: 'Quý',
      keyName: 'QUARTER',
    },
    {
      label: 'Năm',
      keyName: 'YEAR',
    },
  ]
  const dataQuarter = [
    {
      label: 'Quý 1',
      keyName: 'QUY_1',
    },
    {
      label: 'Quý 2',
      keyName: 'QUY_2',
    },
    {
      label: 'Quý 3',
      keyName: 'QUY_3',
    },
    {
      label: 'Quý 4',
      keyName: 'QUY_4',
    },
  ]
  const currentYear = moment().year()
  const limitYear = currentYear + 7

  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)
  const [dataLimit, setDataLimit] = useState(null)
  const [selected, setSelected] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [yearActive, setYearActive] = useState(2022)
  const [selectYear, setSelectYear] = useState([])
  const [activeTab, setActiveTab] = useState(typeKey.DATE)
  const [activeChange, setActiveChange] = useState(null)

  const toggleDropdown = useCallback(open => {
    mountedSet(setDropdownOpen, open)
  }, [])

  const onChange = useCallback(
    (value, type) => {
      const { DATE, QUARTER, YEAR } = typeKey
      switch (type) {
        case DATE:
          const year = moment(value).format('YYYY')
          setYearActive(Number(year))
          setSelected(value)
          selectChange(value)
          break
        case QUARTER:
          setActiveChange(value)
          let month = 12
          if (value === 'QUY_1') {
            month = 3
          } else if (value === 'QUY_2') {
            month = 6
          } else if (value === 'QUY_3') {
            month = 9
          }

          const theLastDayOfAMonthOfTheYear = moment(
            `${month}/01/${yearActive} 23:59:59`
          ).endOf('month')
          setSelected(moment(theLastDayOfAMonthOfTheYear))
          selectChange(moment(theLastDayOfAMonthOfTheYear))
          break
        case YEAR:
          setYearActive(Number(value))
          setActiveChange(value)
          const lastDayOfTheRear = moment(`01/01/${value} 23:59:59`).endOf(
            'year'
          )
          setSelected(moment(lastDayOfTheRear))
          selectChange(moment(lastDayOfTheRear))
          break
        default:
          break
      }
      setDropdownOpen(false)
    },
    [selectChange, setActiveChange, setYearActive, yearActive, typeKey]
  )

  const onChangeRadio = useCallback(values => {
    setActiveTab(values)
  }, [])

  const onChangeYear = useCallback(values => {
    mountedSet(setYearActive, Number(values?.target?.value || 2022))
  }, [])

  const onClear = () => {
    setSelected(null)
    selectChange(null)
  }

  const renderTabs = useCallback(
    values => {
      const minimumMonth = dataLimit?.minimumMonth
      const minimumYear = dataLimit?.minimumYear
      const maximumMonth = dataLimit?.maximumMonth
      const maximumYear = dataLimit?.maximumYear
      const { DATE, QUARTER, YEAR } = typeKey
      const handleQuarterDisable = value => {
        let quy = 0
        switch (value) {
          case 'QUY_1':
            quy = 3
            break
          case 'QUY_2':
            quy = 6
            break
          case 'QUY_3':
            quy = 9
            break
          case 'QUY_4':
            quy = 12
            break
          default:
            break
        }

        if (
          (minimumYear && minimumYear > yearActive) ||
          (maximumYear && maximumYear < yearActive) ||
          (minimumYear && minimumYear === yearActive && minimumMonth > quy) ||
          (maximumYear && maximumYear === yearActive && maximumMonth < quy)
        ) {
          return true
        }

        return false
      }

      const handleYearDisable = value => {
        if (
          (minimumYear && minimumYear >= value) ||
          (maximumYear && maximumYear <= value)
        ) {
          return true
        }

        return false
      }

      switch (values) {
        case DATE:
          return (
            <div>
              <ReactDatePicker
                className={classNames('cpc-datepicker-range')}
                // initialDate={selected}
                value={selected || moment()}
                onSelect={e => onChange(e, DATE)}
                selectionType="single"
                minimumDate={minimumDate}
                maximumDate={maximumDate}
              />
            </div>
          )
        case QUARTER:
          return (
            <div className="pl10 pt10 pr10">
              <ul className="cpc-datepicker-advanced__body__list">
                {dataQuarter.map((elm, index) => (
                  <li key={index}>
                    <button
                      className={classNames('item', {
                        active: activeChange === elm.keyName,
                      })}
                      disabled={handleQuarterDisable(elm.keyName)}
                      onClick={() => onChange(elm.keyName, QUARTER)}
                    >
                      {elm.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        case YEAR:
          return (
            <div className="pl10 pt10 pr10">
              <ul className="cpc-datepicker-advanced__body__list">
                {selectYear?.map((elm, index) => (
                  <li key={index}>
                    <button
                      className={classNames('item', {
                        active: activeChange === elm,
                      })}
                      disabled={handleYearDisable(elm)}
                      onClick={() => onChange(elm, YEAR)}
                    >
                      {elm}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        default:
          break
      }
    },
    [
      selectYear,
      dataQuarter,
      dataLimit,
      yearActive,
      activeChange,
      maximumDate,
      minimumDate,
      selected,
      typeKey,
      onChange,
    ]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (currentYear && limitYear) {
      let yearList = []
      for (let index = currentYear; index <= limitYear; index++) {
        yearList = [...yearList, index]
      }

      setSelectYear(yearList)
    }
  }, [currentYear, limitYear])

  useEffect(() => {
    if (moment(selectValue).isValid()) {
      const year = moment(selectValue).format('YYYY')
      mountedSet(setYearActive, Number(year))
    }

    mountedSet(setSelected, selectValue)
  }, [selectValue])

  useEffect(() => {
    let limitValues = null
    if (moment(minimumDate).isValid()) {
      const month = moment(minimumDate).format('MM')
      const year = moment(minimumDate).format('YYYY')
      limitValues = {
        ...limitValues,
        minimumMonth: Number(month),
        minimumYear: Number(year),
      }
      mountedSet(setYearActive, Number(year))
    }

    if (moment(maximumDate).isValid()) {
      const month = moment(maximumDate).format('MM')
      const year = moment(maximumDate).format('YYYY')
      limitValues = {
        ...limitValues,
        maximumMonth: Number(month),
        maximumYear: Number(year),
      }
    }
    mountedSet(setDataLimit, limitValues)
  }, [minimumDate, maximumDate])

  return (
    <DropdownWrapper
      {...props}
      onClear={() => onClear()}
      value={selected ? moment(selected).format(dateFormat) : ''}
      open={dropdownOpen}
      onDropdownVisibleChange={toggleDropdown}
    >
      <div className="cpc-datepicker-advanced">
        <div className="cpc-datepicker-advanced__head">
          <ul className="d-flex cpc-datepicker-advanced__head__list">
            {dataRadio.map((elm, index) => (
              <li key={index}>
                <Radio
                  label={elm.label}
                  className={classNames(Classes.SMALL, 'm0')}
                  checked={activeTab === elm.keyName || false}
                  disabled={false}
                  onChange={() => onChangeRadio(elm.keyName)}
                />
              </li>
            ))}
            <li>
              <select
                className="cpc-select-basic"
                placeholder="Chọn năm"
                disabled={
                  activeTab === typeKey.YEAR || activeTab === typeKey.DATE
                }
                value={yearActive}
                onChange={onChangeYear}
              >
                {selectYear?.map((elm, index) => (
                  <option
                    key={index}
                    value={elm}
                    disabled={elm < dataLimit?.minimumYear}
                  >
                    {elm}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </div>
        <div className="cpc-datepicker-advanced__body">
          {renderTabs(activeTab)}
        </div>
      </div>
    </DropdownWrapper>
  )
}

export default memo(DateAdvanced)
