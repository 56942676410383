import React, { useContext } from 'react'
import { Button, Tooltip } from '@blueprintjs/core'
import classNames from 'classnames'
import moment from 'moment'
import { get } from 'lodash'

import { ResetButton, Icon } from 'components/newCommon'
import { DateRangePicker, Select, SelectTree } from 'components/newCommon2'
import { ThongKeKetLuanCuocHopContext } from '../ThongKeKetLuanCuocHop'

const filterKeys = {
  from: 'fromDate',
  to: 'toDate',
  status: 'status',
  isHide: 'isHide',
  phongBanDonVi: 'phongBanDonVi',
}

const statusList = [
  {
    value: 'NEW',
    label: 'Chưa hoàn thành',
  },
  {
    value: 'DONE',
    label: 'Hoàn thành',
  },
  {
    value: 'DONE_SLOW',
    label: 'Hoàn thành chậm',
  },
]

const MeetingStatisticFilter = () => {
  const {
    treeData,
    filter: {
      [filterKeys.from]: from = null,
      [filterKeys.to]: to = null,
      [filterKeys.status]: status = 'ALL',
      [filterKeys.phongBanDonVi]: phongBanDonVi = {
        label: 'Phòng ban/Đơn vị có công việc',
        value: 'isHide',
      },
    } = {},
    changeFilter = () => {},
  } = useContext(ThongKeKetLuanCuocHopContext)

  return (
    <div className="layout-filter layout-filter2">
      <div style={{ flex: '0 0 auto', width: 'calc(100% - 84px)' }}>
        <div className="d-flex wrap">
          <div className="layout-filter2__item">
            <DateRangePicker
              placeholder="Ngày tạo (Từ ngày - Đến ngày)"
              clearable={false}
              value={from && to ? moment.range(moment(from), moment(to)) : null}
              onChange={range => {
                let value = {}
                if (range) {
                  value = {
                    [filterKeys.from]: moment(get(range, 'start')),
                    [filterKeys.to]: moment(get(range, 'end')),
                  }
                } else {
                  value = {
                    [filterKeys.from]: null,
                    [filterKeys.to]: null,
                  }
                }
                changeFilter({ multipleKey: true, reFetch: true })(value)
              }}
            />
          </div>
          <div className="layout-filter2__item">
            <Select
              inputWrapperClassName={'border-radius-3 input-not-ph'}
              dataSource={[
                { label: 'Tất cả trạng thái', value: 'ALL' },
                ...statusList,
              ]}
              inputIconClassName="icon2-documents"
              placeholder="Tất cả cả trạng thái"
              // inputClearable
              // onClear={onClear(filterKeys.donVi)}
              wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
              inputStyle={{ height: '35px', lineHeight: '35px' }}
              isShowArrowDropdown
              value={status}
              onChange={changeFilter({
                name: filterKeys.status,
                reFetch: true,
              })}
            />
          </div>
          <div className="layout-filter2__item">
            <SelectTree
              inputWrapperClassName={'border-radius-3 input-not-ph'}
              dataSource={[
                { label: 'Phòng ban/Đơn vị có công việc', value: 'isHide' },
                { label: 'Hiện tất cả Phòng ban/Đơn vị', value: 'ALL' },
                ...treeData,
              ]}
              inputIconClassName="icon2-documents"
              placeholder="Phòng ban/ Đơn vị"
              wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
              inputStyle={{ height: '35px', lineHeight: '35px' }}
              isShowArrowDropdown
              radioName={'phongBanDonVi'}
              keySource={['label', 'value']}
              value={phongBanDonVi}
              onChange={option => {
                const value = {
                  [filterKeys.isHide]: option.value === 'isHide',
                  [filterKeys.phongBanDonVi]: option,
                }
                changeFilter({ multipleKey: true, reFetch: true })(value)
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          flex: '0 0 auto',
          width: '84px',
        }}
      >
        <div className="cpc-input-v2-filter-button justify-content-end">
          <Button
            className={classNames(
              'cpc-button-filter-clear',
              'cpc-button green',
              'color-white'
            )}
            onClick={changeFilter({ name: 'SUBMIT', reFetch: true })}
          >
            <Icon classIcon={'icon2-search'} />
          </Button>
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton
              className="ml10"
              onClick={changeFilter({ name: 'RESET' })}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default MeetingStatisticFilter
