import React, {
  Fragment,
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import toPairs from 'lodash/toPairs'
import isString from 'lodash/isString'
import classNames from 'classnames'
import moment from 'moment'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'

import { RESPONSIBILITY_TYPE, UNSET, FILE_TYPE } from 'constants/Enum'
import { Action, Toast } from 'constants/MessageForm'
import { priorities } from 'constants/task'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import requestAction from 'helpers/request'
import { randomId } from 'helpers/string'
import {
  Row,
  Col,
  Scrollbar,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'
import {
  postUpload,
  postWorksMeeting,
  getCongViec,
  patchWorksMeeting,
} from 'actions/task'
import { MeetingDetailContext } from '../MeetingDetail'
import PhongBanVaDonViThucHien from './_components/PhongBanVaDonViThucHien'
const CONFIRM_TITLE = 'Xóa kết luận'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa kết luận này?'

const WorkMeetingUpdate = ({
  onClose = () => {},
  reloadList = () => {},
  match,
}) => {
  const {
    tags = [],
    hasShowAllDeparment,
    mainDepartmentId,
    dataTreeOrganizationDepartmentStore,
  } = useContext(MeetingDetailContext)

  const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const dateRequest = 'YYYY-MM-DD'
  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { id: meetingId, action: currentAction, actionId } = match.params
  const isUpdate = currentAction === 'chinh-sua-cong-viec'
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [validateErrors, setValidateErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [files, setFiles] = useState({})
  const [dataAutoToggle] = useState([-1])
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)

  const toggleCollapseExtra = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const _upload = async filesData => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(filesData).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const onChangeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [name]: value,
      },
    }))
  }

  const validateRequired = name => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      if (isEmpty(data[name]) || (isString(data[name]) && !data[name].trim())) {
        success = false
        onChangeFormValidate({ id, name, value: true })
      } else {
        success = true
        onChangeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const onValidate = () => {
    const isCheckTaskTitle = validateRequired('title')
    const isCheckTaskChuTri = validateRequired(CHU_TRI)
    const isCheckTask = !isCheckTaskTitle || !isCheckTaskChuTri
    if (isCheckTask) {
      throw new Error('warning')
    }
  }

  const _convertDataType = (values, type) => ({
    responsibility_id: get(values, 'id'),
    assign_type: get(values, 'type'),
    responsibility_type: type,
  })

  const convertType = type => {
    if (type === 'DEPARTMENT') {
      return 'PB'
    } else if (type === 'ORGANIZATION') {
      return 'DV'
    }

    return 'PERSONAL'
  }

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        require_report: originData?.requireReport || false,
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        work_user_tags: get(originData, 'tags', []).map(item => ({
          work_tag_id: item,
        })),
        attachment_file_ids: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        reference_file_ids: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        is_save_template: get(originData, 'isSaveTemplate'),
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.execution_date = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.work_reminder = {
          type: workReminder.type,
          reminder_time: workReminder.date.format(dateTimeRequest),
        }
      }

      let executor = []
      if (get(originData, CHU_TRI)) {
        executor = [
          {
            responsibility_id: get(originData, `${CHU_TRI}.id`),
            assign_type: get(originData, `${CHU_TRI}.type`),
            responsibility_type: RESPONSIBILITY_TYPE.CHU_TRI,
          },
        ]
        newData.presiding_id = {
          [convertType(get(originData, `${CHU_TRI}.type`))]: get(
            originData,
            `${CHU_TRI}.id`
          ),
        }
      }

      let coordination = []
      if (get(originData, PHOI_HOP)) {
        coordination = get(originData, PHOI_HOP, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
        )
        newData.coordination_id = get(originData, PHOI_HOP, [])?.map(elm => ({
          [convertType(elm?.type)]: elm?.id,
        }))
      }

      let watcher = []
      if (get(originData, THEO_DOI)) {
        watcher = get(originData, THEO_DOI, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
        )
      }

      newData.work_assignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  const _changeFormValue = ({ id, name, value }) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload(files)
    return (cloneTasks || []).map(task => ({
      ...(task?.data || {}),
      work_type: 'MEETING',
      attachment_file_ids: [
        ...(task?.data?.attachment_file_ids || []),
        ...(responseFilesObject[task.id] || []).map(file => file.id),
      ],
    }))
  }

  const submitCreate = async () => {
    await requestAction({
      successCode: 201,
      logError: true,
      beforeAction: () => {
        _mountedSet(setIsLoading, true)
        onValidate()
      },
      action: async () => {
        const dataCongViec = await _getDataWithUpload()
        const fullData = dataCongViec?.map(e => ({
          ...e,
          parent_id: meetingId,
        }))
        return postWorksMeeting(fullData)
      },
      afterResponse: () => {
        reloadList({ page: 1 })
        onClose()
      },
      afterAction: () => {
        _mountedSet(setIsLoading, false)
        _mountedSet(setIsDisableButton, true)
      },
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setIsLoading, true)
        onValidate()
      },
      action: async () => {
        const dataCongViec = await _getDataWithUpload()
        const fullData = {
          ...dataCongViec?.[0],
          parent_id: meetingId,
        }
        return patchWorksMeeting(actionId, fullData)
      },
      afterResponse: () => {
        reloadList({ page: 1 })
        onClose()
      },
      afterAction: () => {
        _mountedSet(setIsLoading, false)
      },
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const changeField = (id, name) => value => {
    _mountedSet(setIsDisableButton, false)
    onChangeFormValidate({ id, name, value: false })
    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const changeFile = name => fileList => {
    _mountedSet(setIsDisableButton, false)
    _mountedSet(setFiles, {
      ...files,
      [name]: fileList,
    })
  }

  const toggleCollapseTask = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
            }
          : task
      )
    )
  }

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    _mountedSet(
      setTasks,
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )

    _mountedSet(setFiles, prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      _mountedSet(setTasks, prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      _mountedSet(setFiles, {
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const onChangeRadio = useCallback(
    (name, values, taskId) => {
      _mountedSet(setIsDisableButton, false)
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: name === CHU_TRI ? values : null,
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? elm?.data?.[PHOI_HOP]
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id),
                [THEO_DOI]:
                  name === THEO_DOI
                    ? elm?.data?.[THEO_DOI]
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id),
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTreeOrganizationDepartmentStore?.find(e => e?.id === record?.id)
          ?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          record?.type === 'ALL' &&
          size(parentItem) !== 0 &&
          size(parentItem) === size(taskItemDataKey)
        ) {
          isChecked = true
        } else {
          const dataKey = taskItem?.data?.[key]?.findIndex(
            e => e?.id === record?.id
          )
          if (dataKey === 0 || (dataKey && dataKey !== -1)) {
            isChecked = true
          }
        }
      }

      return isChecked
    },
    [dataTreeOrganizationDepartmentStore]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (dataKey && record?.type === 'ALL' && hasChecked) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      _mountedSet(setIsDisableButton, false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      let treeItem = []
      const isAll = values?.type === 'ALL'
      if (isAll) {
        treeItem =
          dataTreeOrganizationDepartmentStore?.find(e => e?.id === values?.id)
            ?.children || []
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData =
              elm?.data?.[name]?.filter(e => e?.id !== values?.id) || []
            newData = [
              ...oldData,
              ...(isChecked && values?.type !== 'ALL' ? [values] : []),
            ]
            if (isAll) {
              if (isChecked) {
                newData = [...newData, ...treeItem]?.filter(
                  e => e.id !== values?.parentId
                )
                const dataChuTri = elm?.data?.[CHU_TRI]
                if (dataChuTri?.id) {
                  newData = newData?.filter(e => e.id !== dataChuTri?.id)
                }
              } else {
                newData = differenceBy(newData || [], treeItem || [], 'id')
              }
            } else {
              newData = newData?.filter(e => e.id !== values?.parentId)
            }

            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]:
                  name !== CHU_TRI && elm?.data?.[CHU_TRI]?.id === values?.id
                    ? null
                    : elm?.data?.[CHU_TRI],
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id) ||
                      [],
                [THEO_DOI]:
                  name === THEO_DOI
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id) ||
                      [],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks, dataTreeOrganizationDepartmentStore]
  )

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm => elm.id === taskId && elm?.data?.[CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) ||
          record?.type === 'ALL'
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(CHU_TRI, record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isChecked = handleCheckActive(PHOI_HOP, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  const _convertToStateData = useCallback(data => {
    const preside = get(data, 'responsibilities[0].workAssignments', [])
    const coordinations = get(data, 'responsibilities[1].workAssignments', [])
    const originCoordinations = coordinations?.map(coordination => ({
      id: get(coordination, 'responsibilityId'),
      type: get(coordination, 'assignType'),
      parentId: coordination?.responsibility?.type === 'ORGANIZATION' ? -2 : -1,
    }))
    const watchers = get(data, 'responsibilities[2].workAssignments', [])
    const originWatchers = watchers?.map(watcher => ({
      id: get(watcher, 'responsibilityId'),
      type: get(watcher, 'assignType'),
      parentId: watcher?.responsibility?.type === 'ORGANIZATION' ? -2 : -1,
    }))

    const referenceFiles = (data?.files || []).filter(
      file => file?.type === FILE_TYPE.FILE_THAM_KHAO
    )
    const attachmentFiles = (data?.files || [])
      .filter(file => file?.type === FILE_TYPE.FILE_DINH_KEM)
      .map(file => ({ ...file, showClose: file.application !== 'eOffice' }))
    return {
      title: get(data, 'title') || '',
      requireReport: get(data, 'requireReport') || false,
      deadline:
        get(data, 'deadline') && moment(get(data, 'deadline')).isValid()
          ? moment(get(data, 'deadline'))
          : moment(get(data, 'originalDeadline')).isValid()
          ? moment(get(data, 'originalDeadline'))
          : null,
      executionDate:
        get(data, 'executionDate') &&
        moment(get(data, 'executionDate')).isValid()
          ? moment(get(data, 'executionDate'))
          : null,
      description: get(data, 'description') || '',
      workReminder: get(data, 'workReminder')
        ? {
            type: get(data, 'workReminder.type'),
            date: moment(get(data, 'workReminder.reminderTime')),
          }
        : undefined,
      priority: get(data, 'priority'),
      tags: get(data, 'workUserTags', []).map(tag => tag.workTagId),
      [CHU_TRI]: preside?.[0]
        ? {
            id: preside?.[0]?.responsibilityId,
            type: preside?.[0]?.assignType,
          }
        : null,
      [PHOI_HOP]: originCoordinations,
      [THEO_DOI]: originWatchers,
      referenceFiles,
      attachmentFiles,
    }
  }, [])

  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getCongViec(actionId),
      afterResponse: data => {
        _mountedSet(setTasks, [
          {
            ...DEFAULT_TASK,
            id: actionId,
            data: _convertToStateData(data),
          },
        ])
      },
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [actionId, _convertToStateData, DEFAULT_TASK])

  const addMeeting = () => {
    _mountedSet(setTasks, [
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (isUpdate) {
      _fetchDetail()
    }
  }, [_fetchDetail, isUpdate])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>{isUpdate ? 'Sửa / hủy kết luận' : 'Tạo kết luận'}</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          disabled={isLoading}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 170}>
        <main>
          {tasks.map(({ id, showTask, showExtra, data }, index) => (
            <Fragment key={id}>
              {!!index && (
                <span
                  style={{
                    display: 'block',
                    height: 5,
                    background: '#DFE6EA',
                  }}
                />
              )}
              <div style={{ padding: 10 }}>
                {size(tasks) > 1 && (
                  <header className="d-flex">
                    <Button
                      className={classNames(
                        Classes.MINIMAL,
                        'cpc-button',
                        'cpc-form-button-left',
                        'font-weight-600',
                        'uppercase',
                        'text-left'
                      )}
                      intent={Intent.PRIMARY}
                      onClick={toggleCollapseTask(id)}
                      style={{ lineHeight: 1.3 }}
                    >
                      {`Kết luận ${index + 1}`}
                      <Icon
                        classIcon={
                          showTask ? 'icon2-arrow-up' : 'icon2-arrow-down'
                        }
                        className={classNames('ml5', 'size-icon-10', 'd-flex')}
                      />
                    </Button>
                    <Button
                      className={classNames(
                        Classes.MINIMAL,
                        'cpc-button',
                        'cpc-form-button-right'
                      )}
                      intent={Intent.DANGER}
                      onClick={removeTask(id)}
                    >
                      <Icon classIcon="icon-bin" />
                    </Button>
                  </header>
                )}
                <Collapse isOpen={showTask}>
                  <Input
                    required={true}
                    disabled={fetching}
                    label="Nội dung kết luận"
                    onChange={changeField(id, 'title')}
                    placeholder="Nhập nội dung kết luận"
                    type="textarea"
                    rows={2}
                    maxLength={2000}
                    autoResize={true}
                    value={get(data, 'title')}
                    wrapperClassName="mb15"
                    labelButton={
                      <div style={{ marginLeft: 'auto' }}>
                        <Checkbox
                          className={classNames(Classes.SMALL, 'mb0')}
                          checked={get(data, 'requireReport', false)}
                          onChange={({ target: { checked } }) =>
                            changeField(id, 'requireReport')(checked)
                          }
                        >
                          <span className="font-size-13 font-weight-300">
                            Yêu cầu báo cáo Hoàn thành
                          </span>
                        </Checkbox>
                      </div>
                    }
                    warning={get(validateErrors, `${id}.title`)}
                  />
                  <PhongBanVaDonViThucHien
                    className="mb10"
                    isRequired={true}
                    column={column}
                    data={dataTreeOrganizationDepartmentStore}
                    taskId={id}
                    warning={get(validateErrors, `${id}.${CHU_TRI}`)}
                    dataAutoToggle={dataAutoToggle}
                  />
                  <Row gutterVertical className="mb10">
                    <Col grid="half">
                      <FormDatePicker
                        textLabel="Ngày bắt đầu"
                        disabled={fetching}
                        inputClearable
                        inputIconClassName="icon2-date-frames"
                        inputWrapperClassName="mb10"
                        onChange={value => {
                          changeField(id, 'executionDate')(value)
                        }}
                        maximumDate={
                          get(data, 'deadline')
                            ? moment(get(data, 'deadline')).toDate()
                            : undefined
                        }
                        placeholder="Chọn ngày bắt đầu"
                        popoverStretch={false}
                        popoverPosition={Position.TOP_LEFT}
                        selected={get(data, 'executionDate')}
                      />
                    </Col>
                    <Col grid="half">
                      <DateAdvanced
                        textLabel="Hạn thực hiện"
                        disabled={fetching}
                        inputClearable
                        inputIconClassName="icon2-date-frames"
                        inputWrapperClassName="mb10"
                        placeholder="Chọn hạn thực hiện"
                        popoverStretch={false}
                        selected={get(data, 'deadline')}
                        inputWarning={get(validateErrors, `${id}.deadline`)}
                        minimumDate={
                          get(data, 'executionDate')
                            ? moment(get(data, 'executionDate')).toDate()
                            : moment().toDate()
                        }
                        popoverPosition={Position.TOP_RIGHT}
                        onChange={value => {
                          changeField(id, 'deadline')(value)
                        }}
                      />
                    </Col>
                  </Row>
                  <Collapse isOpen={showExtra}>
                    <Input
                      disabled={fetching}
                      label="Mô tả"
                      onChange={changeField(id, 'description')}
                      placeholder="Nhập..."
                      rows={2}
                      maxLength={500}
                      autoResize={true}
                      style={{ maxHeight: 'unset' }}
                      type="textarea"
                      value={get(data, 'description')}
                      wrapperClassName="mb10"
                    />
                    <Row gutterVertical className="mb10">
                      <Col grid="half">
                        <Select
                          textLabel="Mức độ ưu tiên"
                          dataSource={toPairs(priorities).map(
                            ([value, label]) => ({ value, label })
                          )}
                          disabled={fetching}
                          inputIconClassName="icon-flag"
                          onChange={changeField(id, 'priority')}
                          placeholder="Chọn mức độ ưu tiên"
                          value={get(data, 'priority')}
                        />
                      </Col>
                      <Col grid="half">
                        <RemindSelect
                          textLabel="Thời gian nhắc việc"
                          inputIconClassName="icon2-date-frames"
                          deadline={get(data, 'deadline')}
                          disabled={fetching}
                          onChange={changeField(id, 'workReminder')}
                          value={data?.workReminder || null}
                          placeholder="Thời gian nhắc việc"
                          popoverPosition={Position.BOTTOM_RIGHT}
                        />
                      </Col>
                    </Row>
                    <CheckboxSelect
                      textLabel="Loại công việc"
                      disabled={fetching}
                      inputClearable
                      inputIconClassName="icon2-tag"
                      inputWrapperClassName="mb10"
                      placeholder="Loại công việc"
                      popoverPosition={Position.TOP_LEFT}
                      onChange={changeField(id, 'tags')}
                      dataSource={tags.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      value={get(data, 'tags', [])}
                    />
                    {!!size(get(data, 'referenceFiles', [])) && (
                      <>
                        <label className="cpc-detail-label">
                          File công văn
                        </label>
                        <FileGroup list={get(data, 'referenceFiles')} />
                      </>
                    )}
                    {!!size(get(data, 'attachmentFiles', [])) &&
                      !!size(
                        attachmentFilesFromDocument(
                          get(data, 'attachmentFiles', []),
                          true
                        )
                      ) && (
                        <>
                          <label className="cpc-detail-label">
                            File đính kèm từ công văn
                          </label>
                          <FileGroup
                            list={attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              true
                            )}
                            onClose={removeAttachmentFiles(id)}
                          />
                        </>
                      )}
                    {((!!size(get(data, 'attachmentFiles', [])) &&
                      !!size(
                        attachmentFilesFromDocument(
                          get(data, 'attachmentFiles', []),
                          false
                        )
                      )) ||
                      !!size(files[id])) && (
                      <>
                        <label className="cpc-detail-label">
                          File đính kèm từ bên ngoài
                        </label>
                        <FileGroup
                          list={attachmentFilesFromDocument(
                            get(data, 'attachmentFiles', []),
                            false
                          )}
                          listUpload={files[id]}
                          onClose={removeAttachmentFiles(id)}
                          showClose
                        />
                      </>
                    )}
                    <Upload
                      textLabel="File đính kèm"
                      disabled={fetching}
                      accept={documentTypes.toString()}
                      fileList={get(files, id, [])}
                      textSumary={
                        <p className="mt5 font-style-italic font-size-12">
                          Tải file có dung lượng tối đa 100MB, định dạng .pdf,
                          .docs, .doc, .xls, .xlsx, .ppt, .pptx
                        </p>
                      }
                      wrapperClassName="mb10"
                      isShowList={false}
                      allowFileTypes={allowDocumentFileTypes}
                      onChange={changeFile(id)}
                    />
                  </Collapse>
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-left'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={toggleCollapseExtra(id)}
                  >
                    {showExtra ? 'Thu gọn' : 'Mở rộng'}
                    <Icon
                      classIcon={
                        showExtra ? 'icon2-arrow-up' : 'icon2-arrow-down'
                      }
                      className={classNames('ml5', 'size-icon-10', 'd-flex')}
                    />
                  </Button>
                </Collapse>
              </div>
            </Fragment>
          ))}
        </main>
        {!isUpdate && (
          <div className="pt10 pl10 pr10 border-top">
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-button',
                'qlcv-green-color',
                'cpc-form-button-left',
                'font-size-13'
              )}
              onClick={addMeeting}
            >
              <Icon classIcon="icon-Plus" className="size-icon-12 mr5" />
              Thêm nội dung kết luận
            </Button>
          </div>
        )}
      </Scrollbar>
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <div
          className={classNames('element-center', 'pv10 ph10', 'border-top')}
        >
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100',
              'mr15'
            )}
            disabled={isLoading}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100'
            )}
            intent={Intent.PRIMARY}
            disabled={isLoading || isDisableButton}
            loading={isLoading}
            onClick={isUpdate ? submitUpdate : submitCreate}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>
      <Dialog
        buttonUppercase
        isOpen={isShowDialog}
        cancelClassName="pl20 pr20"
        cancelText="Hủy"
        confirmClassName="pl20 pr20"
        confirmText="Xóa"
        onCancel={onCloseDialog}
        onConfirm={onDeleteTask}
        onClose={onCloseDialog}
        title={CONFIRM_TITLE}
        textCenter
      >
        <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
      </Dialog>
    </div>
  )
}

export default withRouter(memo(WorkMeetingUpdate))
