import React, { memo } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import csx from 'classnames'
import { FILE_URL } from 'constants/Api'
import { Utils } from 'helpers'
import { Icon } from 'components/newCommon/Icon'

const File = ({
  file = {},
  smallIcon = true,
  auth = {},
  showClose = false,
  readOnly = false,
  className = '',
  isLink = true,
  disabled = false,
  onClose: fileClose = () => {},
  onChange = () => {},
}) => {
  const { name, size, id, application } = file

  const token = get(auth, 'token.accessToken', '')
  let url = null
  if (isLink && application !== 'DOFFICE') {
    url = `${FILE_URL}/api/file/get/${id}?BearerToken=${token}`
  }

  const onClick = e => {
    onChange(file)
    if (readOnly) {
      e.preventDefault()
    }
  }

  const onClose = e => {
    e.preventDefault()
    fileClose(file)
  }

  const getFileExtension = fileName => {
    if (!fileName) {
      return null
    }

    const fileExtension = fileName.split('.').pop()
    return fileExtension
  }

  return (
    <span className="align-center">
      <a
        style={{
          backgroundImage: `url(${Utils.typeFile(getFileExtension(name))})`,
        }}
        className={csx(
          'd-flex',
          'd-flex-wrap',
          'align-center',
          'link-file',
          'font-size-13',
          {
            'small-file-icon': smallIcon,
            'read-only': readOnly,
          },
          className
        )}
        href={url}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>{name}</div>
        {size > 0 && <span className="ml5">- {Utils.getFileSize(size)}</span>}
      </a>
      {showClose && !disabled && (
        <Icon
          classIcon="icon-close"
          className="has-event ml10"
          style={{ color: '#999999', fontSize: '14px' }}
          onClick={onClose}
        />
      )}
    </span>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(memo(File))
