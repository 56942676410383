import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import csx from 'classnames'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FileDownload from 'file-saver'
import {
  Button,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Menu,
  MenuItem,
  Icon,
} from '@blueprintjs/core'

import Fragment from 'components/common/Fragment'
import requestAction from 'helpers/request'
import {
  ASSIGN_TYPE,
  WORK_STATUS,
  STATISTIC_WORK_RESPONSIBILITY,
  FILE_TYPE,
  WORK_MEETING_STATUS,
  RESPONSIBILITY_TYPE,
  WORK_TYPE,
} from 'constants/Enum'
import { Action, Toast, TEXT_HOANTHANH_CONGVIEC } from 'constants/MessageForm'
import {
  getCongViec,
  patchTienDo,
  getThongKeLoaiGiaoViec,
  getThongKeTrangThaiCongViec,
  showToast,
  getInformationWorkItemAction,
  getDODowloadFile,
} from 'actions/task'
import {
  StatusDate,
  Scrollbar,
  StatusTag,
  PrioritizeFlag,
  UserCard,
  CollapseMore,
  AvatarGroup,
  FileGroup,
  TagGroup,
  Loading,
  Icon as Icon2,
  SliderBar,
} from 'components/newCommon'
import { Progress } from 'components/newCommon2'
import ToDoList from './ToDoList'
import Report from './Report'
import Comment from './Comment'
import { TaskContext } from '../AllTask'

const dateFormat = 'DD/MM/YYYY'

const TaskDetail = ({
  history,
  match,
  roleId,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { detail, setDetail } = useContext(TaskContext)
  const { id, type: taskType } = match.params
  const [fetching, setFetching] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [informationWorkItemAction, setInformationWorkItemAction] = useState({
    allowApproval: false,
    approval: '',
    isLock: true,
    showCancelApprove: false,
    assigned: null,
    isAccept: false,
  })
  const [deadline, setDeadline] = useState(null)
  const [main, setMain] = useState([])
  const [executor, setExecutor] = useState({})
  const [followers, setFollowers] = useState([])
  const [coordinations, setCoordinations] = useState([])
  const [userMention, setUserMention] = useState([])
  const [percent, setPercent] = useState(0)
  const [isSaveDisable, setIsSaveDisable] = useState(true)

  const _getProgressRequestData = (percent = 0) => {
    return {
      progress: percent,
      status:
        percent === 100
          ? WORK_STATUS.HOAN_THANH
          : percent === 0
          ? WORK_STATUS.CHUA_THUC_HIEN
          : WORK_STATUS.DANG_THUC_HIEN,
    }
  }

  const handleCheckWorkItemAction = useCallback(() => {
    setInformationWorkItemAction({
      allowApproval: false,
      approval: '',
      isLock: true,
      showCancelApprove: false,
      assigned: null,
      isAccept: false,
    })
    requestAction({
      showToast: false,
      action: () => getInformationWorkItemAction(id),
      afterResponse: res => {
        setInformationWorkItemAction(res)
      },
    })
  }, [id])

  const handleProgress = useCallback(() => {
    if (percent === get(detail, 'progress')) return
    const data = _getProgressRequestData(percent)
    const finishedDate = moment().format()
    mountedSet(setDisableButton, true)
    requestAction({
      codeCheck: false,
      getResult: false,
      action: () => {
        return patchTienDo(id, data)
      },
      afterResponse: () => {
        const { status: oldStatus } = detail
        handleCheckWorkItemAction()
        reloadList()

        if (![oldStatus].includes(data.status)) {
          reloadThongKe()
        }

        mountedSet(setDetail, {
          ...detail,
          ...data,
          finishedDate,
        })
      },
      successMessage:
        percent === 100
          ? TEXT_HOANTHANH_CONGVIEC
          : Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      afterAction: () => {
        mountedSet(setDisableButton, false)
        mountedSet(setIsSaveDisable, true)
      },
    })
  }, [percent, detail, id, setDetail, reloadList, handleCheckWorkItemAction])

  const changeProgress = (percent = 0) => {
    mountedSet(setIsSaveDisable, false)
    mountedSet(setPercent, percent)
  }

  const reloadThongKe = () => {
    // Get thống kê quản lý công việc
    getThongKeLoaiGiaoViec()
    // Get thống kê trạng thái công việc
    getThongKeTrangThaiCongViec({
      responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
    })
  }

  const _splitDetailFiles = useCallback(data => {
    return {
      ...data,
      referenceFile: (data?.files || []).filter(
        file => file.type === FILE_TYPE.FILE_THAM_KHAO
      ),
      files: (data?.files || []).filter(
        file => file.type === FILE_TYPE.FILE_DINH_KEM
      ),
    }
  }, [])

  const fetchDetail = useCallback(() => {
    const initDeadline = values => {
      if (isEmpty(values)) {
        return
      }

      let masterDeadline = values?.deadline
      if (values?.assigner?.id !== values?.creatorProfileId) {
        const dataGet = values?.responsibilities?.find(
          elm =>
            elm?.workAssignments?.[0] && size(elm?.workAssignments?.[0]) !== 0
        )

        masterDeadline = dataGet?.workAssignments?.[0]?.deadline
      }

      mountedSet(setDeadline, masterDeadline)
    }

    requestAction({
      showToast: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getCongViec(id),
      afterResponse: (result = {}) => {
        const isAssignByMe = result?.assigner?.id === roleId
        initDeadline(result)
        mountedSet(setPercent, result?.progress)
        mountedSet(setDetail, _splitDetailFiles(result))
        mountedSet(setExecutor, get(result, 'mainResponsibility', {}))
        mountedSet(
          setMain,
          isAssignByMe
            ? result?.responsibilities?.[0]?.workAssignments || []
            : result?.responsibilitiesDetails?.[0]?.workAssignments || []
        )
        mountedSet(
          setCoordinations,
          isAssignByMe
            ? result?.responsibilities?.[1]?.workAssignments || []
            : result?.responsibilitiesDetails?.[1]?.workAssignments || []
        )
        mountedSet(
          setFollowers,
          isAssignByMe
            ? result?.responsibilities?.[2]?.workAssignments || []
            : result?.responsibilitiesDetails?.[2]?.workAssignments || []
        )
        mountedSet(setUserMention, getUsersInTaskDetail(result?.relatedStaff))
        if (get(result, 'read') === false) {
          reloadThongKe()
        }
      },
      afterAction: () => {
        mountedSet(setFetching, false)
      },
      afterError: () => {
        showToast({
          message: Toast.NOT_FOUND('công việc'),
          intent: Intent.WARNING,
        })
        onClose()
      },
    })
  }, [id, roleId, setDetail, setPercent, _splitDetailFiles, onClose])

  const getUsersInTaskDetail = data => {
    return uniqBy(
      data?.map(e => ({
        id: e?.id,
        display: e?.name,
      })),
      'id'
    )
  }

  const checkIsEditGiveTask = () => {
    const isToiGiao = taskType === 'toi-giao'
    const isAssigned = informationWorkItemAction?.assigned
    return isToiGiao || isAssigned
  }

  const goToAction = useCallback(
    endpoint => () => {
      if (endpoint) {
        history.push({
          pathname: `/quan-ly-cong-viec/${taskType}/${endpoint}/${id}`,
          state: {
            showGoBack: true,
          },
        })
      }
    },
    [taskType, id, history]
  )

  const getMenu = useCallback(() => {
    const workType = detail?.workType
    const allowChange =
      get(detail, 'status') === WORK_STATUS.CHUA_THUC_HIEN &&
      detail.creatorProfileId === roleId &&
      taskType !== 'theo-doi' &&
      workType !== WORK_TYPE.MEETING

    return [
      {
        text: (
          <>
            <i className="icon-organization font-size-13" /> Luồng công việc
          </>
        ),
        onClick: goToAction('luong-cong-viec'),
      },
      {
        text: (
          <>
            <i className="icon-thoigianluu font-size-15" /> Lịch sử xác nhận
          </>
        ),
        onClick: goToAction('lich-su-xac-nhan'),
      },
      ...(allowChange
        ? [
            {
              text: (
                <>
                  <i className="icon-but" /> Sửa công việc
                </>
              ),
              onClick: goToAction('chinh-sua'),
            },
          ]
        : []),
      {
        text: (
          <>
            <i className="icon2-bell size-icon-15" /> Nhắc việc
          </>
        ),
        onClick: goToAction('nhac-viec'),
      },
      {
        text: (
          <>
            <i className="icon-tag size-icon-10" /> Loại công việc (tags)
          </>
        ),
        onClick: goToAction('tag'),
      },
      ...(allowChange
        ? [
            {
              text: (
                <>
                  <i className="icon-bin" /> Xóa công việc
                </>
              ),
              onClick: goToAction('xoa'),
            },
          ]
        : []),
    ]
  }, [detail, taskType, roleId, goToAction])

  const renderStatus = values => {
    if (
      values?.status === WORK_STATUS.HOAN_THANH &&
      values?.approval === WORK_MEETING_STATUS.APPROVED
    ) {
      return values?.approval
    }

    return values?.status
  }

  const renderType = values => {
    if (!values) {
      return false
    }

    switch (values) {
      case RESPONSIBILITY_TYPE.CHU_TRI:
        return 'Chủ trì'
      case RESPONSIBILITY_TYPE.PHOI_HOP:
        return 'Phối hợp'
      case RESPONSIBILITY_TYPE.THEO_DOI:
        return 'Theo dõi'
      default:
        break
    }
  }

  const renderApproval = values => {
    if (values?.approval === WORK_MEETING_STATUS.WAITING_APPROVAL) {
      return values?.approval
    }

    return values?.status
  }

  const displayHtml = (values, type) => {
    return (
      <div className={csx('border-bottom', 'mb10')}>
        <div className="mb10">
          <label className={csx('cpc-detail-label', 'mb5')}>
            {type === 'assigner' ? 'Người giao' : 'Người tạo'}
          </label>
          <UserCard
            needBaseUrl
            src={values?.user?.avatar}
            name={values?.user?.name}
            department={values?.user?.department}
            unit={values?.user?.unit}
          />
        </div>
        <div className={csx('d-flex', 'mb10')}>
          {values?.creationTime && type === 'creator' && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày tạo:</label>
              <span className="cpc-detail-text">{values?.creationTime}</span>
            </span>
          )}
          {values?.assignDate && type === 'assigner' && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày giao:</label>
              <span className="cpc-detail-text">{values?.assignDate}</span>
            </span>
          )}
          {values?.executionDate && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày bẳt đầu:</label>
              <span className="cpc-detail-text">{values?.executionDate}</span>
            </span>
          )}
          {values?.deadline && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Hạn thực hiện:</label>
              <span className="cpc-detail-text">{values?.deadline}</span>
            </span>
          )}
        </div>
        {values?.description && (
          <div className="mb10">
            <label className="cpc-detail-label">Mô tả</label>
            <p className="cpc-detail-norm">{values?.description}</p>
          </div>
        )}
      </div>
    )
  }

  const renderCreator = values => {
    const {
      creator,
      assigner,
      creatorProfileId,
      creationTime,
      executionDate,
      deadline,
      description,
    } = values
    if (assigner?.id === creatorProfileId) {
      return ''
    }

    return displayHtml(
      {
        user: {
          avatar: creator?.avatar,
          name: creator?.fullName,
          department: creator?.department?.shortName,
          unit: creator?.organization?.shortName,
        },
        creationTime:
          creationTime && moment(creationTime).isValid()
            ? moment(creationTime).format(dateFormat)
            : null,
        executionDate:
          executionDate && moment(executionDate).isValid()
            ? moment(executionDate).format(dateFormat)
            : null,
        deadline:
          deadline && moment(deadline).isValid()
            ? moment(deadline).format(dateFormat)
            : null,
        description: description,
      },
      'creator'
    )
  }

  const renderAssigner = values => {
    let masterData = values
    if (values?.assigner?.id !== values?.creatorProfileId) {
      const dataGet = values?.responsibilities?.find(
        elm =>
          elm?.workAssignments?.[0] && size(elm?.workAssignments?.[0]) !== 0
      )
      masterData = dataGet?.workAssignments?.[0]
    }

    const { assigner, assignDate, executionDate, deadline, description } =
      masterData

    return displayHtml(
      {
        user: {
          avatar: assigner?.avatar,
          name: assigner?.name,
          department: assigner?.information?.department?.shortName,
          unit: assigner?.information?.organization?.shortName,
        },
        assignDate:
          assignDate && moment(assignDate).isValid()
            ? moment(assignDate).format(dateFormat)
            : null,
        executionDate:
          executionDate && moment(executionDate).isValid()
            ? moment(executionDate).format(dateFormat)
            : null,
        deadline:
          deadline && moment(deadline).isValid()
            ? moment(deadline).format(dateFormat)
            : null,
        description: description,
      },
      'assigner'
    )
  }

  const handleChangeFile = useCallback(file => {
    if (file?.application === 'DOFFICE') {
      const params = {
        fileDoId: file?.fileId,
        workType: 'DOFFICE',
      }

      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDODowloadFile(params),
        afterResponse: async response => {
          if (response) {
            FileDownload.saveAs(response, file?.name)
          }
        },
      })
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    handleCheckWorkItemAction()
  }, [handleCheckWorkItemAction])

  const isDOfficeWork = detail?.workType === WORK_TYPE.DOFFICE
  const isStatusNew = get(detail, 'status') === WORK_STATUS.CHUA_THUC_HIEN
  const isAllowAssign =
    isStatusNew &&
    (taskType === 'toi-giao' ||
      (taskType === 'duoc-giao' &&
        informationWorkItemAction?.assigned !== null))
  const isEditGiveTask = checkIsEditGiveTask()
  const attachmentFilesFromDocument = !!size(get(detail, 'files'))
    ? detail.files.filter(elm => elm.application === 'eOffice')
    : []
  const attachmentFilesNotFromDocument = !!size(get(detail, 'files'))
    ? detail.files.filter(elm => elm.application !== 'eOffice')
    : []
  const isNotRatify =
    taskType === 'toi-giao' &&
    informationWorkItemAction?.showCancelApprove &&
    get(detail, 'approval') === WORK_MEETING_STATUS.APPROVED
  const isShowNotRatifyButton = !fetching && isNotRatify
  const isShowAssignButton = !fetching && isAllowAssign
  const isAccept = informationWorkItemAction?.isAccept
  const isShowGoBack = true
  const isAssignByMe = detail?.assigner?.id === roleId
  let isShowExecutor = false
  if (
    size(main) !== 0 &&
    detail?.responsibilityType !== RESPONSIBILITY_TYPE.CHU_TRI &&
    !isAssignByMe
  ) {
    isShowExecutor = true
  }

  return (
    <div className={csx('cpc-side-action', 'open')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>Chi tiết công việc</span>
        <Icon2
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 182}>
        <div style={{ padding: 10 }}>
          {fetching ? (
            <Loading />
          ) : (
            <div className="cpc-detail">
              <div className="cpc-detail-block">
                {!!size(getMenu()) && (
                  <span style={{ float: 'right' }}>
                    <Popover
                      popoverClassName="cpc-popover no-arrow mr10"
                      position={Position.BOTTOM_RIGHT}
                      interactionKind={PopoverInteractionKind.CLICK}
                      content={
                        <Menu className="cpc-context-menu no-border">
                          {getMenu().map(
                            ({ text = '', onClick = () => {} }, index) => (
                              <MenuItem
                                key={index}
                                onClick={onClick}
                                text={
                                  <span className="cpc-context-menu-item">
                                    {text}
                                  </span>
                                }
                              />
                            )
                          )}
                        </Menu>
                      }
                    >
                      <Icon className="menu-button" iconName="more" />
                    </Popover>
                  </span>
                )}
                <div className={csx('pb10', 'border-bottom', 'mb10')}>
                  {detail?.workType === WORK_TYPE.MEETING && (
                    <div
                      className={csx(
                        'uppercase',
                        'font-size-11',
                        'font-weight-600',
                        'text-yellow',
                        'mb5'
                      )}
                    >
                      <Icon2
                        classIcon="icon2-clipboard"
                        className={csx(
                          'icon-color-yellow',
                          'size-icon-18',
                          'mr5',
                          'vertical-align-text-bottom'
                        )}
                      />
                      Công việc Thông báo KLCH
                    </div>
                  )}
                  {detail?.workType === WORK_TYPE.EOFFICE && (
                    <div
                      className={csx(
                        'uppercase',
                        'font-size-11',
                        'font-weight-600',
                        'text-primary',
                        'mb5'
                      )}
                    >
                      <Icon2
                        classIcon="icon2-e-office-label"
                        className={csx(
                          'icon-color-primary',
                          'size-icon-18',
                          'mr5',
                          'vertical-align-text-bottom'
                        )}
                      />
                      Công việc Ký số, Công văn
                    </div>
                  )}
                  {detail?.workType === WORK_TYPE.DOFFICE && (
                    <div
                      className={csx(
                        'uppercase',
                        'font-size-11',
                        'font-weight-600',
                        'text-danger',
                        'mb5'
                      )}
                    >
                      <Icon2
                        classIcon="icon2-d-office"
                        className={csx(
                          'icon-color-danger',
                          'size-icon-18',
                          'mr5',
                          'vertical-align-text-bottom'
                        )}
                      />
                      Công việc D-Office
                    </div>
                  )}
                  <h3
                    className={csx(
                      'font-size-13',
                      'font-weight-600',
                      'pr20',
                      'mb10'
                    )}
                  >
                    {get(detail, 'title')}
                  </h3>
                  {size(detail?.workUserTags) !== 0 && (
                    <TagGroup
                      tags={get(detail, 'workUserTags', []).map(
                        ({ workTagName, backgroundColor }) => ({
                          name: workTagName,
                          color: backgroundColor,
                        })
                      )}
                      popoverPosition={Position.BOTTOM_RIGHT}
                      popoverClassName="mr15"
                      className="mb10"
                    />
                  )}
                  {detail?.requireReport && (
                    <p className="font-size-13">
                      <Icon2
                        classIcon="icon-Bao_Cao"
                        className="font-size-10 mr5"
                      />
                      Yêu cầu báo cáo Hoàn thành
                    </p>
                  )}
                </div>
                <div className={csx('d-flex', 'mb10')}>
                  {(deadline ||
                    (get(detail, 'finishedDate') &&
                      get(detail, 'status') === 'DONE')) && (
                    <span className="cpc-detail-left" style={{ flex: 1 }}>
                      {deadline && (
                        <div>
                          <label className="cpc-detail-label">
                            Hạn thực hiện:
                          </label>
                          <StatusDate
                            className="cpc-detail-text"
                            date={deadline}
                            descriptionInline
                            showDescription={false}
                            isCompleted={get(detail, 'status') === 'DONE'}
                          />
                        </div>
                      )}
                      {get(detail, 'finishedDate') &&
                        get(detail, 'status') === 'DONE' && (
                          <div>
                            <label className="cpc-detail-label">
                              Ngày hoàn thành:
                            </label>
                            <StatusDate
                              className="cpc-detail-text"
                              date={get(detail, 'finishedDate')}
                              descriptionInline
                              isCompleted
                            />
                          </div>
                        )}
                    </span>
                  )}
                  <span className="cpc-detail-right">
                    {detail?.approval ===
                      WORK_MEETING_STATUS.WAITING_APPROVAL && (
                      <StatusTag type={renderApproval(detail)} />
                    )}
                    <StatusTag type={renderStatus(detail)} className="ml25" />
                    <PrioritizeFlag
                      type={get(detail, 'priority')}
                      className="ml10"
                    />
                  </span>
                </div>
                <div className={csx('d-flex', 'pb10', 'border-bottom', 'mb10')}>
                  <div className="cpc-detail-left">
                    <div>
                      {isAssignByMe ? (
                        <span
                          className="uppercase font-size-10"
                          style={{ color: '#738a95' }}
                        >
                          Chủ trì
                        </span>
                      ) : (
                        <div>
                          <label className="cpc-detail-label">Thực hiện:</label>
                          <span
                            className={csx(
                              'uppercase font-size-10 font-weight-600',
                              {
                                'text-blue':
                                  detail?.responsibilityType ===
                                    'MAIN_RESPONSIBILITY' &&
                                  detail?.isMainResponsibility,
                              }
                            )}
                          >
                            {renderType(detail?.responsibilityType)}
                          </span>
                        </div>
                      )}
                    </div>
                    {!!size(executor) && (
                      <div className="mt10">
                        {get(executor, 'type') === ASSIGN_TYPE.CA_NHAN ? (
                          <UserCard
                            needBaseUrl
                            src={get(executor, 'avatar')}
                            name={get(executor, 'name')}
                            department={get(
                              executor,
                              'information.department.shortName'
                            )}
                            unit={get(
                              executor,
                              'information.organization.shortName'
                            )}
                          />
                        ) : (
                          <span className="uppercase font-weight-500 font-size-12">
                            {get(executor, 'shortName')}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <span
                    className={csx('cpc-detail-right', {
                      'has-action': true,
                    })}
                  >
                    <label
                      className={csx('d-block', 'cpc-detail-label', 'mb5')}
                    >
                      Tiến độ
                    </label>
                    <Popover
                      content={
                        <div style={{ width: 355, padding: 10 }}>
                          <SliderBar
                            onRelease={changeProgress}
                            value={percent}
                          />
                        </div>
                      }
                      popoverClassName="cpc-popover no-arrow"
                      position={Position.BOTTOM_RIGHT}
                      isDisabled={informationWorkItemAction?.isLock}
                    >
                      <div style={{ width: 32 }}>
                        <Progress progress={percent} />
                      </div>
                    </Popover>
                  </span>
                </div>
                {(isShowExecutor ||
                  size(followers || []) !== 0 ||
                  size(coordinations || []) !== 0) && (
                  <div className={csx('border-bottom', 'mb10')}>
                    <ul className="d-flex">
                      {isShowExecutor && (
                        <li className="pb10 mr30">
                          <div>
                            <label className={csx('cpc-detail-label', 'mb5')}>
                              Chủ trì
                            </label>
                            <AvatarGroup
                              list={main
                                ?.filter(e => e?.responsibility)
                                ?.map(elm => elm?.responsibility)}
                              needBaseUrl
                              small
                            />
                          </div>
                        </li>
                      )}
                      {size(coordinations || []) !== 0 && (
                        <li className="pb10 mr30">
                          <div>
                            <label className={csx('cpc-detail-label', 'mb5')}>
                              Phối hợp
                            </label>
                            <AvatarGroup
                              list={coordinations
                                ?.filter(e => e?.responsibility)
                                ?.map(
                                  coordination => coordination?.responsibility
                                )}
                              needBaseUrl
                              small
                            />
                          </div>
                        </li>
                      )}
                      {size(followers || []) !== 0 && (
                        <li className="pb10">
                          <div>
                            <label className="cpc-detail-label">Theo dõi</label>
                            <AvatarGroup
                              list={followers
                                ?.filter(e => e?.responsibility)
                                ?.map(follower => follower?.responsibility)}
                              needBaseUrl
                              small
                              position={Position.TOP_RIGHT}
                            />
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <CollapseMore
                  textShow="Xem thêm"
                  textHide="Thu gọn"
                  limitDisplay={0}
                  buttonSpace={false}
                  // defaultOpen
                >
                  {renderCreator(detail)}
                  {renderAssigner(detail)}
                  {(!!size(get(detail, 'files', [])) ||
                    !!size(get(detail, 'referenceFile', []))) && (
                    <div className={csx('pb10', 'border-bottom', 'mb10')}>
                      {!!size(get(detail, 'referenceFile')) && (
                        <div>
                          <label className="cpc-detail-label">
                            File công văn
                          </label>
                          <FileGroup
                            list={get(detail, 'referenceFile')}
                            onChange={handleChangeFile}
                          />
                        </div>
                      )}
                      {!!size(attachmentFilesFromDocument) && (
                        <div>
                          <label className="cpc-detail-label">
                            File đính kèm từ công văn
                          </label>
                          <FileGroup
                            list={attachmentFilesFromDocument}
                            onChange={handleChangeFile}
                          />
                        </div>
                      )}
                      {!!size(attachmentFilesNotFromDocument) && (
                        <div>
                          <label className="cpc-detail-label">
                            File đính kèm từ bên ngoài
                          </label>
                          <FileGroup list={attachmentFilesNotFromDocument} />
                        </div>
                      )}
                    </div>
                  )}
                </CollapseMore>
              </div>
              <Fragment>
                <ToDoList allowAction={!informationWorkItemAction?.isLock} />
                <Report allowAction={!informationWorkItemAction?.isLock} />
                <Comment users={userMention} />
              </Fragment>
            </div>
          )}
        </div>
      </Scrollbar>
      {(isShowGoBack ||
        isShowNotRatifyButton ||
        isShowAssignButton ||
        isAccept ||
        !informationWorkItemAction?.isLock) && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#f8f9fb',
            boxShadow: '-1px 0px 4px rgba(0,0,0,0.1)',
          }}
        >
          <div className="p10">
            <div className="text-center mt10 mb10">
              {isShowGoBack && (
                <Button
                  className={csx(
                    'cpc-button',
                    'btn-cancel',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  disabled={disableButton}
                  onClick={onClose}
                >
                  <Icon2 classIcon="icon-back" className="mr5" />
                  Quay lại
                </Button>
              )}
              {isShowNotRatifyButton && (
                <Button
                  className={csx(
                    'cpc-button',
                    'red-border',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  disabled={disableButton}
                  onClick={goToAction('huy-duyet')}
                >
                  <span>Hủy duyệt</span>
                  <Icon2 classIcon="icon-Huy" className="font-size-12 ml5" />
                </Button>
              )}
              {isShowAssignButton && (
                <Button
                  className={csx(
                    'cpc-button',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  intent={Intent.PRIMARY}
                  disabled={disableButton}
                  onClick={goToAction(
                    isEditGiveTask
                      ? isDOfficeWork
                        ? 'sua-giao-viec-d-office'
                        : 'sua-giao-viec'
                      : isDOfficeWork
                      ? 'giao-viec-d-office'
                      : 'giao-viec'
                  )}
                >
                  <Icon2
                    classIcon="icon2-give-task"
                    className="font-size-14 mr5"
                  />
                  <span>{isEditGiveTask ? 'Sửa giao việc' : 'Giao việc'}</span>
                </Button>
              )}
              {isAccept && (
                <Button
                  className={csx(
                    'cpc-button',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  intent={Intent.PRIMARY}
                  disabled={disableButton}
                  onClick={goToAction('xac-nhan')}
                >
                  <span>Xác nhận</span>
                  <Icon2
                    classIcon="icon-Bao_Cao"
                    className="icon-size-11 ml5"
                  />
                </Button>
              )}
              {!informationWorkItemAction?.isLock && (
                <Button
                  className={csx(
                    'cpc-button',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  intent={Intent.PRIMARY}
                  disabled={isSaveDisable}
                  onClick={handleProgress}
                >
                  <Icon2 classIcon="icon-save" className="font-size-14 mr5" />
                  <span>Lưu</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

TaskDetail.propTypes = {
  roleId: PropTypes.string,
  permission: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    roleId: get(state, 'auth.roleId'),
  }
}

export default withRouter(connect(mapStateToProps)(memo(TaskDetail)))
