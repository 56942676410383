import { Intent } from '@blueprintjs/core'
import { get, isArray, isObject } from 'lodash'

import { showToast } from 'actions/task'

export const parseParams = params => {
  const entries = Object.entries(params)
  let options = ''

  entries.forEach(([key, value]) => {
    const isParamTypeObject = isObject(value)
    const isParamTypeArray = isArray(value)

    if (!isParamTypeObject && !!value) {
      options += `${key}=${value}&`
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach(element => {
        options += `${key}=${element}&`
      })
    }
  })

  return options ? options.slice(0, -1) : options
}

export const requestAction = async ({
  beforeAction = () => {},
  action = () => {},
  afterResponse = () => {},
  afterError = () => {},
  afterAction = () => {},
  successCode = 200,
  successMessage = '',
  errorMessage = '',
  warningMessage = '',
  showToast: allowShowToast = true,
  showToastSucess = true,
  logError = false,
  codeCheck = true,
  getResult = true,
  getResponse = false,
}) => {
  let success = true
  let responseData = null
  try {
    beforeAction()

    const response = await action()
    const data = get(response, 'payload.data') || {}
    const { code, result = {} } = data
    if (getResponse) {
      responseData = data
    }

    if (code === successCode || !codeCheck) {
      afterResponse(getResult ? result : data)

      if (allowShowToast && showToastSucess && successMessage) {
        showToast({
          message: successMessage,
          intent: Intent.SUCCESS,
        })
      }
    } else {
      throw new Error(`Wrong code check. Response code is ${code}`)
    }
  } catch (err) {
    if (logError) console.log(err)
    success = false
    afterError(err)

    if (allowShowToast) {
      const warning = get(err, 'message').includes('warning')
      const warningSpecialMessage = get(err, 'message').replace(/\[(.*?)\]/, '')

      showToast({
        message: warning
          ? warningSpecialMessage && warningSpecialMessage !== 'warning'
            ? warningSpecialMessage
            : warningMessage
          : errorMessage,
        intent: warning ? Intent.WARNING : Intent.DANGER,
      })
    }
  } finally {
    afterAction()
  }
  return getResponse ? responseData : success
}

export default requestAction
