import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withCookies } from 'react-cookie'
import { Button, Intent } from '@blueprintjs/core'
import classnames from 'classnames'
import { get, isEmpty, size } from 'lodash'
import moment from 'moment'
import FileDownload from 'file-saver'

import { Icon } from 'components/newCommon'
import { Select } from 'components/newCommon2'
import { Permission } from 'components/auth'
import requestAction from 'helpers/request'
import { isValidCondition } from 'helpers/Helper'
import BackgroundSetting from './Settings/Background'
import { MasterLayout } from 'components/layout'
import { GUID_EMPTY, ASSIGN_TYPE } from 'constants/Enum'
import { StatisticFilter } from './Filter'
import { Action, Toast } from 'constants/MessageForm'
import {
  ThongKeCongViec,
  ThongKeTiLeCongViec,
  ThongKeTiLeCongViecHalf,
  ThongKeCongViecDanhSach,
} from './statistics'
import {
  showToast,
  getWorkStatistics,
  getWorkStatisticsExportExcel,
  getWorkTypes,
} from 'actions/task'
import { fetchDepartments, getCategoryUnit } from 'actions'

export const StatisticContext = React.createContext({})

const personalSourceOfWork = [
  {
    value: 'ALL',
    label: 'Tất cả nguồn',
  },
  {
    value: '1',
    label: 'Tự giao (Cá nhân)',
  },
  {
    value: '2',
    label: 'Được giao',
  },
]
const roleData = [
  {
    value: 'ALL',
    label: 'Tất cả vai trò',
  },
  {
    value: 'MAIN_RESPONSIBILITY',
    label: 'Chủ trì',
  },
  {
    value: 'COORDINATION',
    label: 'Phối hợp',
  },
]
const requestDateFormat = 'YYYY-MM-DD'
const PAGE_NAME = 'Thống kê công việc'
const colors = {
  green: '#01B051',
  orange: '#ED8526',
  blue: '#195FFF',
  lightBlue: '#1EABF1',
  red: '#f60a18',
  pink: '#FF465D',
  white: '#fff',
  black: '#000',
  yellow: '#EDB900',
}

const Statistic = ({
  actions: { fetchDepartments, getCategoryUnit },
  auth,
  allCookies = {},
  categoryUnit = [],
  listWorkTypes = [],
}) => {
  const DEFAULT_FILTER = {
    FromDate: moment().subtract(1, 'months').format(requestDateFormat),
    ToDate: moment().format(requestDateFormat),
    donVi: '0',
    phongBan: '0',
    WorkTypeId: 'ALL',
    VaiTroId: 'ALL',
    IsGiaoChoChinhMinh: 'ALL',
  }

  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [departments, setdepartments] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isTableDetail, setIsTableDetail] = useState(false)
  const [dataTableDetail, setdataTableDetail] = useState({})
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [filterConvert, setFilterConvert] = useState({})
  const [dataGoback, setDataGoback] = useState([])
  const [dataDoughnut, setDataDoughnut] = useState({})
  const [isBigDesktop, setIsBigDesktop] = useState(false)

  const funcFormatChartData = useCallback(
    items => {
      const isSuperAdmin = isValidCondition(
        auth.permission,
        [Permission.SUPERADMIN],
        'AND'
      )
      const isLanhDaoDonVi = isValidCondition(
        auth.permission,
        [Permission.CONGVANDEN_CHIDAO],
        'AND'
      )
      const isLanhDaoPhongBan = isValidCondition(
        auth.permission,
        [Permission.CONGVIEC_GIAOVIEC],
        'AND'
      )

      const itemTotal = items.filter(elm => elm.id === GUID_EMPTY)
      let dataItem = null
      if (
        (isSuperAdmin || isLanhDaoDonVi || isLanhDaoPhongBan) &&
        size(itemTotal)
      ) {
        dataItem = itemTotal[0]
      } else {
        dataItem = items[0]
      }

      if (!isEmpty(dataItem)) {
        let labelTiLeCongViec = ['Được giao', 'Tự giao (Cá nhân)']
        let dataTiLeCongViec = [
          get(dataItem, 'congViecDuocGiao'),
          get(dataItem, 'congViecTuGiao'),
        ]
        if (isSuperAdmin || isLanhDaoDonVi || isLanhDaoPhongBan) {
          labelTiLeCongViec = ['Từ công văn', 'Các nguồn khác']
          dataTiLeCongViec = [
            get(dataItem, 'congViecTuCongVan'),
            get(dataItem, 'congViecTuBenNgoai'),
          ]
        }

        const dungHan =
          get(dataItem, 'tongSoCongViec', 0) -
          get(dataItem, 'quanHanGiaiQuyet', 0)
        mountedSet(setDataDoughnut, {
          tiLeCongViec: {
            labels: labelTiLeCongViec,
            datasets: [
              {
                data: dataTiLeCongViec,
                backgroundColor: [colors.blue, colors.yellow],
                borderWidth: 0,
                hoverBorderColor: 'transparent',
              },
            ],
          },
          tiLeDungHan: {
            labels: ['Đúng hạn', 'Trễ hạn'],
            datasets: [
              {
                data: [dungHan, get(dataItem, 'quanHanGiaiQuyet')],
                needleValue: dungHan,
                needleValue2: get(dataItem, 'quanHanGiaiQuyet', 0),
                backgroundColor: [colors.lightBlue, colors.pink],
                borderWidth: 0,
                hoverBorderColor: 'transparent',
              },
            ],
          },
          tiLeHoanThanh: {
            labels: ['Đã hoàn thành', 'Chưa hoàn thành'],
            datasets: [
              {
                data: [
                  get(dataItem, 'tongSoCongViecDaHoanThanh', 0),
                  get(dataItem, 'tongSoCongViecChuaHoanThanh', 0),
                ],
                needleValue: get(dataItem, 'tongSoCongViecDaHoanThanh', 0),
                needleValue2: get(dataItem, 'tongSoCongViecChuaHoanThanh', 0),
                backgroundColor: [colors.green, colors.orange],
                borderWidth: 0,
                hoverBorderColor: 'transparent',
              },
            ],
          },
        })
      }
    },
    [auth]
  )

  const setResetState = (type = null) => {
    setFilterConvert({})
    setIsTableDetail(false)
    setdataTableDetail({})
    if (type !== 'GO_BACK') {
      setDataGoback([])
    }
  }

  const setParams = useCallback(() => {
    const isSuperAdmin = isValidCondition(
      auth.permission,
      [Permission.SUPERADMIN],
      'AND'
    )
    const isLanhDaoDonVi = isValidCondition(
      auth.permission,
      [Permission.CONGVANDEN_CHIDAO],
      'AND'
    )
    const isLanhDaoPhongBan = isValidCondition(
      auth.permission,
      [Permission.CONGVIEC_GIAOVIEC],
      'AND'
    )
    let params = {
      ...filter,
      FromDate: `${filter.FromDate} 00:00:00`,
      ToDate: `${filter.ToDate} 23:59:59`,
      caNhan: null,
      phongBan: null,
      donVi: null,
      WorkTypeId: filter?.WorkTypeId === 'ALL' ? undefined : filter.WorkTypeId,
      VaiTroId: filter?.VaiTroId === 'ALL' ? undefined : filter?.VaiTroId,
      IsGiaoChoChinhMinh:
        filter.IsGiaoChoChinhMinh === 'ALL'
          ? null
          : filter.IsGiaoChoChinhMinh === '1'
          ? true
          : false,
      'SearchRequest.Id': get(auth, 'roleId'),
      'SearchRequest.Type': ASSIGN_TYPE.CA_NHAN,
    }
    if (isLanhDaoDonVi && !isSuperAdmin) {
      // Quyền lãnh đạo đơn vị
      if (get(filter, 'phongBan') && get(filter, 'phongBan') !== '0') {
        params = {
          ...params,
          'SearchRequest.Id': get(filter, 'phongBan'),
          'SearchRequest.Type': ASSIGN_TYPE.PHONG_BAN,
        }
      } else if (get(filter, 'donVi') && get(filter, 'donVi') !== '0') {
        params = {
          ...params,
          'SearchRequest.Id': get(filter, 'donVi'),
          'SearchRequest.Type': ASSIGN_TYPE.DON_VI,
        }
      } else {
        params = {
          ...params,
          'SearchRequest.Id': get(auth, 'mainUnitId'),
          'SearchRequest.Type': ASSIGN_TYPE.DON_VI,
        }
      }
    } else if (isLanhDaoPhongBan && !isSuperAdmin) {
      // Quyền lãnh đạo phòng ban
      if (get(filter, 'phongBan') && get(filter, 'phongBan') !== '0') {
        params = {
          ...params,
          'SearchRequest.Id': get(filter, 'phongBan'),
          'SearchRequest.Type': ASSIGN_TYPE.PHONG_BAN,
        }
      } else {
        params = {
          ...params,
          'SearchRequest.Id': get(auth, 'mainDepartmentId'),
          'SearchRequest.Type': ASSIGN_TYPE.PHONG_BAN,
        }
      }
    } else if (isSuperAdmin) {
      if (get(filter, 'phongBan') && get(filter, 'phongBan') !== '0') {
        params = {
          ...params,
          'SearchRequest.Id': get(filter, 'phongBan'),
          'SearchRequest.Type': ASSIGN_TYPE.PHONG_BAN,
        }
      } else if (get(filter, 'donVi') && get(filter, 'donVi') !== '0') {
        params = {
          ...params,
          'SearchRequest.Id': get(filter, 'donVi'),
          'SearchRequest.Type': ASSIGN_TYPE.DON_VI,
        }
      } else {
        params = {
          ...params,
          'SearchRequest.Id': null,
          'SearchRequest.Type': null,
        }
      }
    } else if (get(filter, 'phongBan') && get(filter, 'phongBan') !== '0') {
      params = {
        ...params,
        'SearchRequest.Id': get(filter, 'phongBan'),
        'SearchRequest.Type': ASSIGN_TYPE.PHONG_BAN,
      }
    }

    mountedSet(setFilterConvert, params)
    return params
  }, [filter, auth])

  const getThongKeDonVi = useCallback(() => {
    requestAction({
      showToast: false,
      codeCheck: 200,
      beforeAction: () => {
        setIsLoadingTable(true)
      },
      action: () => {
        const params = setParams()
        return getWorkStatistics(params)
      },
      afterResponse: ({ items = [] }) => {
        mountedSet(setDataTable, items)
        const newItem = items
        // Lấy dữ liệu cho biểu đồ tròn và nửa vòng tròn
        funcFormatChartData(newItem)
      },
      afterAction: () => {
        setIsLoadingTable(false)
      },
    })
  }, [setParams, funcFormatChartData])

  const exportExcel = () => {
    requestAction({
      showToast: false,
      getResult: false,
      codeCheck: false,
      beforeAction: () => {
        setIsLoadingButton(true)
      },
      action: () => {
        const params = setParams()
        return getWorkStatisticsExportExcel(params)
      },
      afterError: () => {
        showToast({
          message: Toast.FAIL(Action.EXPORT_EXCEL),
          intent: Intent.DANGER,
        })
      },
      afterResponse: data => {
        let fromDate = moment(filter.FromDate)
        let toDate = moment(filter.toDate)
        const formatDateExcelName = 'DD_MM_YYYY'
        if (fromDate.isValid()) {
          fromDate = fromDate.format(formatDateExcelName)
        }

        if (toDate.isValid()) {
          toDate = toDate.format(formatDateExcelName)
        }

        if (data) {
          return FileDownload.saveAs(
            data,
            `thong-ke-qlcv-${fromDate}-${toDate}.xls`
          )
        }
      },
      afterAction: () => {
        setIsLoadingButton(false)
      },
    })
  }

  const goBack = () => {
    let newFilter = { ...filter }
    let newGoback = [...dataGoback]
    if (size(newGoback)) {
      newGoback = newGoback.slice(1)
      let [goBackFilter] = newGoback
      newFilter = {
        ...newFilter,
        caNhan:
          get(goBackFilter, 'type') && get(goBackFilter, 'type') === 'caNhan'
            ? get(goBackFilter, 'value')
            : null,
        phongBan:
          get(goBackFilter, 'type') && get(goBackFilter, 'type') === 'phongBan'
            ? get(goBackFilter, 'value')
            : null,
        donVi:
          get(goBackFilter, 'type') && get(goBackFilter, 'type') === 'donVi'
            ? get(goBackFilter, 'value')
            : null,
      }
    }

    mountedSet(setDataGoback, newGoback)
    mountedSet(setFilter, newFilter)
    setResetState('GO_BACK')
  }

  const changeFilter =
    ({ multipleKey, name, isRowChange }) =>
    value => {
      let newFilter = { ...filter }
      let newGoback = [...dataGoback]
      if (name === 'RESET') {
        newFilter = {
          ...DEFAULT_FILTER,
        }
        newGoback = []
        setResetState()
      } else if (name === 'RELOADS') {
        newFilter = { ...newFilter }
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else if (name === 'donVi') {
        newFilter = {
          ...newFilter,
          phongBan: null,
          [name]: value,
        }
        getThongKePhongBan(value)
      } else {
        newFilter = { ...newFilter, [name]: value }
      }

      if (isRowChange) {
        newGoback = [{ type: name, value: value }, ...newGoback]
      }

      mountedSet(setDataGoback, newGoback)
      mountedSet(setFilter, newFilter)
    }

  const onClear = name => value => {
    mountedSet(setFilter, {
      ...filter,
      [name]: value,
      'SearchRequest.Type': null,
    })
  }

  const changeFilterDetail =
    ({ name }) =>
    value => {
      mountedSet(setdataTableDetail, {
        ...dataTableDetail,
        [name]: value,
      })
    }

  const onclickQuaHan = data => {
    const isSuperAdmin = isValidCondition(
      auth.permission,
      [Permission.SUPERADMIN],
      'AND'
    )
    const isLanhDaoDonVi = isValidCondition(
      auth.permission,
      [Permission.CONGVANDEN_CHIDAO],
      'AND'
    )
    const isLanhDaoPhongBan = isValidCondition(
      auth.permission,
      [Permission.CONGVIEC_GIAOVIEC],
      'AND'
    )
    let modelDetail = null
    if (isSuperAdmin || isLanhDaoDonVi || isLanhDaoPhongBan) {
      // Check filter WorkTypeId hoặc IsGiaoChoChinhMinh
      modelDetail = {
        WorkTypeId: filter?.WorkTypeId,
        IsGiaoChoChinhMinh: null,
      }
    } else {
      modelDetail = {
        IsGiaoChoChinhMinh: filter?.IsGiaoChoChinhMinh,
        WorkTypeId: null,
      }
    }

    modelDetail = {
      ...modelDetail,
      'SearchRequest.IsAll': null,
      'SearchRequest.Id': get(data, 'id', null),
      'SearchRequest.Type': get(data, 'type', null),
    }

    if (
      get(data, 'type') === ASSIGN_TYPE.DON_VI ||
      get(data, 'type') === ASSIGN_TYPE.PHONG_BAN
    ) {
      // Check SearchRequest.IsAll khi lick row thường
      modelDetail = {
        ...modelDetail,
        'SearchRequest.IsAll': true,
      }
    }

    if (get(data, 'id') === GUID_EMPTY || get(data, 'id') === GUID_EMPTY) {
      // Check SearchRequest.IsAll khi click row tổng cộng
      modelDetail = {
        ...modelDetail,
        'SearchRequest.IsAll': false,
        'SearchRequest.Id': get(filterConvert, 'SearchRequest.Id', null),
        'SearchRequest.Type': get(filterConvert, 'SearchRequest.Type', null),
      }
    }

    mountedSet(setIsTableDetail, true)
    mountedSet(setdataTableDetail, modelDetail)
    mountedSet(setDataGoback, [
      { type: 'worksStatus', value: get(data, 'id') },
      ...dataGoback,
    ])
  }

  // Danh sách phòng ban theo đơn vị hiện tại
  const getThongKePhongBan = useCallback(
    async mainUnitId => {
      const res = await fetchDepartments(mainUnitId, { isAll: false })
      if (get(res, 'payload.data.result.items')) {
        mountedSet(setdepartments, get(res, 'payload.data.result.items'))
      }
    },
    [fetchDepartments]
  )

  const fetchWorkTypes = useCallback(() => {
    requestAction({
      action: getWorkTypes,
    })
  }, [])

  const contextValue = {
    units: categoryUnit,
    departments,
    fetchStatus: {
      load: false,
    },
    filter,
    filterConvert,
    dataTable,
    isLoadingTable,
    dataTableDetail,
    isBigDesktop,
    changeFilter,
    onClear,
    onclickQuaHan,
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchWorkTypes()
  }, [fetchWorkTypes])

  useEffect(() => {
    getThongKeDonVi()
  }, [getThongKeDonVi])

  useEffect(() => {
    getThongKePhongBan(get(auth, 'mainUnitId'))
  }, [getThongKePhongBan, auth])

  useEffect(() => {
    if (isValidCondition(auth.permission, [Permission.SUPERADMIN], 'AND')) {
      getCategoryUnit()
    }
  }, [auth, getCategoryUnit])

  useEffect(() => {
    if (window && window.innerWidth && window.innerWidth > 1599) {
      setIsBigDesktop(true)
    }
  }, [])

  const isSuperAdmin = isValidCondition(
    auth.permission,
    [Permission.SUPERADMIN],
    'AND'
  )
  const isLanhDaoDonVi = isValidCondition(
    auth.permission,
    [Permission.CONGVANDEN_CHIDAO],
    'AND'
  )
  const isLanhDaoPhongBan = isValidCondition(
    auth.permission,
    [Permission.CONGVIEC_GIAOVIEC],
    'AND'
  )

  return (
    <MasterLayout typeSidebar="taskManager" newLayout>
      <StatisticContext.Provider value={contextValue}>
        <div
          className={classnames('', {
            'layout-big-desktop': isBigDesktop,
          })}
          style={{
            flex: 1,
            paddingTop: 40,
            paddingRight: isBigDesktop ? 80 : 40,
            paddingBottom: 40,
            paddingLeft: isBigDesktop ? 80 : 40,
            position: 'relative',
            height: window.innerHeight - 55,
            overflow: 'auto',
          }}
        >
          <div style={{ position: 'relative' }}>
            <header className="d-flex mb20">
              <b
                className={classnames('font-size-20 font-weight-600', {
                  'color-light': get(
                    allCookies,
                    'background_image.is_light',
                    false
                  ),
                })}
                style={{ marginRight: 'auto' }}
              >
                {PAGE_NAME}
              </b>
              <span style={{ marginLeft: 'auto' }}>
                <BackgroundSetting />
              </span>
            </header>
            <StatisticFilter auth={auth} />
            <div className="layout-chart mb30">
              <div className="layout-chart--col">
                <div className="layout-chart--box">
                  <h2 className="title-chart">Tỉ lệ công việc</h2>
                  <ThongKeTiLeCongViec
                    dataDoughnut={get(dataDoughnut, 'tiLeCongViec')}
                    colors={colors}
                  />
                </div>
              </div>
              <div className="layout-chart--col">
                <div className="layout-chart--box">
                  <h2 className="title-chart">Tỉ lệ đúng hạn</h2>
                  <ThongKeTiLeCongViecHalf
                    dataDoughnut={get(dataDoughnut, 'tiLeDungHan')}
                    colors={colors}
                  />
                </div>
              </div>
              <div className="layout-chart--col">
                <div className="layout-chart--box">
                  <h2 className="title-chart">Tỉ lệ hoàn thành</h2>
                  <ThongKeTiLeCongViecHalf
                    dataDoughnut={get(dataDoughnut, 'tiLeHoanThanh')}
                    colors={colors}
                  />
                </div>
              </div>
            </div>
            <div>
              <header className="d-flex flex-wrap mb20">
                <div
                  style={{
                    marginRight: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {size(dataGoback) !== 0 && (
                    <Button
                      className={classnames('cpc-button default-back', 'mr10')}
                      onClick={goBack}
                      loading={false}
                      disabled={isLoadingButton}
                    >
                      <Icon classIcon={'pt-icon pt-icon-chevron-left'} />
                      <span>Quay lại</span>
                    </Button>
                  )}
                  <b
                    className={classnames('font-size-20 font-weight-600', {
                      'color-light': get(
                        allCookies,
                        'background_image.is_light',
                        false
                      ),
                    })}
                  >
                    Danh sách thống kê
                  </b>
                </div>
                <div className="d-flex">
                  <div className="d-flex align-center">
                    <div className="d-flex align-center mr15">
                      <Select
                        dataSource={roleData}
                        inputIconClassName="icon2-documents"
                        onChange={
                          !isTableDetail
                            ? changeFilter({
                                name: 'VaiTroId',
                              })
                            : changeFilterDetail({
                                name: 'VaiTroId',
                              })
                        }
                        placeholder="Tất cả"
                        wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
                        inputStyle={{ height: '30px', lineHeight: '30px' }}
                        value={
                          !isTableDetail
                            ? filter?.VaiTroId
                            : dataTableDetail?.VaiTroId
                        }
                        isShowArrowDropdown
                        inputWrapperClassName={'border-radius-3 input-not-ph'}
                      />
                    </div>
                    <div className="d-flex align-center">
                      <Select
                        dataSource={
                          isSuperAdmin || isLanhDaoDonVi || isLanhDaoPhongBan
                            ? [
                                { label: 'Tất cả nguồn', value: 'ALL' },
                                ...(listWorkTypes?.map(e => ({
                                  label: e?.name,
                                  value: e?.keyName,
                                })) || []),
                              ]
                            : personalSourceOfWork
                        }
                        inputIconClassName="icon2-documents"
                        onChange={
                          !isTableDetail
                            ? changeFilter({
                                name:
                                  isSuperAdmin ||
                                  isLanhDaoDonVi ||
                                  isLanhDaoPhongBan
                                    ? 'WorkTypeId'
                                    : 'IsGiaoChoChinhMinh',
                              })
                            : changeFilterDetail({
                                name:
                                  isSuperAdmin ||
                                  isLanhDaoDonVi ||
                                  isLanhDaoPhongBan
                                    ? 'WorkTypeId'
                                    : 'IsGiaoChoChinhMinh',
                              })
                        }
                        placeholder="Tất cả"
                        wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
                        inputStyle={{ height: '30px', lineHeight: '30px' }}
                        value={
                          !isTableDetail &&
                          (isSuperAdmin || isLanhDaoDonVi || isLanhDaoPhongBan)
                            ? get(filter, 'WorkTypeId', '0')
                            : isTableDetail &&
                              (isSuperAdmin ||
                                isLanhDaoDonVi ||
                                isLanhDaoPhongBan)
                            ? get(dataTableDetail, 'WorkTypeId', '0')
                            : !isTableDetail &&
                              !isSuperAdmin &&
                              !isLanhDaoDonVi &&
                              !isLanhDaoPhongBan
                            ? get(filter, 'IsGiaoChoChinhMinh', '0')
                            : isTableDetail &&
                              !isSuperAdmin &&
                              !isLanhDaoDonVi &&
                              !isLanhDaoPhongBan
                            ? get(dataTableDetail, 'IsGiaoChoChinhMinh', '0')
                            : null
                        }
                        isShowArrowDropdown
                        inputWrapperClassName={'border-radius-3 input-not-ph'}
                      />
                    </div>
                  </div>
                  {!isTableDetail && (
                    <Button
                      className={classnames(
                        'cpc-button green',
                        'color-white',
                        'ml10'
                      )}
                      onClick={exportExcel}
                      loading={isLoadingButton}
                      disabled={isLoadingButton}
                    >
                      <Icon classIcon={'icon2-download'} />
                      <span className="ml10">Xuất Excel</span>
                    </Button>
                  )}
                </div>
              </header>
              {isTableDetail ? (
                <ThongKeCongViecDanhSach />
              ) : (
                <ThongKeCongViec />
              )}
            </div>
          </div>
        </div>
      </StatisticContext.Provider>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  categoryUnit: get(state, 'category.categoryUnit'),
  auth: get(state, 'auth'),
  listWorkTypes: state?.TaskManager?.listWorkTypes,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchDepartments,
      getCategoryUnit,
    },
    dispatch
  ),
})

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(memo(Statistic))
)
