import React, { memo, useRef, useState, useEffect, Children } from 'react'
import { get, isEmpty } from 'lodash'
import csx from 'classnames'
import { Button } from '@blueprintjs/core'

import { Collapse } from './index'

const CollapseMore = ({
  children,
  textShow = '',
  textHide = '',
  limitDisplay = 1,
  buttonSpace = true,
  defaultOpen = false,
  className,
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const childShow = Children.toArray(children).slice(0, limitDisplay)
  const childCollapse = Children.toArray(children).slice(limitDisplay)

  const [showCollapse, setShowCollapse] = useState(defaultOpen)

  const toggleCollapse = () => {
    mountedSet(setShowCollapse, !showCollapse)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <>
      {childShow}

      {!isEmpty(childCollapse) && (
        <>
          <Collapse isOpen={showCollapse}>{childCollapse}</Collapse>

          <Button
            className={csx(
              'cpc-button-collapse-more',
              'w-100',
              'font-size-11',
              { mt10: buttonSpace, [className]: className }
            )}
            onClick={toggleCollapse}
          >
            {(showCollapse
              ? [...(isEmpty(textHide) ? [] : [textHide])]
              : [...(isEmpty(textShow) ? [] : [textShow])]
            ).join(' ')}
            <i
              className={csx(
                {
                  'icon-arrow-dropup': showCollapse,
                  'icon-arrow-dropdown': !showCollapse,
                },
                'ml5',
                'size-icon-6'
              )}
            />
          </Button>
        </>
      )}
    </>
  )
}

export default memo(CollapseMore)
