import { ApprovalDefined, Status } from '../constants/Signature'

import React from 'react'
import _ from 'lodash'
import { decamelize } from 'humps'
import moment from 'moment'
import store from 'local-storage'

export default class Utils {
  static linkObjectKey(obj) {
    let params = {}

    for (var v in obj) {
      if (typeof obj[v] !== 'object' || Array.isArray(obj[v])) {
        params[decamelize(v)] = obj[v]
        continue
      }

      for (var v1 in obj[v]) {
        if (typeof obj[v][v1] !== 'object' || Array.isArray(obj[v][v1])) {
          params[`${decamelize(v)}.${decamelize(v1)}`] = obj[v][v1]
          continue
        }

        for (var v2 in obj[v][v1]) {
          params[`${decamelize(v)}.${decamelize(v1)}.${decamelize(v2)}`] =
            obj[v][v1][v2]
        }
      }
    }

    return params
  }

  static getFileSize(size) {
    if (isNaN(size)) {
      return
    }

    let fileLength = ''
    if (size < 1000 * 1024) {
      fileLength = (size / 1024).toFixed(2) + ' kB'
    } else if (1000 * 1024 <= size && size <= 1024 * 1024) {
      fileLength = '1 MB'
    } else {
      fileLength = (size / 1024 / 1024).toFixed(2) + ' MB'
    }
    return fileLength
  }

  static getFileLogo(file) {
    switch (file.kieuFile) {
      case 'application/pdf':
        return `${process.env.PUBLIC_URL}/images/pdf.png`
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return `${process.env.PUBLIC_URL}/images/doc.png`
      case 'application/msword':
        return `${process.env.PUBLIC_URL}/images/doc.png`
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return `${process.env.PUBLIC_URL}/images/xls.png`
      case 'application/vnd.ms-excel':
        return `${process.env.PUBLIC_URL}/images/xls.png`
      default:
        return `${process.env.PUBLIC_URL} images/file.png`
    }
  }

  static getIconKieuFile(kieuFile) {
    switch (kieuFile) {
      case 'application/octet-stream':
      case 'application/pdf':
      case '.pdf':
        return `${process.env.PUBLIC_URL}/images/pdf_file.svg`
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case '.doc':
      case '.docx':
        return `${process.env.PUBLIC_URL}/images/doc_file.svg`
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case '.xls':
      case '.xlsx':
        return `${process.env.PUBLIC_URL}/images/excel_file.svg`
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case '.ppt':
      case '.pptx':
      case '.ppsx':
      case '.pps':
        return `${process.env.PUBLIC_URL}/images/powerpoint_file.svg`
      case 'image/png':
      case '.png':
        return `${process.env.PUBLIC_URL}/images/png_file.svg`
      case 'image/jpeg':
      case '.jpg':
      case '.jpeg':
        return `${process.env.PUBLIC_URL}/images/jpg_file.svg`
      default:
        return `${process.env.PUBLIC_URL}/images/file-unknow.svg`
    }
  }

  static typeFile(extension = '') {
    let values = extension
    if (extension) {
      values = extension?.toLowerCase()
    }

    switch (values) {
      case 'doc':
      case 'docx':
        return `${process.env.PUBLIC_URL}/images/doc_file.svg`
      case 'pdf':
        return `${process.env.PUBLIC_URL}/images/pdf_file.svg`
      case 'xls':
      case 'xlsx':
        return `${process.env.PUBLIC_URL}/images/excel_file.svg`
      case 'ppt':
      case 'pptx':
      case 'ppsx':
      case 'pps':
        return `${process.env.PUBLIC_URL}/images/powerpoint_file.svg`
      case 'zip':
        return `${process.env.PUBLIC_URL}/images/zip.svg`
      case 'png':
        return `${process.env.PUBLIC_URL}/images/png_file.svg`
      case 'jpeg':
      case 'jpg':
        return `${process.env.PUBLIC_URL}/images/jpg_file.svg`
      default:
        return `${process.env.PUBLIC_URL}/images/file-unknow.svg`
    }
  }

  static removeData(name) {
    store.remove(name)
  }

  static saveData(name, data) {
    return store.set(name, data)
  }

  static getData(name, data = null) {
    let lastData

    try {
      lastData = store.get(name)
    } catch (e) {
      lastData = undefined
    }

    if (!lastData || lastData === 'undefined') {
      return data
    }

    return lastData
  }

  static preData(
    name,
    data,
    findNames = [
      'chi-tiet',
      'chinh-sua',
      'giao-viec-moi',
      'luu-tu-tai-lieu',
      'chi-dao',
      'thong-ke',
    ]
  ) {
    let resData = data
    if (typeof findNames === 'string') {
      findNames = [findNames]
    }

    if (Array.isArray(findNames)) {
      for (let i = 0; i <= findNames.length; i++) {
        if (
          window.previousLocation &&
          window.previousLocation.pathname.indexOf(findNames[i]) !== -1
        ) {
          resData = Utils.getData(name, data)
          break
        }
      }
    }

    const isReloadPage = Utils.getData('isReloadPage', false)
    if (isReloadPage) {
      resData = Utils.getData(name, data)
    }

    Utils.saveData(name, data)

    return resData
  }

  static isValidDate = (value, optional = {}) => {
    const reg =
      /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g
    let isValid = reg.test(value)

    if (optional.before && optional.before.date) {
      if (
        moment(optional.before.date, 'DD/MM/YYYY').isBefore(
          moment(value, 'DD/MM/YYYY')
        )
      ) {
        isValid = false
      }
    }

    if (optional.after && optional.after.date) {
      if (
        moment(optional.after.date, 'DD/MM/YYYY').isAfter(
          moment(value, 'DD/MM/YYYY')
        )
      ) {
        isValid = false
      }
    }

    return isValid
  }

  static blobToFile(blob, name) {
    blob.lastModifiedDate = new Date()
    blob.name = name
    return blob
  }

  static getExtensionFile = fileName => {
    let extension = null
    if (fileName) {
      extension = fileName
        .slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    }

    return extension
  }

  static convertDataToNestedArray = (data, fieldNameConvert) => {
    let newArray = []
    data &&
      data.forEach((item, i) => {
        let index = item[fieldNameConvert]
        if (_.isNumber(index) && index > 0) {
          if (!newArray[index - 1]) {
            newArray[index - 1] = []
          }
          newArray[index - 1].push(item)
        }
      })
    return _.compact(newArray)
  }

  static getArrayResultByKeyFilter = (
    originArray = [], // array ban đầu
    keyFilter = '', // tên field để lọc
    valueFilter = '', // giá trị cần lọc
    keyResult = '', // tên field cần lấy
    keyConvertResult = null // tên field dùng để thay đổi kết quả
  ) => {
    let resultArray = []
    originArray &&
      originArray.forEach(item => {
        if (
          item[keyFilter] &&
          item[keyFilter] === valueFilter &&
          item[keyResult]
        ) {
          if (keyConvertResult) {
            let tmp = `*${keyConvertResult}-${item[keyConvertResult]}*-${item[keyResult]}`
            resultArray.push(tmp)
          } else {
            resultArray.push(item[keyResult])
          }
        }
      })
    return _.uniq(resultArray)
  }

  static getLabelStatus = (
    item = {},
    type = 'cong-van-di',
    name = 'tinhTrangHienThi'
  ) => {
    if (typeof item !== 'object') {
      return ''
    }
    const tinhTrang = item[name]
    const status = _.findKey(ApprovalDefined, o => o.includes(tinhTrang))
    let label = Status.kySoTT[tinhTrang] || null

    if (type === 'cong-van-di' && Status.special[tinhTrang] != null) {
      label = Status.special[tinhTrang]
    }

    return <span className={`status-job ${status}`}>{`${label}`}</span>
  }

  static convertFileResponse = file =>
    Utils.toDecamelizeKeys({
      fileId: file.id,
      tenFile: file.originalName,
      url: file.path,
      kieuFile: file.extension,
      kichThuoc: file.length,
      isExisted: false,
    })

  static convertFileOld = file =>
    Utils.toDecamelizeKeys({
      fileId: file.fileId,
      tenFile: file.fileName,
      url: file.url,
      kieuFile: file.kieuFile,
      kichThuoc: file.kichThuoc,
      isExisted: true,
    })

  static toDecamelizeKeys = (object, exceptKeys = []) => {
    let newObject = object
    if (_.isPlainObject(object)) {
      newObject = {}
      _.toPairs(object).forEach(([key, value]) => {
        let newValue = value
        if (_.isPlainObject(value)) {
          newValue = Utils.toDecamelizeKeys(value)
        }
        if (_.isArray(value)) {
          newValue = value.map(item => Utils.toDecamelizeKeys(item))
        }
        if (!exceptKeys.includes(key)) {
          newObject[decamelize(key)] = newValue
        } else {
          newObject[key] = newValue
        }
      })
    }
    if (_.isArray(object)) {
      newObject = []
      newObject = object.map(item => Utils.toDecamelizeKeys(item))
    }
    return newObject
  }

  // Kiểm tra phòng ban chính có thuộc ban tổng hợp không?
  // TODO: Hiện tại kiểm tra theo id thuộc ban tổng hợp ở staging và live
  static checkBanTongHop = mainDepartmentId => {
    if (!mainDepartmentId) {
      return false
    }

    const idBanTongHop = '52ed65b0-54b8-4d35-bf63-37495012c29a'
    if (mainDepartmentId === idBanTongHop) {
      return true
    }

    return false
  }
}
