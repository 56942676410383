import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import Recaptcha from 'react-recaptcha'

import {
  GOOGLE_DOMAIN,
  SECRET_KEY,
  SITE_KEY,
} from 'constants/Api'
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      countError: 0,
      isVerified: undefined,
      reReCaptcha: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAuth = this.handleAuth.bind(this)
    this.handlePermission = this.handlePermission.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  componentWillMount = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.token
    ) {
      const { token, type, roleId } = this.props.location.state
      this.props.actions.authLogout()
      this.props.actions.setTokenWithQuery(token)

      this.handleAuth({})
        .then(async res => {
          if (!roleId) {
            return this.handlePermission(res, type)
          }

          await this.props.actions.switchRoleUser({ roleId })
          await this.props.actions.userPermission(roleId)
        })
        .then(() => {
          let loginDirect = '/quan-ly-cong-viec/toi-giao'
          let typeSidebar = 'taskManager'
          this.props.actions.commonSaveTypeSidebar(typeSidebar)
          return this.props.history.push(loginDirect)
        })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: '',
    })
  }

  handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    const { reReCaptcha } = this.state
    this.setState({ isLoading: true }, () => {
      this.props.actions
        .authLogin({
          username: this.state.username,
          password: this.state.password,
          r: reReCaptcha ? reReCaptcha : null,
        })
        .then(this.handleAuth)
    })
  }

  handleAuth = async res => {
    let countError = this.state.countError
    if (res.error) {
      let msg = 'Đăng nhập không thành công, vui lòng thử lại'
      if (
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.error === 'invalid_grant'
      ) {
        if (this.state.isVerified) {
          this.resetRecaptcha()
        }
        countError++
        msg = 'Tài khoản đăng nhập hoặc mật khẩu không đúng'
      }
      if (res.error.data === 'Network Error') {
        msg = 'Mất kết nối internet, vui lòng thử lại'
      }
      return this.setState({ error: msg, isLoading: false, countError })
    }

    return await this.props.actions.userAuthenticated().then(res => {
      if (res.error || !res.payload.data.result.result) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }
      this.handlePermission(res)
    })
  }

  async handlePermission(res, type) {
    if (
      !res ||
      res.error ||
      !res.payload.data ||
      !res.payload.data.result ||
      !res.payload.data.entities.users
    ) {
      return this.setState({
        isLoading: false,
        error: 'Đăng nhập không thành công, vui lòng thử lại',
      })
    }

    const user = res.payload.data.entities.users[res.payload.data.result.result]
    const defaultApartmend = user.dsChucDanh.find(
      item => item.phongBanChinh === true
    )
    const actions = this.props.actions
    actions.commonFetchPriorities()
    actions.commonFetchSecurities()
    actions.commonFetchObjects()
    actions.commonFetchDepartmentsLeader()
    await actions.userPermission(defaultApartmend.id).then(async resPers => {
      if (resPers.error) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }

      let loginDirect = '/quan-ly-cong-viec/toi-giao'
      let typeSidebar = 'taskManager'

      !type && actions.commonSaveTypeSidebar(typeSidebar)
      !type && this.props.history.push(loginDirect)
    })
  }

  resetRecaptcha() {
    this.recaptcha.reset()
  }

  recaptchaLoaded() {}

  verifyCallback = response => {
    if (response) {
      const formData = `secret=${SECRET_KEY}&response=${response}`
      return fetch(GOOGLE_DOMAIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
        body: formData,
      })
        .then(response => response.json())
        .then(res => {
          if (res && res.success) {
            this.setState(
              {
                isVerified: true,
                reReCaptcha: formData,
              },
              () => {
                this.handleSubmit()
              }
            )
          } else {
            this.resetRecaptcha()
          }
        })
    }
  }

  render() {
    return (
      <form className="cpc-login-form" onSubmit={this.handleSubmit}>
        <h2 className="main-title">Thông tin đăng nhập</h2>
        <div className="pt-input-group pt-large">
          <span className="pt-icon icon-profile"></span>
          <div className="cpc-control-group">
            <label htmlFor="username">Tài khoản đăng nhập</label>
            <input
              type="text"
              className="pt-input cpc-input-username cpc-form-control"
              id="username"
              name="username"
              onChange={this.handleChange}
              placeholder="Nhập tên đăng nhập"
              autoFocus={true}
            />
          </div>
        </div>
        <div className="pt-input-group pt-large">
          <span className="pt-icon icon-lock"></span>
          <div className="cpc-control-group">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              className="pt-input cpc-input-password cpc-form-control"
              id="password"
              name="password"
              onChange={this.handleChange}
              placeholder="Nhập mật khẩu"
            />
            {this.state.error && (
              <div className="pt-input-helper-text error">
                {this.state.error}
              </div>
            )}
          </div>
        </div>
        {this.state.countError >= 5 && (
          <div className="recaptcha">
            <Recaptcha
              ref={e => (this.recaptcha = e)}
              sitekey={SITE_KEY}
              render="explicit"
              size={document.body.offsetWidth >= 360 ? 'normal' : 'compact'}
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.verifyCallback}
              hl="vi"
            />
          </div>
        )}
        <div className="container-remember-login">
          <Button
            type="submit"
            className="pt-button pt-large pt-intent-primary"
            disabled={
              !this.state.username.trim() ||
              !this.state.password.trim() ||
              this.state.countError >= 5
            }
            loading={this.state.isLoading}
          >
            Đăng nhập
          </Button>
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  actions: PropTypes.object.isRequired,
}

export default withRouter(LoginForm)
