const VERSION = process.env.REACT_APP_VERSION || '7.1.0'

// Default pagination
const PAGINATION_LIMIT = 10
const PAGE = 1
const LIMIT_ZERO = 0
// Max size file: 25mb
const MAX_SIZE_FILE = 1024 * 1024 * 25
// Max size file: 100mb
const MAX_SIZE_FILE_SUBMISSION = 1024 * 1024 * 100

// Receive document status
const DOCUMENT_RECEIVE_NO_PROCESS = 0
const DOCUMENT_RECEIVE_PROCESSING = 1

// Receive docyment type
const DOCUMENT_RECEIVE_MANULLY = 0
const DOCUMENT_RECEIVE_DIGITAL = 1
const DOCUMENT_RECEIVE_EVN = 2

// Type digital documents
const DIGITAL_RECEIVE_DOCUMENT = 1
const DIGITAL_SEND_DOCUMENT = 2

// Department work
const DEPARTMENT_WORK_NOT_ASSIGN = 0

// Personal work
const PERSONAL_WORK_NO_PROCESS = 0
const PERSONAL_WORK_PROCESSING = 1
const PERSONAL_WORK_DONE = 2

// Document cabinet
const CABINET_UNIT = '1'
const CABINET_DEPARTMENT = '2'
const CABINET_PERSONAL = '3'
const HOSO_CONGVIEC = '4'

// SaveCodes Type
const SAVECODE_RECEIVE = 1
const SAVECODE_SEND = 2

// Alert mistake
const ALERT_MISTAKE_DOCUMENT = 1
const ALERT_MISTAKE_WORK = 2

// SignNumber type composite department
const TYPE_DEPARTMENT = null
const TYPE_LEADER_DEPARTMENT = true
const TYPE_COMMON_DEPARTMENT = false

// SignNumber type-sign
const TYPE_SIGN_NUMBER_AGREE = 3

// SignNumber status
// Đã đồng ý
const AGREED = 2

// Chưa duyệt - chưa đồng ý
const NOT_YET_AGREE = 0

// Không duyệt - không đồng ý
const DISAGREE = 1

// Bảo luư
const REVERSE = 3
// LDPB CT đã duyệt
const LEADER_DEPARTMENT = 2

// LDPB PH đã duyệt
const COMPOSITIVE_DEPARTMENT = 3

// TK LDDV đã duyệt
const SECRETARY = 4

// LDDV đã duyệt
const LEADER_UNIT = 5

// Đã cấp số
const PROGRESSION = 6

// Đã phát hành
const RELEASED = 7

// LDPB PH cấp trên đã duyệt
const PARENT_COMPOSITIVE_DEPARTMENT = 8

// TK LD DV cấp trên đã duyệt
const PARENT_SECRETARY = 9

// TK LD DV khác cùng ký đã duyệt
const OTHER_SECRETARY = 8

// LD DV khác cùng ký đã duyệt
const OTHER_LEADER_UNIT = 9

// LD phong ban lanh dao phoi hop da duyet
const COMPOSITIVE_LEADER_DEPARTMENT = 10

// Ký số dự án:
// Đã duyệt
const PASSED = 6

// Ký số: hình thức ký
const HTK_KY_NHAY = 0
const HTK_KY_KHONG_DAU = 1
const HTK_KY_CO_DAU = 2
const HTK_CAP_SO = 3
const HTK_KY_NHAY_CO_CHU_KY = 4
const HTK_KY_THANH_VIEN = 5

const SIGN_WAYS = [
  { hinhThuc: HTK_KY_NHAY, tenHinhThuc: 'Ký nháy' },
  { hinhThuc: HTK_KY_KHONG_DAU, tenHinhThuc: 'Ký không dấu' },
  { hinhThuc: HTK_KY_CO_DAU, tenHinhThuc: 'Ký có dấu' },
  { hinhThuc: HTK_CAP_SO, tenHinhThuc: 'Cấp số' },
  { hinhThuc: HTK_KY_NHAY_CO_CHU_KY, tenHinhThuc: 'Ký nháy có chữ ký' },
  {
    hinhThuc: HTK_KY_THANH_VIEN,
    tenHinhThuc: 'Ký biên bản đấu thầu, thẩm định',
  },
]

// ký số tập trung hình thức
const KSTT = {
  XAC_NHAN: 0,
  KHONG_HIEN_THI: 1,
  KY_KHONG_DAU: 2,
  KY_CO_DAU: 3,
  KY_NHAY: 4,
  CAP_SO: 6,
}

// trạng thái công văn từ EVN
const STATUS_EVN_DOC = [
  { value: 0, name: 'Chưa nhận' },
  { value: 3, name: 'Đã nhận' },
]

// Trạng thái hồ sơ công việc
const STATUS_HSCV = {
  CHUA_NOP: 0,
  LDPB_CHO_DUYET: 1,
  LDPB_DA_DUYET: 2,
  VT_DA_TIEP_NHAN: 3,
  LDVP_DA_DUYET: 4,
  TRA_LAI: 5,
}

const STATUS_ALL_EVN_DOC = '2'
// const SIGN_WAYS = [];

// Tên field xác định trình tự ký
const SIGN_NUMBER_PROJECT_FIELD_FILTER = 'buoc'
// LDPBPH dang duyet
const COMPOSITIVE_DEPARTMENT_SIGNING = 20

// LDPBPH cap tren dang duyet
const PARENT_COMPOSITIVE_DEPARTMENT_SIGNING = 21

// LD DL PH dang duyet
const COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING = 30

// LD DL PH dang duyet
const COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT = 31

// Type leader
const TYPE_LEADER_UNIT = 1
const TYPE_PARENT_LEADER_UNIT = 2
const TYPE_OTHER_LEADER_UNIT = 3

const TYPE_ALL_SIGN_NUMBER = 0
const TYPE_SEND_SIGN_NUMER = 1
const TYPE_INTERNAL_SIGN_NUMER = 2

const TYPE_STATISTIC_SIGN_NUMER_UNITS = 1
const TYPE_STATISTIC_SIGN_NUMER_DEPARTMENTS = 2
const TYPE_STATISTIC_SIGN_NUMER_PERSONAL = 3
const TYPE_STATISTIC_SIGN_NUMER_PERSONAL_IN_CHILD_UNITS = 4
// Thong Ke Tin Nhan
const TYPE_STATISTIC_MESSENGER = 1
const TYPE_STATISTIC_NOT_MESSENGER = 2

const acceptMIMETypes = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/pdf',
  'application/x-pdf',
  'application/acrobat',
  'applications/vnd.pdf',
  'text/pdf',
  'text/x-pdf',
  'application/x-rar-compressed',
  'application/zip',
  'image/jpeg',
  'image/jpg',
  'application/jpg',
  'application/x-jpg',
  'image/pjpeg',
  'image/pipeg',
  'image/vnd.swiftview-jpeg',
  'image/x-xbitmap',
  'image/gif',
  'image/bmp',
  'image/x-windows-bmp',
  'image/png',
  'application/mspowerpoint',
  'application/powerpoint',
  'application/vnd.ms-powerpoint',
  'application/x-mspowerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'audio/3gpp',
  'video/3gpp',
  'video/mp4',
  'video/x-flv',
  'image/svg+xml',
  'application/visio',
  'application/x-visio',
  'application/vnd.visio',
  'application/visio.drawing',
  'application/vsd',
  'application/x-vsd',
  'image/x-vsd',
  'zz-application/zz-winassoc-vsd',
  'application/vnd.ms-project',
  'application/msproj',
  'application/msproject',
  'application/x-msproject',
  'application/x-ms-project',
  'application/x-dos_ms_project',
  'application/mpp',
  'zz-application/zz-winassoc-mpp',
  'application/rtf',
  'application/x-rtf',
  'text/rtf',
  'text/richtext',
  'application/msword',
  'application/doc',
  'application/x-soffice',
  'text/plain',
  'application/txt',
  'browser/internal',
  'text/anytext',
  'widetext/plain',
  'widetext/paragraph',
  'application/tar',
  'application/x-tar',
  'applicaton/x-gtar',
  'multipart/x-tar',
  'application/x-compress',
  'application/x-compressed',
  'audio/mpeg',
  'audio/x-mpeg',
  'audio/mp3',
  'audio/x-mp3',
  'audio/mpeg3',
  'audio/x-mpeg3',
  'audio/mpg',
  'audio/x-mpg',
  'audio/x-mpegaudio',
  'audio/wav',
  'audio/x-wav',
  'audio/wave',
  'audio/x-pn-wav',
].join(',')

let QLCV_API_URL
let QLCV_FILE_URL
let QLCV_NOTIFICATION
let KSTT_API_URL
let AUTH_API_URL
let CHAT_API_URL
let API_URL
let FILE_URL
let UPLOAD_API
let FE_CHAT_URL
let IMAGE_URL
let OLD_DOCUMENT_URL
let FILE_INFO_URL
let MAINTENANCE_URL
let API_CONNECTED_UNIT_API
let API_XAC_THUC_DANG_NHAP
let GOOGLE_DOMAIN
let SITE_KEY
let SECRET_KEY
let FILE_SERVER_URL
let DO_API
let DO_DOWNLOAD_FILE
let IMAGE_URL_DO
let DO_URL

AUTH_API_URL = 'https://sso-darsitec.dn.greenglobal.vn'
CHAT_API_URL = 'https://api-chat-darsitec.dn.greenglobal.vn'
API_URL = 'https://api-darsitec.dn.greenglobal.vn'
FILE_URL = 'https://api-file-darsitec.dn.greenglobal.vn'
UPLOAD_API = 'https://api-file-darsitec.dn.greenglobal.vn'
FE_CHAT_URL = 'https://chat-darsitec.dn.greenglobal.vn'
IMAGE_URL = 'https://api-file-darsitec.dn.greenglobal.vn'
FILE_INFO_URL = 'https://api-file-darsitec.dn.greenglobal.vn'
API_CONNECTED_UNIT_API = ''
QLCV_API_URL = 'https://api-tasks-darsitec.dn.greenglobal.vn'
QLCV_FILE_URL = 'https://api-file-darsitec.dn.greenglobal.vn'
QLCV_NOTIFICATION = 'https://api-push-darsitec.dn.greenglobal.vn'
API_XAC_THUC_DANG_NHAP = ''
SITE_KEY = null
SECRET_KEY = null

export {
  API_CONNECTED_UNIT_API,
  VERSION,
  PAGINATION_LIMIT,
  PAGE,
  LIMIT_ZERO,
  MAX_SIZE_FILE,
  MAX_SIZE_FILE_SUBMISSION,
  DOCUMENT_RECEIVE_NO_PROCESS,
  DOCUMENT_RECEIVE_PROCESSING,
  DOCUMENT_RECEIVE_MANULLY,
  DOCUMENT_RECEIVE_DIGITAL,
  DOCUMENT_RECEIVE_EVN,
  DIGITAL_RECEIVE_DOCUMENT,
  DIGITAL_SEND_DOCUMENT,
  DEPARTMENT_WORK_NOT_ASSIGN,
  PERSONAL_WORK_NO_PROCESS,
  PERSONAL_WORK_PROCESSING,
  PERSONAL_WORK_DONE,
  CABINET_UNIT,
  CABINET_DEPARTMENT,
  CABINET_PERSONAL,
  SAVECODE_RECEIVE,
  SAVECODE_SEND,
  ALERT_MISTAKE_DOCUMENT,
  ALERT_MISTAKE_WORK,
  TYPE_SIGN_NUMBER_AGREE,
  AGREED,
  NOT_YET_AGREE,
  DISAGREE,
  REVERSE,
  LEADER_DEPARTMENT,
  COMPOSITIVE_DEPARTMENT,
  SECRETARY,
  LEADER_UNIT,
  PROGRESSION,
  RELEASED,
  PARENT_COMPOSITIVE_DEPARTMENT,
  PARENT_SECRETARY,
  OTHER_SECRETARY,
  OTHER_LEADER_UNIT,
  AUTH_API_URL,
  CHAT_API_URL,
  API_URL,
  FILE_URL,
  UPLOAD_API,
  FE_CHAT_URL,
  IMAGE_URL,
  OLD_DOCUMENT_URL,
  FILE_INFO_URL,
  TYPE_DEPARTMENT,
  TYPE_LEADER_DEPARTMENT,
  TYPE_COMMON_DEPARTMENT,
  COMPOSITIVE_LEADER_DEPARTMENT,
  PASSED,
  HTK_KY_NHAY,
  HTK_KY_KHONG_DAU,
  HTK_KY_CO_DAU,
  HTK_CAP_SO,
  HTK_KY_NHAY_CO_CHU_KY,
  HTK_KY_THANH_VIEN,
  SIGN_WAYS,
  STATUS_EVN_DOC,
  STATUS_ALL_EVN_DOC,
  SIGN_NUMBER_PROJECT_FIELD_FILTER,
  COMPOSITIVE_DEPARTMENT_SIGNING,
  PARENT_COMPOSITIVE_DEPARTMENT_SIGNING,
  COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING,
  COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT,
  TYPE_LEADER_UNIT,
  TYPE_PARENT_LEADER_UNIT,
  TYPE_OTHER_LEADER_UNIT,
  TYPE_ALL_SIGN_NUMBER,
  TYPE_SEND_SIGN_NUMER,
  TYPE_INTERNAL_SIGN_NUMER,
  TYPE_STATISTIC_SIGN_NUMER_UNITS,
  TYPE_STATISTIC_SIGN_NUMER_DEPARTMENTS,
  TYPE_STATISTIC_SIGN_NUMER_PERSONAL,
  TYPE_STATISTIC_SIGN_NUMER_PERSONAL_IN_CHILD_UNITS,
  TYPE_STATISTIC_MESSENGER,
  TYPE_STATISTIC_NOT_MESSENGER,
  acceptMIMETypes,
  MAINTENANCE_URL,
  KSTT_API_URL,
  KSTT,
  API_XAC_THUC_DANG_NHAP,
  HOSO_CONGVIEC,
  STATUS_HSCV,
  QLCV_API_URL,
  QLCV_FILE_URL,
  QLCV_NOTIFICATION,
  GOOGLE_DOMAIN,
  SITE_KEY,
  SECRET_KEY,
  FILE_SERVER_URL,
  DO_API,
  DO_DOWNLOAD_FILE,
  IMAGE_URL_DO,
  DO_URL,
}
