import PropTypes from 'prop-types'
import React from 'react'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    if (!this?.props?.allCookies?.authState?.token?.isEmbed) {
      this.props.history.push('/quan-ly-cong-viec/toi-giao')
    }
  }

  render() {
    return (
      <div></div>
    )
  }
}

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {},
    dispatch
  ),
})

export default withCookies(
  connect(null, mapDispatchToProps)(HomePage)
)
