import React, { memo, useContext } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { Tooltip } from '@blueprintjs/core'

import {
  ResetButton,
  SearchInput,
  CheckboxSelect,
  Select,
} from 'components/newCommon'
import { DateRangePicker } from 'components/newCommon2'
import { TaskContext } from '../AllTask'

const requestDateFormat = 'YYYY/MM/DD'

const filterKeys = {
  from: 'FromDate',
  to: 'ToDate',
  tag: 'TagIds',
  title: 'Title',
  status: 'Status',
  workType: 'WorkType',
}

const Filter = () => {
  const {
    changeFilter = () => {},
    tags = [],
    listWorkTypes = [],
    filter: {
      [filterKeys.from]: from = null,
      [filterKeys.to]: to = null,
      [filterKeys.tag]: selectedTags = [],
      [filterKeys.title]: title = '',
      [filterKeys.workType]: workType = null,
    } = {},
  } = useContext(TaskContext)

  return (
    <div className="layout-filter">
      <div className={classnames('flex-basis-20')}>
        <div className="cpc-input-v2-filter">
          <DateRangePicker
            placeholder="Ngày tạo (Từ ngày - Đến ngày)"
            onChange={range => {
              let value = {}
              if (range) {
                value = {
                  [filterKeys.from]: moment(get(range, 'start')).format(
                    requestDateFormat
                  ),
                  [filterKeys.to]: moment(get(range, 'end')).format(
                    requestDateFormat
                  ),
                }
              } else {
                value = {
                  [filterKeys.from]: null,
                  [filterKeys.to]: null,
                }
              }
              changeFilter({ multipleKey: true })(value)
            }}
            value={from && to ? moment.range(moment(from), moment(to)) : null}
          />
        </div>
      </div>
      <div className={classnames('flex-basis-20')}>
        <div className="cpc-input-v2-filter">
          <CheckboxSelect
            inputLeftIcon="icon2-documents"
            dataSource={tags.map(tag => ({
              value: tag.id,
              label: tag.name,
            }))}
            onClear={changeFilter({ name: filterKeys.tag })}
            onChange={changeFilter({ name: filterKeys.tag, reFetch: false })}
            onClose={changeFilter({ name: filterKeys.tag })}
            placeholder="Chọn loại công việc"
            selectedAllText="Tất cả loại công việc"
            value={selectedTags}
          />
        </div>
      </div>
      <div className="flex-basis-20">
        <div className="cpc-input-v2-filter">
          <Select
            placeholder="Chọn nguồn công việc"
            dataSource={[
              { label: 'Tất cả', value: 'ALL' },
              ...(listWorkTypes?.map(e => ({
                label: e?.name,
                value: e?.keyName,
              })) || []),
            ]}
            onChange={changeFilter({ name: filterKeys.workType })}
            value={workType}
          />
        </div>
      </div>
      <div className={classnames('flex-basis-40')}>
        <div className="cpc-input-v2-filter-button">
          <SearchInput
            placeholder="Nhập trích yếu/Tiêu đề"
            onChange={changeFilter({ name: filterKeys.title, reFetch: false })}
            onSearch={changeFilter({ name: filterKeys.title })}
            value={title}
            leftIcon="icon2-search"
          />
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton onClick={changeFilter({ name: 'RESET' })} />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default memo(Filter)
