import React, { memo } from 'react'

import { IMAGE_URL } from 'constants/Api'

const cardStyles = {
  display: 'flex',
  alignItems: 'center',
}

const iconStyles = {
  width: '32px',
  objectFit: 'cover',
  borderRadius: '100%',
}

const contentStyles = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '10px',
}

const titleStyles = {
  fontFamily: `Roboto, sans-serif`,
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '13px',
  lineHeight: '18px',
  color: '#31434b',
}

const CardProfile = ({ profile }) => {
  if (!profile) return null

  return (
    <div style={cardStyles}>
      <div
        className="avatar-small"
        style={{ backgroundImage: profile.avatar ? `url(${IMAGE_URL}${profile.avatar})` : `url(/images/default_avatar.jpeg)` }}
      >
        <img
          src={profile.avatar ? `${IMAGE_URL}${profile.avatar}` : '/images/default_avatar.jpeg'}
          alt={profile.avatar}
          style={iconStyles}
        />
      </div>
      <div style={contentStyles}>
        <p style={titleStyles}>{profile.name}</p>
      </div>
    </div>
  )
}

export default memo(CardProfile)
