import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import { Position } from '@blueprintjs/core'
import { get, find } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import { Card } from 'components/newCommon'
import DropdownWrapper from './DropdownWrapper'
import { UNSET, WORK_REMINDER } from 'constants/Enum'
import { FormDatetimePicker } from '.'

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

const selectData = [
  { value: UNSET, label: 'Không nhắc' },
  { value: WORK_REMINDER.TRUOC_MOT_NGAY, label: 'Trước 1 ngày' },
  { value: WORK_REMINDER.TRUOC_HAI_NGAY, label: 'Trước 2 ngày' },
  { value: WORK_REMINDER.TRUOC_BA_NGAY, label: 'Trước 3 ngày' },
]

const typeToNumber = {
  [WORK_REMINDER.TRUOC_MOT_NGAY]: 1,
  [WORK_REMINDER.TRUOC_HAI_NGAY]: 2,
  [WORK_REMINDER.TRUOC_BA_NGAY]: 3,
}

const RemindSelect = ({
  deadline = null,
  onChange = () => {},
  value: selectValue,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [selected, setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    mountedSet(_setDropdownOpen, open)
  }, [])

  const onSelect = value => () => {
    const newSelected = {
      type: value,
      date: null,
    }
    if (value !== UNSET) {
      newSelected.date = moment(deadline)
        .subtract(typeToNumber[value], 'days')
        .set('hour', 7)
        .set('minute', 0)
    }
    mountedSet(setSelected, newSelected)
    onChange(value !== UNSET ? newSelected : undefined)
    _toggleDropdown(false)
  }

  const changeDate = date => {
    const newSelected = {
      type: date ? WORK_REMINDER.TUY_CHINH : UNSET,
      date: date,
    }
    mountedSet(setSelected, newSelected)
    onChange(date ? newSelected : null)
    if (!date) {
      _toggleDropdown(false)
    }
  }

  const onAfterChangeTime = () => {
    _toggleDropdown(false)
  }

  const selectDisabled = value => {
    if (value === UNSET) {
      return false
    }
    if (!deadline) {
      return true
    }
    if (
      moment(deadline).subtract(typeToNumber[value], 'days') <
      moment().endOf('d')
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      isModal={true}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
      value={
        !selected
          ? undefined
          : get(selected, 'type') !== WORK_REMINDER.TUY_CHINH
          ? get(find(selectData, { value: get(selected, 'type') }), 'label')
          : moment(get(selected, 'date')).format(DATETIME_FORMAT)
      }
    >
      <Card className="popup">
        <Card className="cpc-list ph5">
          {selectData.map(({ value, label }) => (
            <Card
              className={csx(
                'cpc-list-item',
                'font-size-13',
                'ph5 pv20',
                'has-event',
                {
                  active: value === get(selected, 'type'),
                  disabled: selectDisabled(value),
                }
              )}
              key={value}
              onClick={onSelect(value)}
            >
              {label}
            </Card>
          ))}
        </Card>

        <Card borderTop className="p10">
          <FormDatetimePicker
            isModal={true}
            inputIconClassName="icon2-date-frames"
            onChange={changeDate}
            onAfterChangeTime={onAfterChangeTime}
            minimumDate={moment().toDate()}
            maximumDate={deadline && moment(deadline).toDate()}
            placeholder="Tùy chỉnh"
            popoverStretch={false}
            popoverPosition={Position.TOP_RIGHT}
            selected={
              get(selected, 'type') === WORK_REMINDER.TUY_CHINH
                ? get(selected, 'date')
                : null
            }
            defaultTime={[7, 0]}
          />
        </Card>
      </Card>
    </DropdownWrapper>
  )
}

export default memo(RemindSelect)
