import React, { memo, Fragment, useState, useRef, useEffect } from 'react'
import { Icon } from 'components/newCommon'
import { size, get } from 'lodash'
import csx from 'classnames'
import { Loading } from 'components/newCommon'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  MESSAGE_ERROR,
  MESSAGE_NOT_FOUND,
  MESSAGE_REQUIRED_STATISTICS,
} from 'constants/MessageForm'
const publicPath = process.env.PUBLIC_URL
const imgPaths = {
  notFound: `${publicPath}/images/search-result-not-found.png`,
  error: `${publicPath}/images/Server.png`,
  statistic: `${publicPath}/images/calendar.png`,
}
const messages = {
  notFound: MESSAGE_NOT_FOUND,
  error: MESSAGE_ERROR,
  statistic: MESSAGE_REQUIRED_STATISTICS,
}

const Table = ({
  loading = true,
  error = false,
  statisticMessage = false,
  columns = [],
  onRowClick,
  data = [],
  className,
  classNameWrapper,
  tableHeightMax = 3000,
  tableMinWidth = 1200,
  autoHideScrollBody = true,
  isSpecialFirstRow = false,
  dataTotal = [],
  autoHide = true,
  onSort: tableSort = () => {},
  onContextMenu = () => {},
  rowClassName = () => {},
  onScrollFrameBody = () => {},
  onScrollFrameBottom = () => {},
}) => {
  const scrollbarRef = useRef(null)
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const fullRow = size(columns)
  const empty = !size(data)
  const showBody = !(loading || error || empty || statisticMessage)
  const [sorts, setSorts] = useState({})
  const onSort = key => () => {
    const value = get(sorts, key, 'DESC') === 'DESC' ? 'ASC' : 'DESC'
    mountedSet(setSorts, {
      ...sorts,
      [key]: value,
    })
    tableSort(`${key} ${value}`)
  }

  const getMessageType = () => {
    if (statisticMessage) {
      return 'statistic'
    }
    if (error) {
      return 'error'
    }
    if (empty) {
      return 'notFound'
    }
    return ''
  }

  const onScrollFrame = values => {
    onScrollFrameBody(values)
    const { scrollTop, scrollHeight, clientHeight } =
      scrollbarRef?.current?.getValues()
    if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
      onScrollFrameBottom(values)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Scrollbars autoHeight autoHeightMax={3000} autoHide={autoHide}>
      <div
        className={csx('cpc-table-wrapper', classNameWrapper)}
        style={{ minWidth: tableMinWidth }}
      >
        <div className={csx('cpc-table', className)}>
          <div className="cpc-table-thead">
            <div className="cpc-table-row cpc-table-row-head">
              {columns.map(
                (
                  { key, title, className, sortKey, headClass, width },
                  headIndex
                ) => (
                  <div
                    key={key || headIndex}
                    className={csx(
                      'cpc-table-cell',
                      'p10',
                      {
                        [`width-${width}`]: width,
                      },
                      className,
                      headClass
                    )}
                  >
                    {title}
                    {sortKey && (
                      <Icon
                        classIcon={
                          get(sorts, sortKey, 'DESC') === 'DESC'
                            ? 'icon2-arrow-down'
                            : 'icon2-arrow-up'
                        }
                        className={csx('ml5 has-event font-size-11')}
                        onClick={onSort(sortKey)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
            {columns.some(cell => cell.hasOwnProperty('filter')) && (
              <div className="cpc-table-row cpc-table-row-filter">
                {columns.map(({ filter, width, className }, index) => (
                  <div
                    key={index}
                    className={csx(
                      'cpc-table-cell',
                      {
                        [`width-${width}`]: width,
                      },
                      className
                    )}
                  >
                    {filter}
                  </div>
                ))}
              </div>
            )}
            {/* Nếu trong cột chia nhiều cột nhỏ thì truyền headSplit = [] */}
            {columns.some(cell => cell.headSplit && size(cell.headSplit)) && (
              <div className="cpc-table-row cpc-table-row-filter">
                {columns.map(({ headSplit, width, className }, index) => (
                  <div
                    key={index}
                    className={csx(
                      'cpc-table-cell',
                      {
                        [`width-${width}`]: width,
                      },
                      className
                    )}
                  >
                    {headSplit && size(headSplit) && (
                      <div className="cpc-table-cell--split">
                        {headSplit.map((elm, key) => {
                          return (
                            <div
                              key={key}
                              className={csx(
                                'uppercase font-size-12',
                                elm.className
                              )}
                            >
                              {elm.name}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {showBody ? (
            <Fragment>
              <Scrollbars
                ref={scrollbarRef}
                autoHeight
                autoHeightMax={tableHeightMax}
                autoHide={autoHideScrollBody}
                onScrollFrame={onScrollFrame}
              >
                <div className="cpc-table-tbody">
                  <Fragment>
                    {data.map((record, rowIndex) => {
                      const { key: rowKey } = record
                      let isRowHead = false
                      if (isSpecialFirstRow && rowIndex === 0) {
                        isRowHead = true
                      }

                      return (
                        <div
                          key={rowKey || rowIndex}
                          className={csx(
                            'cpc-table-row',
                            {
                              'has-event': onRowClick,
                              'special-last-row': isRowHead,
                            },
                            rowClassName(record)
                          )}
                          onClick={() => onRowClick && onRowClick(record)}
                          onContextMenu={e => onContextMenu(record, e)}
                        >
                          {columns.map((column, columnIndex) => {
                            const {
                              key: columnKey,
                              render,
                              dataIndex,
                              align,
                              width,
                              className: columnClass,
                            } = column
                            return (
                              <div
                                key={columnKey || columnIndex}
                                className={csx(
                                  'cpc-table-cell',
                                  'p10',
                                  {
                                    [`text-${align}`]: !!align,
                                    [`width-${width}`]: width,
                                  },
                                  columnClass
                                )}
                              >
                                {render
                                  ? render(
                                      dataIndex
                                        ? get(record, dataIndex)
                                        : record,
                                      record,
                                      rowIndex
                                    )
                                  : get(record, dataIndex)}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </Fragment>
                </div>
              </Scrollbars>
              {size(dataTotal) !== 0 && (
                <div className="cpc-table-tbody-total">
                  {dataTotal?.map((record, rowIndex) => {
                    const { key: rowKey } = record
                    return (
                      <div
                        key={rowKey || rowIndex}
                        className={csx('cpc-table-row', rowClassName(record))}
                      >
                        {columns.map((column, columnIndex) => {
                          const {
                            key: columnKey,
                            render,
                            dataIndex,
                            align,
                            width,
                            className: columnClass,
                          } = column
                          return (
                            <div
                              key={columnKey || columnIndex}
                              className={csx(
                                'cpc-table-cell',
                                'p10',
                                {
                                  [`text-${align}`]: !!align,
                                  [`width-${width}`]: width,
                                },
                                columnClass
                              )}
                            >
                              {render && columnKey !== 'stt'
                                ? render(
                                    dataIndex ? get(record, dataIndex) : record,
                                    record,
                                    rowIndex
                                  )
                                : get(record, dataIndex)}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              )}
            </Fragment>
          ) : (
            <div className={csx('cpc-table-row')}>
              <div
                colSpan={fullRow}
                className={csx('cpc-table-cell center', {
                  'table-placeholder': !loading,
                })}
              >
                <div className="p20" style={{ backgroundColor: '#FFF' }}>
                  {loading ? (
                    <Loading column={3} />
                  ) : (
                    <Fragment>
                      <img
                        src={imgPaths[getMessageType()]}
                        alt="table-placeholder"
                        className="thumb"
                        style={{ maxWidth: '75px', marginBottom: '10px' }}
                      />
                      <p className="description" style={{ fontSize: 13 }}>
                        {messages[getMessageType()]}
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Scrollbars>
  )
}

export default memo(Table)
