import React from 'react'
import { Cookies, withCookies } from 'react-cookie'
import withRouter from 'react-router/withRouter'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import get from 'lodash/get'

import { Header, Footer } from '../layout'
import { Permission, Authorization } from '../auth'
import { menuSidebar } from '../../constants/menuSidebar'
import * as Actions from '../../actions'
import * as Actions2 from '../../actions/procedure'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { checkPermissionMenu, isValidCondition } from 'helpers/Helper'
import { getThongKeLoaiGiaoViec, getNotificationCounter } from 'actions/task'

import SidebarV2 from './SidebarV2-2'

const cookies = new Cookies()

class MasterLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmit: true,
      isShowMenuMobile: false,
      hiddenMenuHome: false,
      enableReadOnly: this.props.readOnly,
      enableScrollFixedMenu: this.props.enableScrollFixedMenu,
      isLeftNav: true,
    }
    this.handleClick = this.handleClick.bind(this)
    this.clickSidebarMobile = this.clickSidebarMobile.bind(this)
    this.resize = this.resize.bind(this)
    this.logout = this.logout.bind(this)
    this.getMenuItems = this.getMenuItems.bind(this)
    this.handleOpenChat = this.handleOpenChat.bind(this)
  }

  checkCouterQuanLyCongViec = () => {
    const taskCounters = this.props?.counters.task
    return (
      !!taskCounters?.notification ||
      !!taskCounters?.assignToMe ||
      !!taskCounters?.watchedByMe ||
      !!taskCounters?.assignByMe
    )
  }

  getMenuItems = () => {
    const path = type => {
      if (!type) {
        return '/'
      }

      const auth = this.props?.auth
      const quyensMenu = this.props?.quyensMenu
      let pathUrl = '/'
      switch (type) {
        case 'documentary':
          if (
            Authorization.vaild(auth.permission, [Permission.CONGVANDEN]) &&
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
            ])
          ) {
            pathUrl = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVANDI])
          ) {
            pathUrl = '/cong-van/cong-van-di/danh-sach'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVAN_XEMTATCA])
          ) {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          } else {
            pathUrl = '/cong-van/cong-van-noi-bo/danh-sach'
          }

          break
        case 'signNumber':
          if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI,
            ])
          ) {
            pathUrl = '/ky-so/cong-van-di/danh-sach'
          } else {
            pathUrl = '/ky-so/cong-van-noi-bo/danh-sach'
          }

          break
        case 'work':
          if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
            ]) &&
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_PHONGBAN])
          ) {
            pathUrl = '/cong-viec/phong-ban/danh-sach/chua-giao'
          } else if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
            ]) &&
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_CANHAN])
          ) {
            pathUrl = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
          } else {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          }

          break
        case 'utilities':
          if (
            this.props.numberSideBar &&
            this.props.numberSideBar.phieuGuiYeuCauGopYCanXyLy > 0 &&
            Authorization.vaild(auth.permission, [
              Permission.LAYYKIENDONVI_PHIEUGUI,
            ])
          ) {
            pathUrl = '/tien-ich/lay-y-kien-don-vi/danh-sach-goi'
          } else {
            pathUrl = '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan'
          }

          break
        case 'sidebarSystem':
          if (
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
          ) {
            pathUrl = '/he-thong/nguoi-dung/phan-quyen'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOITUONGCONGVAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/doi-tuong/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.SOLUUTRU_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/so-luu/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOKHAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-khan/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOBAOMAT_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-bao-mat/danh-muc'
          }

          break
        default:
          break
      }

      return pathUrl
    }

    return [
      {
        path: '/',
        icon: 'icon-Trang_Chu',
        name: 'Trang Chủ',
        permission: [],
        links: [],
        typeSidebar: 'HomePage',
        newStatus: false,
      },
      {
        typeSidebar: 'taskManager',
        path: Authorization.vaild(this.props.auth.permission, [
          Permission.CONGVANDEN_CHIDAO,
        ])
          ? '/quan-ly-cong-viec/toi-giao'
          : '/quan-ly-cong-viec/duoc-giao',
        icon: 'icon-case',
        name: 'Công việc',
        permission: [Permission.SUDUNG_QLCV],
        links: [
          '/quan-ly-cong-viec/thong-bao/',
          '/quan-ly-cong-viec/toi-giao',
          '/quan-ly-cong-viec/duoc-giao',
          '/quan-ly-cong-viec/theo-doi',
          '/quan-ly-cong-viec/tags',
          '/quan-ly-cong-viec/nhom-thuong-giao-viec',
          '/quan-ly-cong-viec/thong-ke',
          '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
          '/thong-bao-ket-luan-cuoc-hop/thong-ke',
          '/thong-bao-ket-luan-cuoc-hop/chi-tiet',
        ],
        newStatus: this.checkCouterQuanLyCongViec(),
      },
      {
        path: path('sidebarSystem'),
        icon: 'icon-Hethong',
        name: 'Hệ thống',
        orPermission: [
          Permission.ADMIN,
          Permission.SUPERADMIN,
          Permission.HETHONG,
        ],
        links: [
          '/he-thong/nguoi-dung/phong-ban',
          '/he-thong/nguoi-dung/phan-quyen',
          '/he-thong/nguoi-dung/them-moi',
          '/he-thong/nguoi-dung/chinh-sua',
          '/he-thong/phan-quyen-vai-tro',
          '/he-thong/vai-tro/danh-muc',
          '/he-thong/vai-tro/them-moi',
          '/he-thong/vai-tro/chinh-sua',
          '/he-thong/don-vi/danh-muc',
          '/he-thong/don-vi/them-moi',
          '/he-thong/don-vi/chinh-sua',
          '/he-thong/phong-ban/danh-muc',
          '/he-thong/phong-ban/them-moi',
          '/he-thong/phong-ban/chinh-sua',
          '/he-thong/chuc-vu/danh-muc',
          '/he-thong/chuc-vu/them-moi',
          '/he-thong/chuc-vu/chinh-sua',
          '/he-thong/so-luu/danh-muc',
          '/he-thong/so-luu/them-moi',
          '/he-thong/so-luu/chinh-sua',
          '/he-thong/doi-tuong/danh-muc',
          '/he-thong/doi-tuong/them-moi',
          '/he-thong/doi-tuong/chinh-sua',
          '/he-thong/do-khan/danh-muc',
          '/he-thong/do-khan/them-moi',
          '/he-thong/do-khan/chinh-sua',
          '/he-thong/do-bao-mat/danh-muc',
          '/he-thong/do-bao-mat/them-moi',
          '/he-thong/do-bao-mat/chinh-sua',
          '/he-thong/quan-ly-cong-viec/banner/danh-sach',
          '/he-thong/quan-ly-cong-viec/banner/them-moi',
          '/he-thong/quan-ly-cong-viec/banner/chinh-sua',
          '/he-thong/service/them-moi',
          '/he-thong/service/chinh-sua',
          '/he-thong/service/danh-sach',
          '/he-thong/service/thong-ke',
          '/he-thong/danh-muc-quy-trinh',
          '/he-thong/quy-trinh',
          '/he-thong/don-vi',
          '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
          '/he-thong/phuong-phap-luu/danh-sach',
          '/he-thong/phuong-phap-luu/them-moi',
          '/he-thong/phuong-phap-luu/chinh-sua',
          '/he-thong/thoi-gian-luu/danh-sach',
          '/he-thong/thoi-gian-luu/them-moi',
          '/he-thong/thoi-gian-luu/chinh-sua',
          '/he-thong/phien-ban/phan-quyen-chuc-nang',
        ],
        typeSidebar: 'sidebarSystem',
        newStatus: false,
      },
    ]
  }

  isNewNotifications = () => {
    let noti = this.props.notifications
    let corporations =
      noti && noti.list && noti.list.corporations ? noti.list.corporations : []
    let unit = noti && noti.list && noti.list.unit ? noti.list.unit : []
    let isNewDate = date => {
      if (moment(date).format('L') === moment().format('L')) {
        return true
      } else {
        return false
      }
    }
    if (
      (corporations &&
        corporations[0] &&
        corporations[0].ngayTao &&
        isNewDate(corporations[0].ngayTao)) ||
      (unit && unit[0] && unit[0].ngayTao && isNewDate(unit[0].ngayTao))
    ) {
      return true
    } else {
      return false
    }
  }

  handleClick(e) {
    this.setState(
      {
        isSubmit: !this.state.isSubmit,
      },
      () => {
        this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        cookies.set('sidebarCollapsed', this.state.isSubmit)
      }
    )
  }

  clickSidebarMobile(e) {
    this.setState({
      isShowMenuMobile: !this.state.isShowMenuMobile,
    })
  }
  logout(e) {
    let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
    let user_login = localStorage.getItem('user_login')
    localStorage.clear()
    localStorage.setItem('user_login', user_login)
    localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
    sessionStorage.clear()
    this.props.actions.authLogout()
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('checkReloadPage', this.checkReloadPage)
    this.resize()
    this.checkReloadPage()

    const collapsed = cookies.get('sidebarCollapsed')
    if (this.props.typeSidebar === 'taskManager') {
      this.setState({ isSubmit: collapsed ? collapsed === 'true' : true })
    }
  }

  checkReloadPage = () => {
    window.onbeforeunload = e => {
      Tool.Utils.saveData('isReloadPage', true)
    }

    window.onload = e => {
      Tool.Utils.saveData('isReloadPage', false)
    }
  }

  resize() {
    if (this.props.collapseSideBar && this.props.collapseSideBar === true) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (window.innerWidth <= 1024) {
      this.setState(
        {
          enableReadOnly: true,
        },
        () => {
          this.props.actions.commonReadOnlyDatePicker(this.state.enableReadOnly)
        }
      )
    }

    if (
      this.props.location.pathname.indexOf('them-file') > -1 ||
      this.props.location.pathname.indexOf('di-chuyen') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-sach') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('chuyen-nham') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('tien-ich') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-ba') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('he-thong') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
  }

  componentDidMount() {
    const { auth, location } = this.props
    const currentUserPermission = auth.permission
    const { pathname } = location
    if (
      isValidCondition(currentUserPermission, [Permission.SUDUNG_QLCV], 'OR')
    ) {
      getThongKeLoaiGiaoViec()
      if (pathname.includes('/quan-ly-cong-viec/')) {
        getNotificationCounter()
      }
    }
    this.props.actions.getNumberHomePage()
    if (this.props.typeSidebar === 'listContact') {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
    if (this.props.location.pathname === '/error-page') {
      document.body.style.backgroundColor = 'white'
    }
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (this.props.typeSidebar === 'documentary' && danhSach) {
      this.props.actions.getNumberDocumentPage()
      this.props.actions.getTongSoCongVanThuHoi()
    }
    if (this.props.typeSidebar === 'signNumber' && danhSach) {
      this.props.actions.getNumberSignNumberPage()
      this.props.actions.getNumberProjectSignNumberPage()
      this.props.actions.getNumberKSTTPage()
      this.props.actions.getNumberPhieuYKien()
    }
    if (this.props.typeSidebar === 'work' && danhSach) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
    if (
      this.props.typeSidebar === 'documentsCabinet' ||
      danhSach ||
      this.props.location.pathname === '/'
    ) {
      this.props.actions.getNumberHSCV()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (
      prevProps.typeSidebar === 'documentary' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      this.props.actions.getNumberDocumentPage()
    }
    if (
      prevProps.typeSidebar === 'work' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('checkReloadPage', this.checkReloadPage)
  }

  handleOpenChat() {
    const { auth } = this.props

    const url =
      Types.FE_CHAT_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`
    window.open(url, 'eoffice-chat')
  }

  render() {
    const isHiddenHeader = this?.props?.auth?.token?.isEmbed
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {})
    )
    const isLeftNav = this.state?.isLeftNav
    const {
      newLayout,
      children,
      location,
      quyensMenu,
    } = this.props

    return (
      <div>
        <div
          className={classnames('page-container', {
            'is-navigation': isLeftNav,
            'page-container-dashboard': location.pathname === '/',
            'sidebar-collapsed': !this.state.isSubmit,
            'sidebar-collapsed-back': this.state.isSubmit,
          })}
        >
          {
            !isHiddenHeader && (
              <Header
                quyensMenu={quyensMenu}
                user={this.props.auth.user}
                roleId={this.props.auth && this.props.auth.roleId}
                path={this.props.path}
                actions={this.props.actions}
                hiddenMenuHome={this.state.hiddenMenuHome}
                notifications={this.props.notifications}
                history={this.props.history}
                isNavigation={isLeftNav}
                menu={this.getMenuItems()}
                onHandleClick={this.clickSidebarMobile}
                onOpenChat={this.handleOpenChat}
                handleLogout={this.logout}
              />
            )
          }
          <SidebarV2
            menu={menuSidebar.taskManager}
            isMobileMenuShown={this.state.isShowMenuMobile}
            isMenuShown={this.state.isSubmit}
            isNavigation={isLeftNav}
            toggleMenu={this.handleClick}
            closeMenuMobile={this.clickSidebarMobile}
            isHiddenHeader={isHiddenHeader}
          />
          <main
            className={classnames({
              'left-content': !newLayout,
              'right-content-wrapper': newLayout,
              'dashboard-page-wapper':
                location.pathname === '/' ||
                // giữ class với 1 số router '/thong-bao/*'
                get(location, 'pathname', '').split('/')[1] === 'thong-bao',
              'dashboard-page-error': location.pathname === '/error-page',
              'hidden': isHiddenHeader,
            })}
          >
            {!newLayout && (
              <div
                className={classnames({
                  'content-wrapper padding-mobile-default':
                    location.pathname !== '/',
                })}
              >
                {childrenWithProps}
              </div>
            )}
            {newLayout && (
              <div
                className={classnames('task-manager-wrapper', {'pt-0': isHiddenHeader})}
                style={{
                  display: 'flex',
                  minHeight: '100vh',
                  height: 'auto',
                  background: `#fff url(${get(cookies.get('background_image'), 'url')
                    ? `/backgrounds/${get(
                      cookies.get('background_image'),
                      'url'
                    )}`
                    : '/backgrounds/bg1.jpg'
                    }) center/cover no-repeat`,
                }}
              >
                {children}
              </div>
            )}

            <Footer />
          </main>
        </div>
      </div>
    )
  }
}

MasterLayout.defaultProps = {
  typeSidebar: '',
  collapseSideBar: false,
}

const mapStateToProps = state => ({
  counters: state?.counters,
  config: state.common.config,
  userName: state.auth.user.username,
  auth: state.auth,
  receiveDocumentTotals: state.common.receiveDocumentTotals,
  departmentWorkTotals: state.common.departmentWorkTotals,
  personalWorkTotals: state.common.personalWorkTotals,
  numberSideBar: state.common.numberSideBar,
  totalSeeToKnow: state.personalWorks.totalSeeToKnowNotRead,
  alertMistakeTotals: state.common.alertMistakeTotals,
  readOnly: state.common.enableReadOnly,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  notifications: state.notifications,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions, ...Actions2 }, dispatch),
})

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterLayout))
)
